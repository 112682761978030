import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as TickIcon } from '../../assets/icons/tick.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/toast-close.svg';
import Button from '../../components/common/button/button.js';
import IconContainer from '../../components/common/icon-container/index.js';
import InputSearch from '../../components/common/input-search/index.js';
import NoData from '../../components/common/no-data/index.js';
import { OrganisationContext } from '../../context/organisationContext.js';
import useDebounce from '../../helpers/useDebounceHook.js';
import { initModal, uppercase } from '../../helpers/utils.js';
import { getAutomationActions } from '../../store/features/automationsSlice.js';
import { addToast } from '../../store/features/toastSlice.js';

const AddAction = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setModal, modal } = useContext(OrganisationContext);
  const { onSuccess, isEdit, action_type = {} } = modal?.content ?? {};

  const [actions, setActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState(action_type);
  const [loadingActions, setLoadingActions] = useState(true);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const onDone = async () => {
    onSuccess({ ...selectedAction, action_type: { ...selectedAction } });
    setModal(initModal);
  };

  const fetchActions = async debouncedSearch => {
    setLoadingActions(true);
    dispatch(getAutomationActions({ params: { search: debouncedSearch || null } }))
      .then(data => {
        setActions(data);
      })
      .catch(() => {
        setActions([]);
        dispatch(addToast({ error: true, text: t('FETCHING_ACTIONS_ERROR'), id: nanoid() }));
      })
      .finally(() => setLoadingActions(false));
  };

  useEffect(() => {
    fetchActions(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddTriggerWrapper className="flex-column px-8 py-9 flex-column">
        <div className="flex justify-between px-2">
          <p className="inter-600-text natural-900-text font-20">{isEdit ? t('EDIT_ACTION') : t('ADD_ACTION')}</p>
          <CrossIcon width={24} height={24} className="cursor" onClick={() => setModal(initModal)} />
        </div>
        <div className="flex-column row-gap-6 mt-6 flex-1 px-2 pt-2 overflow-hidden">
          <InputSearch onChange={setSearch} value={search} placeholder="Search" />
          <div className="flex-column flex-1 overflow-scroll row-gap-6">
            {loadingActions ? (
              <Fragment>
                <Skeleton height={64} />
                <Skeleton height={64} />
                <Skeleton height={64} />
              </Fragment>
            ) : actions?.length > 0 ? (
              actions?.map(action => (
                <div className="flex-column row-gap-2" key={action?.id}>
                  <label className="inter-600-text natural-900-text font-12 letter-spacing-1">
                    {uppercase(action?.name)}
                  </label>
                  <div className="flex-column row-gap-3">
                    {action?.action_types?.map(actionType => (
                      <div
                        className={classNames(
                          'flex items-center cursor border px-4 py-3 radius-1_5 overflow-hidden',
                          selectedAction?.action_type === actionType?.action_type && 'selected-action',
                        )}
                        onClick={() => setSelectedAction(actionType)}
                        key={actionType?.id}>
                        <div className="flex pxy-1 border radius-1_5 mr-2 action-icon">
                          <img src={actionType?.icon?.active} alt="event-type-icon" height={24} width={24} />
                        </div>
                        <div className="flex-column flex-1 col-gap-3">
                          <label className="inter-500-text natural-700-text one-line">{actionType?.name}</label>
                          <label className="inter-400-text natural-500-text font-12 one-line">
                            {actionType?.description}
                          </label>
                        </div>
                        {selectedAction?.action_type === actionType?.action_type && (
                          <IconContainer Icon={TickIcon} iconColor="primary_500" backgroundColor="transparent" />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <NoData
                title={debouncedSearch ? t('NO_RESULTS_FOUND') : t('NO_ACTIONS_FOUND')}
                description={debouncedSearch ? t('NO_RESULTS_DESCRIPTION') : t('NO_ACTIONS_FOUND')}
              />
            )}
          </div>
        </div>
        <div className="flex col-gap-6 justify-center mt-12 w-full">
          <Button
            className={classNames('primary-white flex-1')}
            label={'Cancel'}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary flex-1', !selectedAction?.action_type && 'disabled')}
            label={isEdit ? 'Edit' : 'Add'}
            disabled={!selectedAction?.action_type}
            onClick={onDone}
            size="large"
            borderRadius="100px"
          />
        </div>
      </AddTriggerWrapper>
    </CSSTransition>
  );
};

const AddTriggerWrapper = styled.div`
  width: 450px;
  max-height: calc(100dvh - 100px);

  .selected-action {
    background-color: ${({ theme }) => theme?.primary_50};
    border: 1px solid ${({ theme }) => theme?.primary_500};

    .action-icon {
      border: 1px solid ${({ theme }) => theme?.primary_300};
      background-color: white;
    }
  }
`;

export default AddAction;
