import { AnimatePresence, motion } from 'framer-motion';
import React, { Fragment, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrow-right.svg';
import { ReactComponent as ChildArrowIcon } from '../../../../../assets/icons/child-arrow.svg';
import { weekDaysData } from '../../../../../helpers/optionData';
import { capitalize, formatText, getFormattedNumber, getFormattedNumberStyle } from '../../../../../helpers/utils';
import { useEnergyTariffDetails } from '../../../../../hooks/useTariffs';
import { cn } from '../../../../../lib/utils';
import Card from '../../../../common/card';
import FlexBox from '../../../../common/flex-box/flex';
import IconContainer from '../../../../common/icon-container';
import Label from '../../../../common/label';

const getWeekDayLabel = day => {
  const weekDay = weekDaysData.find(weekDay => weekDay.value === day);
  return weekDay ? weekDay.label : weekDaysData.find(weekDay => weekDay.value === 1).label;
};

const RateDetails = ({ rate }) => {
  const { start_day, end_day, start_time, end_time, price_per_kwh, taxation, commission_partner } = rate;
  const { rate: taxRate, name: taxName } = taxation || {};
  const { name: partnerName } = commission_partner || {};
  const startDayLabel = getWeekDayLabel(start_day);
  const endDayLabel = getWeekDayLabel(end_day);

  return (
    <FlexBox key={rate.id} className="flex items-center">
      <IconContainer
        Icon={ChildArrowIcon}
        iconHeight={24}
        iconWidth={24}
        backgroundColor="transparent"
        iconContainerClassname="p-0"
      />
      <FlexBox className="border rounded-lg flex flex-1 px-4 py-3 gap-x-3 ml-2">
        <Label className="inter-500-text whitespace-nowrap">
          {startDayLabel} - {endDayLabel}
        </Label>
        <div className="w-px max-h-4 min-h-4 bg-natural-400" />
        <Label className="inter-500-text whitespace-nowrap">
          {start_time} - {end_time}
        </Label>
        <div className="w-px max-h-4 min-h-4 bg-natural-400" />
        <Label className="inter-500-text whitespace-nowrap">{getFormattedNumber(price_per_kwh)} p/kWh</Label>
        {taxRate && (
          <>
            <div className="w-px max-h-4 min-h-4 bg-natural-400" />
            <Label className="inter-500-text whitespace-nowrap">
              {taxName} - {getFormattedNumberStyle((taxRate || 0) / 100, 'percent', 0, 0)}
            </Label>
          </>
        )}
        {partnerName && (
          <>
            <div className="w-px max-h-4 min-h-4 bg-natural-400" />
            <Label className="inter-500-text whitespace-nowrap">{partnerName}</Label>
          </>
        )}
      </FlexBox>
    </FlexBox>
  );
};

const RatesContainer = ({ rates, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Card className="flex flex-col border rounded-lg px-4 py-4">
      <FlexBox className="flex justify-between items-center cursor" onClick={() => setIsOpen(!isOpen)}>
        <Label className="inter-600-text flex-1">{capitalize(formatText(name, ' '))}</Label>
        <IconContainer
          Icon={ArrowRightIcon}
          iconClassName={
            isOpen ? '-rotate-90 transition ease-in-out delay-150' : 'rotate-90 transition ease-in-out delay-150'
          }
          iconColor="natural-900"
          iconHeight={14}
          iconWidth={14}
          backgroundColor="transparent"
          iconContainerClassname="p-0"
        />
      </FlexBox>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={cn('flex flex-col gap-y-3')}
            initial={{ opacity: 0, height: 0, paddingTop: 0 }}
            animate={{ opacity: 1, height: 'auto', paddingTop: 12 }}
            exit={{ opacity: 0, height: 0, paddingTop: 0 }}
            transition={{ duration: 0.5 }}>
            {rates.map(rate => (
              <RateDetails key={rate.id} rate={rate} />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </Card>
  );
};

const sortRates = rates => {
  try {
    return rates.sort((first, second) => {
      const { start_day, end_day, start_time, end_time } = first;
      const { start_day: start_day2, end_day: end_day2, start_time: start_time2, end_time: end_time2 } = second;
      const startTimeInSec = start_time
        .split(':')
        .reduce((acc, time, index) => acc + time * Math.pow(60, 2 - index), 0);
      const endTimeInSec = end_time.split(':').reduce((acc, time, index) => acc + time * Math.pow(60, 2 - index), 0);
      const startTimeInSec2 = start_time2
        .split(':')
        .reduce((acc, time, index) => acc + time * Math.pow(60, 2 - index), 0);
      const endTimeInSec2 = end_time2.split(':').reduce((acc, time, index) => acc + time * Math.pow(60, 2 - index), 0);

      if (start_day > start_day2) return 1;
      if (start_day < start_day2) return -1;
      if (end_day > end_day2) return 1;
      if (end_day < end_day2) return -1;
      if (startTimeInSec > startTimeInSec2) return 1;
      if (startTimeInSec < startTimeInSec2) return -1;
      if (endTimeInSec > endTimeInSec2) return 1;
      if (endTimeInSec < endTimeInSec2) return -1;
    });
  } catch (e) {
    return rates;
  }
};

const groupRates = rates => {
  return rates.reduce((acc, rate) => {
    const { charge_type, id } = rate;
    const existingRate = acc.find(rate => rate.charge_type === charge_type);
    if (existingRate) {
      let newRates = [...existingRate.rates, rate];
      newRates = sortRates(newRates);
      acc = acc.map(rate => (rate.charge_type === charge_type ? { ...rate, rates: newRates } : rate));
    } else {
      acc.push({
        id: id,
        charge_type,
        name: charge_type,
        rates: [rate],
      });
    }
    return acc;
  }, []);
};

const filterRates = rates => {
  const commission_partner_rates = rates.filter(rate => rate.commission_partner);
  const other_rates = rates.filter(rate => !rate.commission_partner);
  return [
    { name: 'ENERGY RATES', id: 'ENERGY_RATES', rates: groupRates(other_rates || []) },
    {
      name: 'COMMISION',
      id: 'COMMISION',
      rates: groupRates(commission_partner_rates || []),
    },
  ];
};

const TariffRates = ({ tariff, account_id }) => {
  const { name, id: energy_tariff_id } = tariff;

  const { data: tariffRates, isFetching, error } = useEnergyTariffDetails({ energy_tariff_id, params: { account_id } });

  const isDataEmpty = !tariffRates || !tariffRates.length;
  const filteredRates = filterRates(tariffRates || []);

  return (
    <div className="flex flex-col flex-1 gap-y-3">
      {isFetching && <Skeleton containerClassName="h-full tracking-[1px]" height={'100%'} />}
      {!isFetching && (
        <Fragment>
          {isDataEmpty && <Label className="inter-400-text">No rates available</Label>}
          {!isDataEmpty && (
            <div className="flex flex-col gap-y-6">
              {filteredRates.map(mainRate => (
                <div className="flex flex-col gap-y-3" key={mainRate.id}>
                  <Label className="inter-600-text">{mainRate.name}</Label>
                  {mainRate.rates.map(rate => (
                    <RatesContainer key={rate.name} rates={rate.rates} name={rate.name} />
                  ))}
                </div>
              ))}
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default TariffRates;
