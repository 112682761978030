import classNames from 'classnames';
import React, { useState } from 'react';
import styled from 'styled-components';
import Card from '../../common/card';
import SitePowerContainer from './site-power';

const tabs = [
  {
    label: 'Monitoring',
    value: 'MONITORING',
  },
];

const SiteDetails = () => {
  const [selectedTab, setSelectedTab] = useState('MONITORING');

  return (
    <SiteDetailsWrapper className="flex-column row-gap-6 flex-1 h-full px-6 py-3">
      <div className="flex items-center border-bottom">
        {tabs.map(tab => (
          <div
            key={tab.value}
            className={classNames(
              'cursor inter-500-text font-14 pxy-4 tab',
              selectedTab === tab.value ? 'primary-500-text selected-tab' : 'natural-700-text',
            )}
            onClick={() => setSelectedTab(tab.value)}>
            {tab.label}
          </div>
        ))}
      </div>
      <SitePowerContainer />
    </SiteDetailsWrapper>
  );
};

const SiteDetailsWrapper = styled(Card)`
  .tab {
    border-bottom: 1px solid transparent;
  }
  .selected-tab {
    border-bottom: 1px solid ${({ theme }) => theme.primary_500};
  }
`;

export default SiteDetails;
