import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 32, 48, 0.8);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .modal-content {
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
    overflow-y: visible !important;
    background: #ffffff;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-shadow: 2px 2px 50px rgba(5, 49, 73, 0.2);
    border-radius: 16px;
    outline: 0;
    /* padding: 40px; */
    position: relative;
    max-width: 100%;
  }

  .popup-in-appear {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-active {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in, opacity 50ms ease-in;
  }

  .popup-fade-appear-active {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }
  .popup-dashboard-fullscreen {
    height: 100vh;
    width: 100vw;
    padding: 20px;
  }
`;

export const AddIntegrationWrapper = styled.div`
  width: 450px;

  .description-input {
    min-height: 100px;
    resize: none;
    outline: none;
    max-height: 200px;
  }

  .provider-selector {
    .organisations-border {
      height: 48px;
      border-color: ${({ theme }) => theme.lightGrey};
    }
    .mc-options {
      top: 48px;
    }
  }

  .sub-provider {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: 10px;
    row-gap: 10px;
  }

  .sub-provider-item {
    background: ${({ theme }) => theme.lightGreyBackground};
    border: 1px solid ${({ theme }) => theme.lightGreyBackground};
    border-radius: 100px;
  }

  .selected-sub-provider {
    border: 1px solid ${({ theme }) => theme.primary_500};
    background: #ffffff;
  }

  .error-border {
    border-color: red;

    .organisations-border {
      border-color: red !important;
    }
  }
`;

export const UserActionsWrapper = styled.div`
  width: 384px;

  .user-details-container {
    border: 1px solid ${({ theme }) => theme.natural_200};
    background: #fff;
  }

  .delete-btn {
    background: ${({ theme }) => theme.additionalRedDark};
    border: 1px solid ${({ theme }) => theme.additionalRedDark};
  }
`;

export const InviteUserWrapper = styled.div`
  width: 455px;
`;

export const AutomationLogActionsWrapper = styled.div`
  width: 420px;

  .automation-action-conatiner {
    background: ${({ theme }) => theme.navBackground};
  }

  .delete-btn {
    background: ${({ theme }) => theme.additionalRed};
    border: 1px solid ${({ theme }) => theme.additionalRed};
  }

  .send-email-box {
    background-color: ${({ theme }) => theme.natural_50};
  }

  .icon-wrapper {
    width: 24px;
    height: 24px;
    padding: 3px;
    border-width: 0.512px;
  }
`;

export const AutomationLogCancel = styled.div`
  width: 420px;

  .automation-cancel-conatiner {
    background: ${({ theme }) => theme.navBackground};
  }
`;

export const AddNewMember = styled.div`
  width: 450px;

  .options-select-container {
    width: 360px;

    .select__placeholder {
      color: #053149;
      opacity: 0.5;
      font-family: Roboto Regular;
    }

    .select__value-container {
      padding-left: 15px;
    }

    .select__control {
      border-radius: 100px;
      border: 1px solid ${({ theme }) => theme.lightGrey};
    }

    .select__control--is-focused {
      border: 1px solid ${({ theme }) => theme.lightGreyBackground};
      box-shadow: unset;
    }

    .select__multi-value {
      background: rgba(210, 229, 237, 0.3);
      border-radius: 4px;
    }
    .select__multi-value__label {
      color: #053149;
      font-size: 16px;
      font-family: Roboto Regular;
    }

    .select__clear-indicator,
    .select__indicator-separator {
      display: none;
    }
  }
  .button {
    padding: 12px 0;
    width: 168px;
    font-size: 12px;
    background-color: ${({ theme }) => theme.lightGreyBackground};
    border-radius: 12px;
  }
`;

export const NotesActionWrapper = styled.div`
  width: 384px;
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  .content-wrapper {
    gap: 20px;
    label {
      color: #171717;
    }
    p {
      color: #737373;
    }
  }
  .action-wrapper {
    button {
      display: flex;
      padding: 12px 20px;
      justify-content: center;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }
    .delete-btn {
      background: ${({ theme }) => theme.additionalRedDark};
    }
    .cancel-btn {
      border: 1px solid #e5e5e5;
      background: #fff;
      color: #404040;
    }
  }
`;

export const StageWrapper = styled.div`
  width: 450px;
  gap: 32px;
  .close-icon {
    right: 0px;
  }

  .air-con-info {
    border-radius: 6px;
    border: 1px solid #e5e5e5;
  }
  .purple-label {
    display: flex;
    padding: 2px 10px;
    align-items: flex-start;
    gap: 2px;
    border-radius: 24px;
    background: #f3eafe;
    color: #8324ff;
  }

  .btn-purple {
    svg {
      margin: 0 0 0 8px;
      path {
        fill: #8324ff;
      }
    }
  }

  .btn-blue {
    svg {
      margin: 0 0 0 8px;
      path {
        fill: #2496ff;
      }
    }
  }

  .btn-yellow {
    svg {
      margin: 0 0 0 8px;
      path {
        fill: #f59e0b;
      }
    }
  }
`;
