import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';
import { formatText, getContactValueByType } from '../../../helpers/utils';
import { cn, getContactName } from '../../../lib/utils';
import Card from '../../common/card';
import IconContainer from '../../common/icon-container';
import Label from '../../common/label';

const ContactAdditionalInfo = ({ value, label, loading }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-between">
      <Label className="inter-400-text text-sm min-h-6 flex items-center">{label}:</Label>
      <Label
        className={cn('ml-2 inter-400-text text-sm min-h-6 flex items-center', !value && 'natural-400-text')}
        loading={loading}>
        {value || t('NO_TYPE', { type: formatText(label) })}
      </Label>
    </div>
  );
};

const ContactInfoData = ({ contact, loading, openedContact, setIsOpenedContact }) => {
  const { job_title, contact_type, phones, emails, addresses } = contact || {};

  const isCompany = contact_type === 'COMPANY';
  const isOpened = openedContact === contact.id;

  const contactName = getContactName(contact);

  const mobile = getContactValueByType(phones, 'contact_type', 'MOBILE', 'value') || '';
  const landline = getContactValueByType(phones, 'contact_type', 'LANDLINE', 'value') || '';
  const email = getContactValueByType(emails, 'email_type', 'PERSONAL', 'contact_value') || '';
  const address = getContactValueByType(addresses, 'is_primary', true) || '';

  return (
    <div className="flex flex-col flex-1 items-start border rounded-md px-4 py-3">
      <div className={cn('flex w-full flex-1 items-center justify-between')}>
        <div className="flex flex-1 flex-col">
          <Label className="flex-1 inter-500-text text-sm min-h-6 flex items-center" loading={loading}>
            {contactName}
          </Label>
        </div>
        <div className="flex items-center gap-x-2">
          {/* <IconContainer
            Icon={EditIcon}
            backgroundColor="transparent"
            iconContainerClassname="p-1 rounded-full cursor-pointer"
            iconColor="primary_500"
            iconWidth={14}
            iconHeight={14}
          /> */}
          <IconContainer
            Icon={AddIcon}
            onClick={() => setIsOpenedContact(isOpened ? null : contact.id)}
            backgroundColor="trasparent"
            iconContainerClassname="p-2 rounded-full cursor-pointer"
            iconColor="natural_500"
            iconWidth={14}
            iconHeight={14}
          />
        </div>
      </div>
      <motion.div
        className="flex flex-col w-full overflow-hidden"
        initial={{ height: isOpened ? 'auto' : 0, margin: isOpened ? '8px 0' : 0 }}
        animate={{ height: isOpened ? 'auto' : 0, margin: isOpened ? '8px 0' : 0 }}
        exit={{ height: 0 }}
        transition={{ duration: 0.5 }}>
        <div className={cn('flex flex-col gap-y-2', isOpened && 'border-top pt-3')}>
          {!isCompany && <ContactAdditionalInfo value={mobile} label={'Landline'} loading={loading} />}
          <ContactAdditionalInfo value={landline} label={'Mobile'} loading={loading} />
          <ContactAdditionalInfo value={email} label={'Email'} loading={loading} />
          <ContactAdditionalInfo value={address} label={'Address'} loading={loading} />
        </div>
      </motion.div>
    </div>
  );
};

const AdditionalContacts = ({ contacts, loading }) => {
  const [openedContact, setIsOpenedContact] = useState(null);
  const { t } = useTranslation();

  const isContactsAvailable = contacts && contacts.length > 0;
  return (
    <div>
      <Card className="p-6 rounded-xl flex flex-col gap-y-2">
        <div className="flex items-center justify-between border-b pb-2 mb-2">
          <h1 className={cn('inter-600-text font-16 font-semibold text-lg')}>{t('ADDITIONAL_CONTACTS')}</h1>
          {/* <IconContainer
            Icon={AddIcon}
            backgroundColor="primary_50"
            iconContainerClassname="p-2 rounded-full cursor-pointer"
            iconColor="primary_500"
            iconWidth={14}
            iconHeight={14}
          /> */}
        </div>
        {loading ? (
          <Skeleton height={60} className="my-1" />
        ) : isContactsAvailable ? (
          contacts.map(contact => (
            <ContactInfoData
              key={contact.id}
              contact={contact}
              loading={loading}
              openedContact={openedContact}
              setIsOpenedContact={setIsOpenedContact}
            />
          ))
        ) : (
          <Label className="inter-400-text natural-400-text text-sm min-h-6 flex items-center">
            {t('NO_TYPE', { type: 'additional contacts' })}
          </Label>
        )}
      </Card>
    </div>
  );
};

export default AdditionalContacts;
