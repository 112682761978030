import { t } from 'i18next';
import React from 'react';
import { ReactComponent as EmptyInvitation } from '../../assets/icons/emptyInvitation.svg';

const InviteExpired = () => {
  return (
    <div className="text-center">
      <p className="font-28 inter-700-text mb-2">{t('INVITATION_HAS_EXPIRED')}</p>
      <p className="inter-400-text main-grey-text natural-500-text">{t('FRESH_INVITATION')}</p>
      <EmptyInvitation />
    </div>
  );
};

export default InviteExpired;
