import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as TickIcon } from '../../assets/icons/tick.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/toast-close.svg';
import Button from '../../components/common/button/button.js';
import IconContainer from '../../components/common/icon-container/index.js';
import InputSearch from '../../components/common/input-search/index.js';
import NoData from '../../components/common/no-data/index.js';
import { OrganisationContext } from '../../context/organisationContext.js';
import useDebounce from '../../helpers/useDebounceHook.js';
import { initModal, uppercase } from '../../helpers/utils.js';
import { getEventTypes, updateEventType } from '../../store/features/automationsSlice.js';
import { addToast } from '../../store/features/toastSlice.js';

const AddTrigger = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setModal, modal } = useContext(OrganisationContext);

  const { automationDetails, onSuccess, isEdit, event_type = {} } = modal?.content ?? {};

  const [triggers, setTriggers] = useState([]);
  const [selectedTrigger, setSelectedTrigger] = useState(event_type);
  const [loading, setLoading] = useState(false);
  const [loadingTriggers, setLoadingTriggers] = useState(true);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const onDone = async () => {
    if (loading) {
      return;
    }
    if (isEdit) {
      setModal({
        type: 'update-trigger',
        content: {
          automationDetails,
          onSuccess,
          currentTrigger: event_type,
          selectedTrigger,
        },
      });
      return;
    }
    setLoading(true);
    const request = { event_type: selectedTrigger?.event_type };
    dispatch(updateEventType({ request, automation_id: automationDetails?.id }))
      .then(data => {
        setLoading(false);
        setModal(initModal);
        onSuccess && onSuccess(data);
        dispatch(
          addToast({
            error: false,
            title: t('AUTOMATION_TRIGGER_ADDED'),
            text: t('SUCCESSFULLY_AUTOMATION_TRIGGER_ADDED'),
            id: nanoid(),
          }),
        );
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_AUTOMATION_TRIGGER'), id: nanoid() }));
        setLoading(false);
      });
  };

  const fetchTriggers = async debouncedSearch => {
    setLoadingTriggers(true);
    dispatch(getEventTypes({ params: { search: debouncedSearch } }))
      .then(data => {
        setTriggers(data);
      })
      .catch(() => {
        setTriggers([]);
        dispatch(addToast({ error: true, text: t('ERROR_FETCHING_TRIGGER'), id: nanoid() }));
      })
      .finally(() => setLoadingTriggers(false));
  };

  useEffect(() => {
    fetchTriggers(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddTriggerWrapper className="flex-column px-8 py-9 flex-column">
        <div className="flex justify-between px-2">
          <p className="inter-600-text natural-900-text font-20">{isEdit ? t('EDIT_TRIGGER') : t('ADD_TRIGGER')}</p>
          <CrossIcon width={24} height={24} className="cursor" onClick={() => setModal(initModal)} />
        </div>
        <div className="flex-column row-gap-6 mt-6 flex-1 px-2 pt-2 overflow-hidden">
          <InputSearch onChange={setSearch} value={search} placeholder={t('SEARCH')} />
          <div className="flex-column flex-1 overflow-scroll row-gap-6">
            {loadingTriggers ? (
              <Fragment>
                <Skeleton height={64} />
                <Skeleton height={64} />
                <Skeleton height={64} />
              </Fragment>
            ) : triggers?.length > 0 ? (
              triggers?.map(trigger => (
                <div className="flex-column row-gap-2" key={trigger?.id}>
                  <label className="inter-600-text natural-900-text font-12 letter-spacing-1">
                    {uppercase(trigger?.name)}
                  </label>
                  <div className="flex-column row-gap-3">
                    {trigger?.event_types?.map(eventType => (
                      <div
                        className={classNames(
                          'flex items-center cursor border px-4 py-3 radius-1_5 overflow-hidden',
                          selectedTrigger?.id === eventType?.id && 'selected-trigger',
                        )}
                        onClick={() => setSelectedTrigger(eventType)}
                        key={eventType?.id}>
                        <div className="flex pxy-1 border radius-1_5 mr-2 trigger-icon">
                          <img src={eventType?.icon?.active} alt="event-type-icon" height={24} width={24} />
                        </div>
                        <div className="flex-column flex-1 col-gap-3">
                          <label className="inter-500-text natural-700-text one-line">{eventType?.name}</label>
                          <label className="inter-400-text natural-500-text font-12 one-line">
                            {eventType?.description}
                          </label>
                        </div>
                        {selectedTrigger?.id === eventType?.id && (
                          <IconContainer Icon={TickIcon} iconColor="primary_500" backgroundColor="transparent" />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <NoData
                title={debouncedSearch ? t('NO_RESULTS_FOUND') : t('NO_TRIGGERS_FOUND')}
                description={debouncedSearch ? t('NO_RESULTS_DESCRIPTION') : t('NO_TRIGGERS_FOUND')}
              />
            )}
          </div>
        </div>
        <div className="flex col-gap-6 justify-center mt-12 w-full">
          <Button
            className={classNames('primary-white flex-1', loading && 'disabled')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary flex-1', (loading || !selectedTrigger?.id) && 'disabled')}
            label={isEdit ? t('EDIT') : t('ADD')}
            disabled={loading || !selectedTrigger?.id}
            onClick={onDone}
            size="large"
            borderRadius="100px"
          />
        </div>
      </AddTriggerWrapper>
    </CSSTransition>
  );
};

const AddTriggerWrapper = styled.div`
  width: 450px;
  max-height: calc(100dvh - 100px);

  .selected-trigger {
    background-color: ${({ theme }) => theme?.primary_50};
    border: 1px solid ${({ theme }) => theme?.primary_500};

    .trigger-icon {
      border: 1px solid ${({ theme }) => theme?.primary_300};
      background-color: white;
    }
  }
`;

export default AddTrigger;
