import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import { useCustomers } from '../../hooks/useContacts';
import { useError } from '../../hooks/useError';
import Card from '../common/card';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import ContactListItem from './contact-list-item';

const contactTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'COMPANY / PERSON', key: 'COMPANY_PERSON' },
  { name: 'EMAIL', key: 'EMAIL' },
  { name: 'PHONE', key: 'PHONE' },
  { name: 'BILLING ADDRESS', key: 'BILLING_ADDRESS' },
  { name: 'ACCOUNTS', key: 'ACCOUNTS' },
  { name: '', key: 'MENU' },
];

const CustomersList = ({ debouncedSearch, orderBy, sortBy }) => {
  const { t } = useTranslation();

  const {
    data: customersList,
    isLoading,
    hasNextPage,
    fetchNextPage,
    error,
  } = useCustomers({ params: { search: debouncedSearch, orderBy, sortBy } });

  useError({ error, default_message: t('ERROR_WHILE_FETCHING_CUSTOMERS') });

  const isDataEmpty = !customersList || (customersList && customersList.length === 0);

  return (
    <div className="flex flex-col flex-1 rounded-md mt-6 mx-6 overflow-hidden">
      <SkeletonTransition
        loading={isLoading}
        loaderClassName="flex items-center justify-center flex-1 mb-6"
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        {isDataEmpty && (
          <div className="flex flex-col flex-1 mb-6 items-center justify-center">
            <NoData
              title={debouncedSearch ? t('NO_RESULTS_FOUND') : t('NO_CUSTOMERS')}
              description={debouncedSearch ? t('NO_RESULTS_DESCRIPTION') : t('NO_CUSTOMERS_HERE')}
              className="search-terms"
              EmptyIcon={NoDocumentIcon}
              iconClassName="mb-6 relative"
            />
          </div>
        )}
        {!isDataEmpty && (
          <Card className="w-full rounded-md border overflow-auto">
            <div className="flex flex-col w-fit min-w-full h-full">
              <TableHeader
                headers={contactTableHeaders}
                headerClassName="px-6 bg-natural-50 min-w-full w-fit h-[52px] grid grid-cols-contacts-list-grid gap-x-4"
              />
              <InfiniteScrollV2
                hasMore={hasNextPage}
                fetchMoreData={fetchNextPage}
                infiniteScrollClassName="custom-scrollbar thin-scrollbar">
                {(customersList || []).map((customer, index) => (
                  <ContactListItem customer={customer} key={customer.id} showBorderTop={index !== 0} />
                ))}
              </InfiniteScrollV2>
            </div>
          </Card>
        )}
      </SkeletonTransition>
    </div>
  );
};

export default CustomersList;
