import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 232px;
  min-width: 232px;
  background: ${({ theme }) => theme.sidebarBackground};
  max-height: 90vh;
  overflow-y: scroll;
  height: 100%;
  border-right: 1px solid #e3eef3;

  .sidebar-top {
    display: flex;
    flex-direction: column;
    position: relative;
    outline: 0;
  }
`;

export const SidebarMenuItemWrapper = styled.div`
  user-select: none;

  .menu-arrow {
    transition-duration: 300ms;
    transition-property: transform;
  }

  .menu-arrow-down {
    transform: rotate(90deg);
  }

  .menu-item-container {
    height: 40px;

    .mi-item {
      height: 40px;

      .mi-icon-stroke,
      .mi-icon {
        height: 20px;
        width: 20px;
      }

      &:hover {
        background: ${({ theme }) => theme.primary_50};
        border-color: ${({ theme }) => theme.primary_50};

        .icon-container {
          svg {
            color: ${({ theme }) => theme.primary_500};
          }
        }
      }
    }

    .mi-item-selected {
      background: ${({ theme }) => theme.primary_50};
      .menu-item-name {
        color: ${({ theme }) => theme.primary_500};
      }
      .icon-container {
        svg {
          color: ${({ theme }) => theme.primary_500};
        }
      }
    }

    .mi-subitem-selected {
      .menu-item-name {
        color: ${({ theme }) => theme.primary_500};
      }
      .icon-container {
        svg {
          color: ${({ theme }) => theme.primary_500};
        }
      }
    }
  }

  .menu-subitems {
    overflow: hidden;
    transition: height 300ms linear 0s;
    margin-top: 2px;

    .subitem-container {
      .subitem {
        height: 40px;
      }

      &:hover {
        background: ${({ theme }) => theme.primary_50};
        border-color: ${({ theme }) => theme.primary_50};

        .icon-container {
          svg {
            color: ${({ theme }) => theme.primary_500};
          }
        }
      }
    }

    .subitem-selected {
      background: ${({ theme }) => theme.primary_50};
      .sub-item-name {
        color: ${({ theme }) => theme.primary_500};
      }
      .icon-container {
        svg {
          color: ${({ theme }) => theme.primary_500};
        }
      }
    }
  }

  .menu-subitems-hide {
    height: 0;
    overflow: hidden;
    transition: height 300ms linear 0s;
  }
`;
