import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function getContactName(contact) {
  const { title, forename, surname, contact_type, company_name } = contact || {};
  if (contact_type === 'COMPANY') {
    return company_name;
  }
  return forename || surname ? [title, forename, surname].join(' ') : '-';
}

export function getContactNameInitials(contact) {
  const { forename, surname, contact_type, company_name } = contact || {};
  if (contact_type === 'COMPANY') {
    const [first, second] = company_name.split('');
    return `${first || ''}${second || ''}`;
  }
  return forename || surname ? `${forename[0] || ''}${surname[0] || ''}` : '';
}

export const isUserSassOperator = () => {
  const user = getItemFromLocalStorage('user');
  const userOrganization = user?.organizations?.[0];
  return userOrganization?.organization_type === 'SAAS_OPERATOR';
};
