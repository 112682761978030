import classNames from 'classnames';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';
import { ReactComponent as DownIcon } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/toast-close.svg';
import Checkbox from '../checkbox';
import IconContainer from '../icon-container';
import InputSearch from '../input-search';

const defaultCustomStyle = {
  control: {},
  valueContainer: {},
  menu: {},
  menuList: {},
  indicatorsContainer: {},
  dropdownIndicator: {},
  loadingIndicator: {},
  group: {},
  input: {},
  multiValue: {},
  multiValueContainer: {},
  multiValueRemove: {},
};

const variants = {
  primary: {
    control: { height: '44px', borderRadius: '6px' },
  },
};

const Option = props => {
  const { innerProps, data, isSelected, isFocused, isMulti, selectProps } = props;
  const { getOptionLabel } = selectProps;

  return (
    <components.Option {...props} getStyles={() => {}}>
      <div
        className={classNames(
          'flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
          isSelected && 'selected',
          isFocused && 'bg-lightgray-1',
        )}
        {...innerProps}>
        {isMulti && (
          <Checkbox
            variant="small"
            isStopPropagation={false}
            is_checked_done
            checked={isSelected}
            className="mr-2 multi-checkbox"
          />
        )}
        {data.icon && <div className={classNames(data?.code ? '' : 'mr-2')}>{data.icon}</div>}
        <span
          className={classNames(
            'flex-1 items-center inter-400-text natural-900-text option-text one-line',
            isSelected && 'inter-500-text',
          )}>
          {getOptionLabel(data)}
        </span>
      </div>
    </components.Option>
  );
};

const DropdownIndicator = props => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <components.DropdownIndicator {...props} className="mr-2">
      <DownIcon height={16} width={16} className={classNames(menuIsOpen ? 'rotate-270' : 'rotate-90')} />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = props => {
  return <CloseIcon height={16} width={16} onClick={props.clearValue} />;
};

const CustomInputSearch = ({ selectProps, ...props }) => {
  const {
    onInputChange,
    inputValue,
    onMenuInputFocus,
    isCustomSearchable,
    showAddOnMenu,
    addClickFunction,
    onMenuClose,
  } = selectProps;

  return (
    <Fragment>
      {isCustomSearchable && (
        <div className="flex w-full p-3 border-bottom relative">
          <InputSearch
            className="custom-input-search w-full"
            value={inputValue || ''}
            onChange={value =>
              onInputChange(value, {
                action: 'input-change',
              })
            }
            onMouseDown={e => {
              e.stopPropagation();
              e.target.focus();
            }}
            onTouchEnd={e => {
              e.stopPropagation();
              e.target.focus();
            }}
            onFocus={onMenuInputFocus}
            placeholder="Search"
          />
          {showAddOnMenu && (
            <div className="flex p-1 relative">
              <IconContainer
                iconContainerClassname="radius-50-percent pxy-2 cursor"
                Icon={AddIcon}
                iconWidth={16}
                iconHeight={16}
                iconColor="primary_500"
                backgroundColor="primary_50"
                onClick={() => {
                  addClickFunction();
                  onMenuClose();
                }}
              />
            </div>
          )}
        </div>
      )}
      <components.MenuList {...props} selectProps={selectProps} />
    </Fragment>
  );
};

export const MenuList = wrapMenuList(CustomInputSearch);

const SearchableDropdown = ({
  placeholder = '',
  onChange = () => {},
  value = null,
  isMulti = false,
  name = '',
  sub_name = '',
  is_required = false,
  className = '',
  error = false,
  customStyle = defaultCustomStyle,
  customClass = false,
  menuPlacement = 'auto',
  inputValue = '',
  onInputChange = () => {},
  loadOptions,
  defaultAdditional = {},
  key = 'searchable_dropdown',
  isClearable = false,
  variant = '',
  isSearchable = false,
  loadOptionsOnMenuOpen = true,
  customComponent,
  isCustomSearchable = true,
  noOptionsMessage = 'No options',
  menuOpenHandler = null,
  onClose = () => {},
  classNamePrefix = '',
  closeMenuOnSelect = true,
  ignoreMenuDomClick = false,
  ...rest
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);

  const dropdownStyle = {
    container: baseStyles => ({
      ...baseStyles,
      ...customStyle.container,
    }),
    input: baseStyles => ({
      ...baseStyles,
      ...customStyle.input,
    }),
    singleValue: baseStyles => ({
      ...baseStyles,
      ...customStyle.singleValue,
    }),
    menuPortal: baseStyles => ({
      ...baseStyles,
      ...customStyle.menuPortal,
    }),
    control: (baseStyles, { selectProps: { menuIsOpen } }) => ({
      ...baseStyles,
      borderColor: error ? theme.additionalRed : menuIsOpen ? theme.focus_border : theme.primary_50,
      boxShadow: error ? `0px 0px 0px 2px #FEE2E2` : menuIsOpen ? `0px 0px 0px 2px ${theme.focus_border}` : 'none',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: '44px',
      cursor: 'pointer',
      // boxShadow: 'none',

      '&:hover': {
        borderColor: error ? theme.additionalRed : menuIsOpen ? theme.focus_border : theme.primary_50,
        boxShadow: `0px 0px 0px 2px ${menuIsOpen && !error ? theme.focus_border : theme.primary_50}`,
      },
      '&:focus': {
        borderColor: error ? theme.additionalRed : theme.focus_border,
        boxShadow: `0px 0px 0px 2px ${theme.focus_border}`,
      },
      '&:active': {
        borderColor: error ? theme.additionalRed : theme.focus_border,
        boxShadow: `0px 0px 0px 2px ${theme.focus_border}`,
      },
      ...(variants[variant]?.control || {}),
      ...customStyle.control,
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontFamily: 'Inter',
      color: theme.natural_400,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: baseStyles => ({
      ...baseStyles,
      color: theme.natural_900,
      ...customStyle.indicatorsContainer,
    }),
    dropdownIndicator: baseStyles => ({
      ...baseStyles,
      ...customStyle.dropdownIndicator,
    }),
    valueContainer: (baseStyles, { selectProps: { isCustomSearchable } }) => ({
      ...baseStyles,
      fontFamily: 'Inter',
      fontSize: '14px',
      padding: '4px 16px',
      input: {
        height: isCustomSearchable ? 0 : 'auto',
      },
      ...customStyle.valueContainer,
    }),
    menu: baseStyles => ({
      ...baseStyles,
      borderRadius: '8px',
      overflow: 'hidden',
      zIndex: 11,
      ...customStyle.menu,
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: '0px',
      maxHeight: '180px',
      ...customStyle.menuList,
    }),
    noOptionsMessage: baseStyles => ({
      ...baseStyles,
      fontFamily: 'Inter',
      color: theme.natural_400,
    }),
    loadingIndicator: baseStyles => ({
      ...baseStyles,
      ...customStyle.loadingIndicator,
    }),
    group: baseStyles => ({
      ...baseStyles,
      padding: '0px',
      ...customStyle.group,
    }),
    option: baseStyles => ({
      ...baseStyles,
      ...customStyle.option,
    }),
    input: baseStyles => ({
      ...baseStyles,
      ...customStyle.input,
    }),
    menuPortal: baseStyles => ({
      ...baseStyles,
      zIndex: 9999,
    }),
    multiValue: baseStyles => ({
      ...baseStyles,
      backgroundColor: theme.natural_100,
      borderRadius: '12px',
      padding: '4px 4px',
      ...customStyle.multiValue,
    }),
    multiValueRemove: baseStyles => ({
      ...baseStyles,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    }),
  };

  const onDomClick = e => {
    const menuClassNamePrefix = classNamePrefix
      ? classNamePrefix
      : customClass
      ? 'search-primary-select'
      : 'searchable-select';
    let menu = ignoreMenuDomClick ? true : containerRef.current.querySelector(`.${menuClassNamePrefix}__menu`);

    if ((!containerRef.current.contains(e.target) || !menu) && isFocused) {
      setIsFocused(false);
      (isCustomSearchable || isSearchable) && onInputChange('');
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', onDomClick);
    return () => {
      document.removeEventListener('mousedown', onDomClick);
    };
  }, [isFocused, isCustomSearchable, isSearchable, classNamePrefix, onClose, ignoreMenuDomClick]);

  const fetchChildOpt = async (
    search,
    _prevOptions,
    {
      page,
      merge,
      fetchFunction,
      hasMore,
      pageable,
      params = {},
      payload = {},
      formatOptions = null,
      defaultOptions = [],
    },
  ) => {
    if (!fetchFunction) {
      return {
        options: [...defaultOptions],
        hasMore: false,
        additional: {
          page: 0,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
          pageable,
          params,
          payload,
          formatOptions,
          defaultOptions,
        },
      };
    }
    try {
      const optionData = await dispatch(
        fetchFunction({
          forFetchOnly: true,
          params: {
            page: page,
            size: 10,
            search: search,
            ...params,
          },
          ...payload,
        }),
      );
      let optionContent = [];
      let last = true;
      if (pageable) {
        const { content, ...restResponse } = optionData || {};
        optionContent = optionData ? content : [];
        optionContent = page === 0 ? defaultOptions.concat(optionContent) : optionContent;
        last = restResponse.last;
      } else {
        optionContent = optionData || [];
        optionContent = defaultOptions.concat(optionContent);
      }
      let changedOptions = optionContent.map(option => ({ ...option, label: option.name, value: option.id }));
      changedOptions = formatOptions ? formatOptions(changedOptions) : changedOptions;

      return {
        options: changedOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: !last,
          pageable,
          params,
          payload,
          formatOptions,
          defaultOptions,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: hasMore,
        additional: {
          page: page,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
          pageable,
          params,
          payload,
          formatOptions,
          defaultOptions,
        },
      };
    }
  };

  return (
    <SearchableDropdownWrapper className={classNames('w-full', className)} ref={containerRef}>
      {name && (
        <div className="mb-1">
          <span className="inter-500-text natural-900-text text-wrap">{name}</span>
          {sub_name && <span className="inter-400-text natural-400-text ml-1">{sub_name}</span>}
          {is_required && <span className="required-star ml-1">*</span>}
        </div>
      )}
      <AsyncPaginate
        isClearable={isClearable}
        isSearchable={isSearchable}
        isCustomSearchable={isCustomSearchable}
        key={key}
        openMenuOnClick={true}
        isMulti={isMulti}
        placeholder={placeholder}
        closeMenuOnSelect={closeMenuOnSelect || !isMulti}
        value={value}
        inputValue={inputValue}
        onInputChange={onInputChange}
        loadOptions={loadOptions ? loadOptions : fetchChildOpt}
        additional={defaultAdditional}
        classNamePrefix={
          classNamePrefix ? classNamePrefix : `${customClass ? 'search-primary-select' : ''} searchable-select`
        }
        hideSelectedOptions={false}
        loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
        onChange={option => onChange(option)}
        styles={dropdownStyle}
        menuPlacement={menuPlacement}
        onMenuInputFocus={() => setIsFocused(true)}
        onMenuOpen={() => {
          setIsFocused(true);
          menuOpenHandler && menuOpenHandler();
        }}
        onMenuClose={() => setIsFocused(false)}
        onBlur={() => setIsFocused(false)}
        noOptionsMessage={() => noOptionsMessage}
        tabSelectsValue={false}
        components={{
          Option,
          DropdownIndicator,
          ClearIndicator,
          MenuList,
          ...customComponent,
        }}
        {...{
          menuIsOpen: isFocused,
          isFocused: isFocused,
        }}
        {...rest}
      />
    </SearchableDropdownWrapper>
  );
};

export const SearchableDropdownWrapper = styled.div`
  .search-primary-select {
    .searchable-select__value-container--has-value {
      .searchable-select__input-container {
        display: none !important;
        height: 0px !important;
      }
    }
  }

  .select-option-primary {
    .title-one-line {
      width: 235px;
    }
  }

  .option-wrapper {
    &:hover {
      background-color: ${({ theme }) => theme.natural_50};
    }
    .line-vertical {
      width: 1px;
      height: 12px;
      background-color: ${({ theme }) => theme.natural_300};
    }
    .vertical-last-line {
      display: none;
    }
  }

  .select__placeholder {
    display: flex;
    align-items: center;
  }

  .selected {
    background-color: ${({ theme }) => theme.white};

    &:hover {
      background-color: ${({ theme }) => theme.natural_50};
    }
  }

  .select__input {
    color: transparent !important;
  }

  .custom-input-search {
    .input {
      background-color: ${({ theme }) => theme.natural_50};
      border: 0;
    }
    .search-icon {
      top: 0px;
      left: 0px;
    }
  }
`;

export default SearchableDropdown;
