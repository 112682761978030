import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { getContacts } from '../apis/contacts';

export const useCustomers = ({ params, hasNextPage, ...rest }) => {
  return useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ['contacts', { ...params }],
    select: data => {
      return data?.pages?.reduce((acc, page) => {
        return [...acc, ...page.content];
      }, []);
    },
    placeholderData: keepPreviousData,
    queryFn: ({ pageParam = 0 }) => getContacts({ params: { ...params, page: pageParam }, ...rest }),
    getNextPageParam: lastPage => {
      const { last, number } = lastPage;
      return last ? undefined : number + 1;
    },
  });
};
