import styled from 'styled-components';

export const AutomationDetailsComponentWrapper = styled.div`
  overflow: hidden;
`;

export const AutomationDetailsLeftWrapper = styled.div`
  height: 100% !important;
  min-width: 350px;
  max-width: 350px;
  border-radius: 16px;
  max-height: 100%;
  overflow: hidden;

  .update-detail-contaner {
    display: flex;
    padding: 12px;
    gap: 32px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    background: var(--generic-white, #fff);
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
    .input {
      height: 40px;
    }
  }

  .hover-edit {
    padding: 8px 12px;
    .edit-icon {
      display: none;
      width: 32px;
      height: 32px;
      padding: 0 9px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: var(--primary-50, #eff6ff);
    }

    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      padding: 8px 12px;
      border-radius: 8px;
      .edit-icon {
        display: flex;
      }
    }
  }
  .update-action {
    margin-top: 0;
    padding: 0;
  }
  .name-input {
    height: 40px;
  }

  .description-input {
    font-size: 16px;
  }

  .user-dropdown {
    max-height: 40px;
    border-radius: 16px;
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.borderColor};

    &:hover {
      border-color: ${({ theme }) => theme.borderColor};
    }
    &:focus {
      border-color: ${({ theme }) => theme.borderColor};
    }
    &:active {
      border-color: ${({ theme }) => theme.borderColor};
    }
  }
  .selected {
    background: ${({ theme }) => theme.borderColor};
    .option-text {
      color: ${({ theme }) => theme.natural_900};
    }
  }

  .user-icon {
    height: 32px;
    width: 32px;
    background: ${({ theme }) => theme.natural_900};
    padding: 8px;
    border-radius: 50%;
    span {
      color: ${({ theme }) => theme.borderColor};
    }
  }
  .display-none {
    display: none;
  }
  .menu-wrapper {
    border-radius: 16px;
  }
  .option-wrapper {
    cursor: pointer;
    min-height: 40px;
    max-height: 40px;
    border-radius: 8px;
    pointer: cursor;
    &:hover {
      background: ${({ theme }) => theme.lightGreyBackground};
      .option-text {
        color: ${({ theme }) => theme.backgroundColor};
      }
    }
  }
`;

export const AutomationDetailsHeaderWrapper = styled.div`
  padding: 20px 24px;
`;

export const ConfigurationWrapper = styled.div`
  .vertical-line {
    height: 24px;
    border-color: ${({ theme }) => theme.lightGrey};
  }

  .footer-btn {
    z-index: 2;
    background: ${({ theme }) => theme.primaryGrey};
    border: 1px solid ${({ theme }) => theme.primaryGrey};
    height: 40px;
    padding: 10px auto;
    label {
      color: ${({ theme }) => theme.primary_500};
    }
    &::before {
      content: '';
      top: -24px;
      content: '';
      left: 20px;
      height: 24px;
      background: ${({ theme }) => theme.lightGrey};
      positon: relative;
      position: absolute;
      width: 1px;
      z-index: 1;
    }
  }
  .automation-trigger-wrapper {
    .header-border-left {
      &:before {
        content: '';
        height: 100%;
        width: 4px;
        border-radius: 100px;
        background: ${({ theme }) => theme.lightViolet};
      }
    }
    .sub-header-border {
      width: calc(100% - 24px);
    }
    .dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: ${({ theme }) => theme.lightGrey};
    }
  }
  .automation-action-wrapper {
    z-index: 2;
    &::before {
      content: '';
      top: -24px;
      content: '';
      left: 20px;
      height: 24px;
      background: ${({ theme }) => theme.lightGrey};
      positon: relative;
      position: absolute;
      width: 1px;
      z-index: 1;
    }

    .header-border-left {
      &:before {
        content: '';
        height: 100%;
        width: 4px;
        border-radius: 100px;
        background: ${({ theme }) => theme.additionalGreen};
      }
    }

    .action-container {
      width: calc(100% - 24px);
    }

    .icon-wrapper {
      background: ${({ theme }) => theme.navBackground};
      border-radius: 6px;
      padding: 3px;
    }

    .sub-header-border {
      width: calc(100% - 24px);
    }

    .schedule-conditon-wrapper {
      background: ${({ theme }) => theme.backgroundColor};
    }

    .dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: ${({ theme }) => theme.primaryGreen};
    }
  }
`;
