import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const Card = ({ children, className = {}, ...rest }) => {
  return (
    <CardWrapper className={classNames('card radius-3', className)} {...rest}>
      {children}
    </CardWrapper>
  );
};

const CardWrapper = styled(motion.div)`
  background-color: ${({ theme }) => theme.white};
`;

export default Card;
