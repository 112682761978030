import React, { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as CoinStackIcon } from '../../../../assets/icons/coin-stack.svg';
import { ReactComponent as HandCoinShareIcon } from '../../../../assets/icons/hand-coin-share.svg';
import { ReactComponent as WalletMoneyIcon } from '../../../../assets/icons/wallet-money.svg';
import { OrganisationContext } from '../../../../context/organisationContext';
import { getFormattedNumber } from '../../../../helpers/utils';
import { cn } from '../../../../lib/utils';
import Card from '../../../common/card';
import { StatInfo } from '../../../sites/site-detail-container/site-stats';
import AccountInvoices from './account-invoices';
import AccountTariffs from './account-tariffs/account-tariffs';
import AccountTransactions from './account-transactions';
import AccountUsages from './account-usages/account-usages';

const tabs = [
  {
    label: 'Usages',
    value: 'USAGES',
    isDefault: true,
    permissions: ['TRANSACTIONS_VIEW'],
  },
  {
    label: 'Invoices',
    value: 'INVOICES',
  },
  {
    label: 'Transactions',
    value: 'TRANSACTIONS',
  },
  {
    label: 'Tariffs',
    value: 'TARIFFS',
    permissions: ['ACCOUNTS_VIEW'],
  },
];

const DetailsContainer = ({ data, loading }) => {
  const { hasMultiplePermission } = useContext(OrganisationContext);
  const { balance = 0, average_mrr = 0, total_spend = 0 } = data || {};

  const tabsData = useMemo(() => {
    const filteredTabs = tabs.filter(({ permissions }) => (permissions ? hasMultiplePermission(permissions) : true));
    const defaultTab = filteredTabs.find(tab => tab.isDefault) || filteredTabs[0];
    return { tabs: filteredTabs, defaultTab };
  }, []);

  const [searchParams, setSearchParams] = useSearchParams({ tab: tabsData.defaultTab.value });
  const selectedTab = searchParams.get('tab') || tabsData.defaultTab.value;

  return (
    <div className="flex flex-1 flex-col gap-y-4 min-w-fit h-full">
      <div className="flex gap-x-3 w-full">
        <StatInfo
          icon={WalletMoneyIcon}
          iconColor={'orange_500'}
          label={'Account balance'}
          value={getFormattedNumber(balance)}
          loading={loading}
        />
        <StatInfo
          icon={HandCoinShareIcon}
          iconColor={'error_500'}
          label={'Lifetime Spend'}
          value={getFormattedNumber(total_spend)}
          loading={loading}
        />
        <StatInfo
          icon={CoinStackIcon}
          iconColor={'success_500'}
          label={'Average MRR'}
          value={getFormattedNumber(average_mrr)}
          loading={loading}
        />
      </div>

      <Card className={'flex flex-col gap-y-5 h-full flex-1 overflow-hidden px-6 pt-2 pb-4'}>
        <div className="flex h-fit w-full border-bottom">
          {tabsData.tabs.map(tab => (
            <div
              key={tab.value}
              className={cn(
                'flex cursor-pointer p-4 one-line w-fit inter-400-text',
                selectedTab === tab.value ? 'primary-500-text border-bottom-primary' : 'natural-700-text',
              )}
              onClick={() => setSearchParams({ tab: tab.value })}>
              {tab.label}
            </div>
          ))}
        </div>
        {selectedTab === 'INVOICES' && <AccountInvoices />}
        {selectedTab === 'TRANSACTIONS' && <AccountTransactions />}
        {selectedTab === 'USAGES' && <AccountUsages />}
        {selectedTab === 'TARIFFS' && <AccountTariffs />}
      </Card>
    </div>
  );
};

export default DetailsContainer;
