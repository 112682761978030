import api from '../store/services/index';

const fetchAPI = async ({ url, method, setDataFunc, ...rest }) => {
  try {
    const { data } = await api({ url, method, ...rest });
    setDataFunc && setDataFunc(data);
    return data;
  } catch (error) {
    setDataFunc && setDataFunc(null);
    return Promise.reject(error);
  }
};

export const getAccountDetails = async ({ account_id, ...rest }) => {
  return fetchAPI({ url: `/accounts/${account_id}`, method: 'GET', ...rest });
};

export const getAccountPaymentMethods = async ({ account_id, ...rest }) => {
  return fetchAPI({ url: `/accounts/${account_id}/payment_methods`, method: 'GET', ...rest });
};

export const getAccountEnergyRevenue = async ({ account_id, ...rest }) => {
  return fetchAPI({ url: `/accounts/${account_id}/energy_revenue`, method: 'GET', ...rest });
};
