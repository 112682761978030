import React from 'react';
import styled from 'styled-components';
import { ReactComponent as OrbitLogoWithNameIcon } from '../../../assets/icons/orbit-logo-name.svg';

const AuthContainer = ({ children }) => {
  return (
    <AuthContainerWrapper className="flex">
      <div className="flex flex-1 items-center justify-center logo-container">
        <OrbitLogoWithNameIcon />
      </div>
      <div className="flex flex-1 items-center justify-center children-container overflow-scroll">{children}</div>
    </AuthContainerWrapper>
  );
};

const AuthContainerWrapper = styled.div`
  height: 100dvh;
  width: 100dvw;
  overflow: hidden;
  .logo-container {
    background: linear-gradient(179deg, #9f72ff 0.44%, #6720d9 99.56%);
  }
`;

export default AuthContainer;
