import React from 'react';
import { Tooltip } from 'react-tooltip';
import { cn } from '../../../lib/utils';

const CustomTooltip = ({
  id = '',
  place = 'top',
  clickable = false,
  children,
  content,
  wrapperClassName = '',
  hidden = false,
  noArrow = false,
  isOpen = undefined,
  onBlurTooltip = () => {},
  tooltipId = '',
  opacity = 1,
  positionStrategy = undefined,
  tooltipClassname = '',
  onWrapperClick = () => {},
  wrapperClickable = false,
  ...restProps
}) => {
  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      onBlurTooltip();
    }
  };

  return (
    <div
      onBlur={onBlur}
      role={wrapperClickable ? 'button' : 'tooltip'}
      tabIndex={-1}
      data-tooltip-id={id}
      className={wrapperClassName}
      id={`tooltip-container-${id || tooltipId}`}
      onClick={onWrapperClick}>
      {children}
      <Tooltip
        opacity={opacity}
        id={id || tooltipId}
        place={place}
        clickable={clickable}
        positionStrategy={positionStrategy}
        className={cn(
          '!px-5 !py-4 !z-[1000] !rounded-lg !bg-white natural-900-text !drop-shadow-lg custom-tooltip',
          tooltipClassname,
        )}
        classNameArrow="custom-arrow"
        hidden={hidden}
        noArrow={noArrow}
        isOpen={isOpen}
        {...restProps}>
        {content}
      </Tooltip>
    </div>
  );
};

export default CustomTooltip;
