import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/clock.svg';
import { ReactComponent as HorizontalDots } from '../../../../assets/icons/HorizontalDots.svg';
import { ReactComponent as MouseClickIcon } from '../../../../assets/icons/mouse-click.svg';
import { ReactComponent as TickIcon } from '../../../../assets/icons/tick.svg';
import { ReactComponent as UnionIcon } from '../../../../assets/icons/union.svg';
import { OrganisationContext } from '../../../../context/organisationContext';
import { fieldOptions, typeOptions, unitOptions } from '../../../../helpers/optionData';
import { capitalize, formatText } from '../../../../helpers/utils';
import {
  addAutomationActions,
  getActionIntegrations,
  updateAutomationAction,
} from '../../../../store/features/automationsSlice';
import { addToast } from '../../../../store/features/toastSlice';
import Button from '../../../common/button/button';
import IconContainer from '../../../common/icon-container';
import InputElement from '../../../common/input';
import MenuV2 from '../../../common/menu/menu-v2';
import Dropdown from '../../../common/select-dropdown';
import UpdateAction from '../../../common/update-action';
import IntegrationConfig from './integration-config';

const getDefaultSchedule = scheduleData => {
  const { offset, unit } = scheduleData || {};
  if (!unit) {
    return {};
  }
  return {
    ...scheduleData,
    type: offset < 0 ? 'BEFORE' : 'AFTER',
    offset: Math.abs(offset),
  };
};

const getDefaultCondition = conditionData => {
  if (!conditionData.length) {
    return [{ id: nanoid() }];
  }
  return conditionData.map(condition => ({
    ...condition,
    id: nanoid(),
  }));
};

const ActionSchedule = ({ setShowSchedule, scheduleData, setActionSchedule }) => {
  const { t } = useTranslation();

  const [schedule, setSchedule] = useState(getDefaultSchedule(scheduleData || null));

  const onUpdateSchedule = (key, value) => {
    setSchedule({ ...schedule, [key]: value });
    setActionSchedule({ ...schedule, [key]: value });
  };

  const onRemoveSchedule = () => {
    setActionSchedule(null);
    setShowSchedule(false);
  };

  return (
    <ActionScheduleWrapper className="flex-column">
      <div className="flex items-center justify-between mb-1">
        <label className="inter-600-text natural-700-text">{t('SCHEDULE')}</label>
        <label className="inter-400-text primary-500-text cursor" onClick={onRemoveSchedule}>
          {t('REMOVE')}
        </label>
      </div>
      <div className="flex col-gap-4">
        <InputElement
          type="number"
          placeholder="00"
          onChange={value => onUpdateSchedule('offset', value)}
          value={schedule.offset}
        />
        <Dropdown
          options={unitOptions}
          placeholder={t('SELECT')}
          onChange={option => onUpdateSchedule('unit', option.value)}
          value={unitOptions.find(option => option.value === schedule.unit)}
        />
        <Dropdown
          options={typeOptions}
          placeholder={t('SELECT')}
          onChange={option => onUpdateSchedule('type', option.value)}
          value={typeOptions.find(option => option.value === schedule.type)}
        />
        <Dropdown
          options={fieldOptions}
          placeholder="Select"
          onChange={option => onUpdateSchedule('field', option.value)}
          value={fieldOptions.find(option => option.value === schedule.field)}
        />
      </div>
    </ActionScheduleWrapper>
  );
};

const ActionConditions = ({ setShowConditions, conditionsData, setActionCondition }) => {
  const { t } = useTranslation();

  const { automationDetails } = useSelector(state => state.automations);
  const { event_type } = automationDetails || {};
  const { lexicons } = event_type || {};

  const lexiconOptions = useMemo(() => {
    return lexicons?.map(lexicon => ({ ...lexicon, label: lexicon.name, value: lexicon.lexicon })) || [];
  }, [lexicons]);

  const [conditions, setConditions] = useState(getDefaultCondition(conditionsData || null));

  const onAddCondition = () => {
    setConditions([...conditions, { id: nanoid() }]);
  };

  const onRemoveCondition = id => {
    const newConditions = conditions.filter(condition => condition.id !== id);
    setConditions(newConditions);
    if (newConditions.length === 0) {
      setShowConditions(false);
    }
  };

  const onUpdateCondition = (id, key, value) => {
    const newConditions = conditions.map(condition => {
      if (condition.id === id) {
        return { ...condition, [key]: value };
      }
      return condition;
    });
    setConditions(newConditions);
  };

  useEffect(() => {
    setActionCondition(conditions);
  }, [conditions]);

  return (
    <ActionConditionWrapper>
      <div className="flex-column row-gap-6 mb-5">
        {conditions.map((condition, index) => (
          <div className="flex-column" key={condition.id}>
            <div className="flex items-center justify-between mb-1">
              <label className="inter-500-text natural-900-text">
                {t('CONDITION')} {index + 1}
              </label>
              <label className="inter-400-text primary-500-text cursor" onClick={() => onRemoveCondition(condition.id)}>
                {t('REMOVE')}
              </label>
            </div>
            <div className="condition-container items-center col-gap-4">
              <label className="inter-400-text natural-500-text">{t('WHEN')}</label>
              <Dropdown
                placeholder={t('SELECT')}
                options={lexiconOptions}
                onChange={option => onUpdateCondition(condition.id, 'field', option.lexicon)}
                value={lexiconOptions?.find(lexicon => lexicon.value === condition.field) || null}
              />
              <label className="inter-400-text natural-500-text text-center"> = </label>
              <InputElement
                placeholder={t('ENTER')}
                onChange={value => onUpdateCondition(condition.id, 'value', value)}
                value={condition.value}
              />
            </div>
          </div>
        ))}
      </div>
      <Button label={t('ADD_CONDITION')} className="primary-blue" size="average" onClick={onAddCondition} />
    </ActionConditionWrapper>
  );
};

const SelectedIntegration = ({
  action,
  index,
  integration,
  action_type,
  setSelectedIntegration,
  onCancelOrRemoveAction,
  onResetActionData,
  onAddOrUpdateAction,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { params, schedule = {}, conditions = [], isNew, isEdit, previousData = {} } = action || {};
  const { name, connector } = integration || {};

  const [showSchedule, setShowSchedule] = useState(schedule?.unit);
  const [showConditions, setShowConditions] = useState(!!conditions.length);
  const [actionData, setActionData] = useState({ params, schedule, conditions });
  const [savingActionData, setSavingActionData] = useState(false);

  const updateActionData = (key, value) => {
    setActionData({ ...actionData, [key]: value });
  };

  const onCancelAction = () => {
    if (isNew) {
      onCancelOrRemoveAction(action, true);
    }
    if (isEdit) {
      const resetAction = previousData?.id ? previousData : action;
      onResetActionData({ ...resetAction, isEdit: false });
    }
  };

  const onSaveAction = () => {
    const { params, schedule, conditions } = actionData || {};
    const { offset, type, unit, field } = schedule || {};
    const request = {
      step_number: index - 3,
      action_type: action_type,
      integration: {
        id: integration.id,
      },
      params: params
        ?.filter(p => p.param_value)
        ?.map(p => ({
          param_type: p.param_name,
          param_value: p.param_value,
          display_value: p.display_name || p.param_value,
        })),
      conditions: conditions?.length
        ? conditions
            ?.filter(c => c.value && c.field)
            .map(c => ({
              value: c.value,
              field: c.field,
              display_value: c.value,
              operator: 'EQ',
            }))
        : [],
      schedule:
        offset && type && unit && field
          ? {
              offset: type === 'BEFORE' ? offset * -1 : offset,
              unit: unit,
              field: field,
            }
          : undefined,
    };

    if (isNew) {
      setSavingActionData(true);
      dispatch(addAutomationActions({ automation_id: id, request: request }))
        .then(data => {
          dispatch(addToast({ error: false, text: t('AUTOMATION_ACTION_ADDED'), id: nanoid() }));
          onAddOrUpdateAction({ ...data, action_id: action.action_id }, true);
        })
        .catch(error => dispatch(addToast({ error: true, text: t('ERROR_WHILE_ADDING_ACTION'), id: nanoid() })))
        .finally(() => setSavingActionData(false));
    } else if (isEdit) {
      setSavingActionData(true);
      dispatch(updateAutomationAction({ automation_id: id, action_id: action.id, request: request }))
        .then(data => {
          dispatch(addToast({ error: false, text: t('AUTOMATION_ACTION_UPDATE'), id: nanoid() }));
          onAddOrUpdateAction(data);
        })
        .catch(error => dispatch(addToast({ error: true, text: t('ERROR_WHILE_UPDATING_ACTION'), id: nanoid() })))
        .finally(() => setSavingActionData(false));
    }
  };

  return (
    <div className="flex-column">
      <label className="inter-600-text natural-700-text font-12 mb-4 text-uppercase">{t('DETAILS')}</label>
      <div className="flex-column pxy-5 border radius-1_5 row-gap-6">
        <div className="flex">
          <div className="flex pxy-1 border radius-1_5 action-icon mr-3">
            <img src={connector?.icon?.active} alt="event-type-icon" height={24} width={24} />
          </div>
          <div className="flex-column flex-1">
            <label className="inter-500-text natural-700-text one-line">{name}</label>
            <span
              className="inter-500-text primary-500-text cursor"
              onClick={() => setSelectedIntegration({ isEdit: true })}>
              {t('CHANGE')}
            </span>
          </div>
          <div className="flex col-gap-2">
            <Button
              label={t('ADD_SCHEDULE')}
              className="primary-blue specified-width"
              width="120px"
              size="medium"
              disabled={showSchedule}
              onClick={() => setShowSchedule(true)}
            />
            <Button
              label={t('ADD_CONDITION')}
              className="primary-blue specified-width"
              width="120px"
              size="medium"
              disabled={showConditions}
              onClick={() => setShowConditions(true)}
            />
          </div>
        </div>
        <IntegrationConfig
          integration={integration}
          action_type={action_type}
          integrationConfigValues={actionData.params}
          setIntegrationData={value => updateActionData('params', value)}
        />
        {showSchedule && (
          <ActionSchedule
            action={action_type}
            setShowSchedule={setShowSchedule}
            scheduleData={actionData.schedule}
            setActionSchedule={value => updateActionData('schedule', value)}
          />
        )}
        {showConditions && (
          <ActionConditions
            action={action_type}
            setShowConditions={setShowConditions}
            conditionsData={actionData.conditions}
            setActionCondition={value => updateActionData('conditions', value)}
          />
        )}
        <UpdateAction
          className="justify-end update-action"
          btnClassname="specified-width"
          disabled={savingActionData}
          width="100px"
          size="average"
          onCancel={onCancelAction}
          onUpdate={onSaveAction}
        />
      </div>
    </div>
  );
};

const AutomationEditIntegration = ({
  action,
  index,
  onCancelOrRemoveAction,
  onResetActionData,
  onAddOrUpdateAction,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { action_type: actionTypeObject, integration } = action || {};
  const { action_type } = actionTypeObject || {};

  const [integrations, setIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(integration);
  const [loadingIntegrations, setLoadingIntegrations] = useState(false);

  const fetchIntegrations = () => {
    setLoadingIntegrations(true);
    dispatch(getActionIntegrations({ action_type: action_type }))
      .then(({ data }) => {
        setIntegrations(data);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoadingIntegrations(false));
  };

  useEffect(() => {
    fetchIntegrations();
  }, [action_type]);

  return (
    <AutomationIntegrationWrapper>
      {!selectedIntegration?.id && (
        <div className="flex-column">
          <label className="inter-600-text natural-700-text font-12 mb-4 text-uppercase">
            {t('SELECT_INTEGRATION')}
          </label>
          <div className="flex integration-grid-list">
            {loadingIntegrations && (
              <Fragment>
                <Skeleton height={110} />
                <Skeleton height={110} />
                <Skeleton height={110} />
                <Skeleton height={110} />
              </Fragment>
            )}
            {!loadingIntegrations && !integrations.length && (
              <div>
                <label className="inter-500-text natural-400-text">{t('NO_INTEGRATIONS_FOUND')}</label>
              </div>
            )}
            {!loadingIntegrations &&
              integrations.map(integration => (
                <div
                  className={classNames(
                    'flex-column items-center justify-center cursor border pxy-4 radius-1_5 overflow-hidden',
                    selectedIntegration?.id === integration?.id && 'selected-action',
                  )}
                  onClick={() => setSelectedIntegration(integration)}
                  key={integration?.id}>
                  <div className="flex pxy-1 border radius-1_5 mb-2 action-icon">
                    <img src={integration?.connector?.icon?.active} alt="event-type-icon" height={24} width={24} />
                  </div>
                  <div className="flex-column items-center flex-1 col-gap-3">
                    <label className="inter-500-text natural-700-text one-line">{integration?.name}</label>
                    <label className="inter-400-text natural-500-text font-12 one-line">
                      {integration?.description}
                    </label>
                  </div>
                  {selectedIntegration?.id === integration?.id && (
                    <IconContainer
                      Icon={TickIcon}
                      iconHeight={16}
                      iconWidth={16}
                      iconColor="primary_500"
                      backgroundColor="transparent"
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
      {selectedIntegration?.id && (
        <SelectedIntegration
          action={action}
          index={index}
          integration={selectedIntegration}
          setSelectedIntegration={setSelectedIntegration}
          onCancelOrRemoveAction={onCancelOrRemoveAction}
          onAddOrUpdateAction={onAddOrUpdateAction}
          onResetActionData={onResetActionData}
          action_type={action_type}
        />
      )}
    </AutomationIntegrationWrapper>
  );
};

const AutomationSelectedIntegration = ({ action, onAddOrUpdateAction }) => {
  const { t } = useTranslation();

  const { integration, params = [], schedule = {}, conditions = [] } = action || {};
  const { connector, name } = integration || {};

  const onEditAction = () => {
    onAddOrUpdateAction({ ...action, isEdit: true });
  };

  const renderIntegrationParams = useCallback(params => {
    return (
      <div className="flex-column row-gap-3">
        {!params?.length && (
          <div>
            <label className="inter-400-text natural-400-text">{t('NO_INTEGRATIONS_PARAMS_FOUND')}</label>
          </div>
        )}
        {!!params?.length &&
          params.map(({ id, param_type, param_value, display_value }) => (
            <div
              className={classNames('flex flex-1 items-center col-gap-1', param_type === 'TAGS' && 'items-baseline')}
              key={id}>
              <label className="inter-400-text natural-500-text">{`${capitalize(formatText(param_type))}:`}</label>
              {/* <label className="inter-400-text natural-900-text">{display_value || param_value || '-'}</label> */}
              <label
                className="inter-400-text natural-900-text "
                dangerouslySetInnerHTML={{
                  __html: `<div>${display_value || param_value || '-'}</div>`,
                }}></label>
            </div>
          ))}
      </div>
    );
  }, []);

  const renderIntegrationConditions = useCallback(conditions => {
    return (
      <div className="flex items-center w-fit-content px-2 py-1 bg-natural-100 radius-1_5">
        <IconContainer Icon={UnionIcon} iconColor="natural_900" backgroundColor="transparent" />
        <div className="flex-column ml-1">
          {conditions.map(({ id, field, value, display_value, display_field }, index) => (
            <div className="flex inter-400-text natural-900-text col-gap-1" key={id}>
              <span>{index === 0 ? 'When' : 'And'}</span>
              <span>{display_field || field}</span>
              <span>=</span>
              <span>{display_value || value}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }, []);

  const renderIntegrationSchedule = useCallback(schedule => {
    const { offset, unit, field } = schedule || {};
    if (!(Number(offset) && unit && field)) {
      return null;
    }
    const isBeforeAfterText = offset < 0 ? 'before' : 'after';

    return (
      <div className="flex items-center w-fit-content px-2 py-1 bg-natural-100 radius-1_5">
        <IconContainer Icon={ClockIcon} iconColor="natural_900" backgroundColor="transparent" />
        <div className="flex inter-400-text natural-900-text col-gap-1">
          <span>{Math.abs(offset)}</span>
          <span>{capitalize(formatText(unit))}</span>
          <span>{isBeforeAfterText}</span>
          <span>{field}</span>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="pxy-5 border radius-1_5">
      <div className="flex pb-4 border-bottom">
        <div className="flex pxy-1 border radius-1_5 action-icon mr-3">
          <img src={connector?.icon?.active} alt="event-type-icon" height={16} width={16} />
        </div>
        <div className="flex flex-1 items-center justify-between">
          <label className="inter-500-text natural-700-text one-line">{name}</label>
          <span className="inter-500-text primary-500-text cursor" onClick={onEditAction}>
            {t('EDIT')}
          </span>
        </div>
      </div>
      <div className="mt-5 row-gap-4 flex-column">
        {renderIntegrationParams(params)}
        {!!conditions.length && renderIntegrationConditions(conditions)}
        {renderIntegrationSchedule(schedule)}
      </div>
    </div>
  );
};

const AutomationAction = ({ action, index, onAddOrUpdateAction, onCancelOrRemoveAction, onResetActionData }) => {
  const { t } = useTranslation();

  const { id: automation_id } = useParams();
  const { setModal } = useContext(OrganisationContext);

  const { id, action_type: actionTypeObject, isNew, isEdit } = action || {};
  const { name, description, icon } = actionTypeObject || {};

  const onChangeAction = () => {
    setModal({
      type: 'add-action',
      content: {
        isEdit: true,
        action_type: actionTypeObject,
        onSuccess: newAction => {
          onAddOrUpdateAction(
            {
              ...action,
              previousData: {
                ...action,
              },
              action_type: newAction.action_type,
              integration: null,
              params: [],
              conditions: [],
              schedule: null,
              isEdit: !isNew,
            },
            isNew,
          );
        },
      },
    });
  };

  const onDeleteAction = () => {
    setModal({
      type: 'delete-action',
      content: {
        action: action,
        automation_id: automation_id,
        onSuccess: () => onCancelOrRemoveAction(action, !isNew),
      },
    });
  };

  return (
    <AutomationActionWrapper className="flex-column w-full h-auto px-6 py-5 border radius-1_5 row-gap-6 mt-8">
      <div className="flex col-gap-3 border-bottom pb-4">
        <IconContainer
          iconContainerClassname="border radius-1_5"
          backgroundColor="white"
          Icon={MouseClickIcon}
          iconColor="primary_500"
          iconWidth={35}
          iconHeight={35}
        />
        <div className="flex-column flex-1">
          <div className="flex items-center justify-between">
            <span className="inter-600-text natural-700-text font-18">
              {t('ACTION')} {index}
            </span>
            <MenuV2 Icon={HorizontalDots} menuList={[{ name: t('DELETE'), onClick: onDeleteAction }]} />
          </div>
          <span className="inter-400-text natural-500-text">{t('AUTOMATION_TRIGGER_CRITERIA')}</span>
        </div>
      </div>
      <div className="flex items-center border px-4 py-3 radius-1_5 event-type-container">
        <div className="flex pxy-1 border radius-1_5 mr-2 trigger-icon bg-white">
          <img src={icon?.active} alt="event-type-icon" height={24} width={24} />
        </div>
        <div className="flex-column flex-1">
          <span className="inter-500-text natural-700-text">{name}</span>
          <span className="inter-400-text natural-500-text font-12">{description}</span>
        </div>
        <span className="inter-500-text primary-500-text cursor" onClick={onChangeAction}>
          {t('CHANGE')}
        </span>
      </div>
      {isNew || isEdit ? (
        <AutomationEditIntegration
          action={action}
          index={index}
          onAddOrUpdateAction={onAddOrUpdateAction}
          onCancelOrRemoveAction={onCancelOrRemoveAction}
          onResetActionData={onResetActionData}
        />
      ) : (
        <AutomationSelectedIntegration action={action} onAddOrUpdateAction={onAddOrUpdateAction} />
      )}
    </AutomationActionWrapper>
  );
};

const AutomationActionWrapper = styled.div`
  .event-type-container {
    background-color: ${({ theme }) => theme?.natural_50};
  }
`;

const AutomationIntegrationWrapper = styled.div`
  .update-action {
    padding: 0px;
    margin-top: 8px;
  }

  .integration-grid-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
  }

  .selected-action {
    background-color: ${({ theme }) => theme?.primary_50};
    border: 1px solid ${({ theme }) => theme?.primary_500};

    .action-icon {
      border: 1px solid ${({ theme }) => theme?.primary_300};
      background-color: white;
    }
  }
`;

const ActionScheduleWrapper = styled.div``;

const ActionConditionWrapper = styled.div`
  .condition-container {
    display: grid;
    grid-template-columns: 40px 1fr 20px 1fr;
  }
`;

export default AutomationAction;
