import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as LogoIcon } from '../../assets/icons/orbit-logo.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { updateIntegrationState, updatedIntegrationStateInList } from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';
import MenuV2 from '../common/menu/menu-v2';
import Switch from '../common/switch';

const IntegrationListItem = ({ integration, isFirst = false, menuPortal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setModal } = useContext(OrganisationContext);

  const { id: integrationId, name, description, connector, is_enabled, is_default } = integration;

  const onIntegrationClick = () => {
    navigate(`integration-details/${integrationId}`);
  };

  const onDeleteIntegration = () => {
    setModal({
      type: 'integration-action',
      content: {
        integration: integration,
        top: true,
        isDelete: true,
      },
    });
  };

  const onChangeStatus = async () => {
    const { is_enabled } = integration;
    if (is_enabled) {
      setModal({ type: 'integration-action', content: { integration: integration, top: true, isDisable: true } });
    } else {
      const request = {
        enabled: !is_enabled,
      };
      dispatch(updatedIntegrationStateInList(integration));
      dispatch(updateIntegrationState({ id: integration?.id, request, updateList: false })).catch(error => {
        const errorData = error?.response?.data;
        dispatch(updatedIntegrationStateInList(integration));
        dispatch(
          addToast({
            error: true,
            text: errorData?.error_code === 'BAD_REQUEST' ? t('REQUIRED_INTIGRATION') : t('ERROR_UPDATE_STATUS'),
            id: uuid(),
          }),
        );
      });
    }
  };
  return (
    <IntegrationListItemWrapper
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={integrationId}
      className={classNames('data-container py-2 cursor', {
        'border-top': !isFirst,
      })}
      onClick={onIntegrationClick}>
      <div className="flex items-center overflow-hidden">
        <label className="inter-500-text natural-900-text one-line">{name}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="inter-400-text natural-900-text two-lines">{description}</label>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img alt="icon" className="icons" src={connector?.icon?.active} height={24} width={24} />
          <span className="inter-400-text natural-900-text ml-2">{connector?.name}</span>
        </div>
        {is_default && <LogoIcon height={24} width={48} className="mr-4" />}
      </div>
      <div className="flex items-center justify-center">
        <Switch onClick={() => onChangeStatus()} enabled={is_enabled} disabled={false} />
      </div>
      <div className="flex items-center justify-center">
        <MenuV2
          menuList={[
            { name: t('EDIT'), onClick: () => onIntegrationClick(integration), permission: 'INTEGRATIONS_MANAGE' },
            { name: t('DELETE'), onClick: () => onDeleteIntegration(), permission: 'INTEGRATIONS_MANAGE' },
          ]}
          portal={menuPortal}
        />
      </div>
    </IntegrationListItemWrapper>
  );
};

const IntegrationListItemWrapper = styled(motion.div)`
  min-height: 58px;
  max-height: 58px;

  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }
`;

export default IntegrationListItem;
