import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import PageHeader from '../../components/page-header';
import useDebounce from '../../helpers/useDebounceHook';
import { useAccountsStore } from '../../store/accounts-store';
import { useSitesStore } from '../../store/sites-store';
import AccountDetailContainer from '../accounts/account-detail-container';
import BreadcrumbWrapper from '../common/breadcrumb-wrapper';
import SiteDetailContainer from './site-detail-container';
import SitesList from './sites-list';

const Sites = () => {
  const { t } = useTranslation();
  const { siteDetails: siteDetailsData } = useSitesStore();
  const { accountDetails } = useAccountsStore();

  const { siteDetails } = useSelector(state => state.sites);

  const siteData = siteDetails?.id ? siteDetails : siteDetailsData;

  const { id: siteId, property: { formatted_address } = {} } = siteData || {};
  const { account_number } = accountDetails || {};

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  return (
    <Routes>
      <Route
        element={
          <SiteDetailsWrapper className="flex-column flex-1 pxy-5 bg-natural-150 overflow-auto">
            <BreadcrumbWrapper
              breadcrumbs={[
                { text: t('SITES'), path: `/sites` },
                { text: formatted_address, path: `/sites/${siteId}` },
                { text: account_number },
              ]}
            />
            <AccountDetailContainer />
          </SiteDetailsWrapper>
        }
        path="/:site_id/account/:account_id"
      />
      <Route
        element={
          <SiteDetailsWrapper className="flex-column flex-1 pxy-5 bg-natural-150 overflow-auto">
            <BreadcrumbWrapper breadcrumbs={[{ text: t('SITES'), path: `/sites` }, { text: formatted_address }]} />
            <SiteDetailContainer />
          </SiteDetailsWrapper>
        }
        path="/:site_id"
      />
      <Route
        index
        element={
          <SitesWrapper className="flex-column flex-1">
            <PageHeader
              headerTitle={t('SITES')}
              // showAddNewBtn
              showSearch
              // showSort
              onSortClick={() => {}}
              onAddClick={() => {}}
              addButtonLabel={t('ADD_SITE')}
              addBtnPermission="SITES_MANAGE"
              searchText={searchText}
              onSearchChange={setSearchText}
            />
            <SitesList debouncedSearch={debouncedSearch} />
          </SitesWrapper>
        }
      />
    </Routes>
  );
};

export const SitesWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

const SiteDetailsWrapper = styled.div`
  .page-header {
    padding: 12px 24px;
    background-color: white;
  }
`;

export default Sites;
