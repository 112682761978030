import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getAccountDetails, getAccountEnergyRevenue, getAccountPaymentMethods } from '../apis/accounts';
import { getContacts } from '../apis/contacts';
import { getInvoices } from '../apis/invoices';
import { getTransactions } from '../apis/transactions';

const defaultOptions = {
  retry: 0,
  networkMode: 'online',
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const useAccountDetails = ({ account_id, ...rest }) => {
  return useQuery({
    ...defaultOptions,
    enabled: !!account_id,
    queryKey: ['accountDetails', { account_id }],
    queryFn: () => getAccountDetails({ account_id, ...rest }),
  });
};

export const useContacts = ({ params = {}, ...rest }) => {
  return useQuery({
    ...defaultOptions,
    queryKey: ['contacts-list', { ...params }],
    queryFn: () => getContacts({ params, ...rest }),
  });
};

export const useAccountPaymentMethods = ({ account_id, ...rest }) => {
  return useQuery({
    ...defaultOptions,
    queryKey: ['account-payment-methods', { account_id }],
    queryFn: () => getAccountPaymentMethods({ account_id, ...rest }),
  });
};

export const useInvoices = ({ params, hasNextPage, ...rest }) => {
  return useInfiniteQuery({
    ...defaultOptions,
    initialPageParam: 0,
    queryKey: ['invoices', { ...params }],
    select: data => {
      return data?.pages?.reduce((acc, page) => {
        return [...acc, ...page.content];
      }, []);
    },
    placeholderData: keepPreviousData,
    queryFn: ({ pageParam = 0 }) => getInvoices({ params: { ...params, page: pageParam }, ...rest }),
    getNextPageParam: lastPage => {
      const { last, number } = lastPage;
      return last ? undefined : number + 1;
    },
  });
};

export const useTransactions = ({ params, hasNextPage, ...rest }) => {
  return useInfiniteQuery({
    ...defaultOptions,
    initialPageParam: 0,
    queryKey: ['transactions', { ...params }],
    select: data => {
      return data?.pages?.reduce((acc, page) => {
        return [...acc, ...page.content];
      }, []);
    },
    placeholderData: keepPreviousData,
    queryFn: ({ pageParam = 0 }) => getTransactions({ params: { ...params, page: pageParam }, ...rest }),
    getNextPageParam: lastPage => {
      const { last, number } = lastPage;
      return last ? undefined : number + 1;
    },
  });
};

export const useAccountEnergyRevenue = ({ account_id, params, ...rest }) => {
  return useQuery({
    queryKey: ['account-energy-revenue', { account_id, ...params }],
    queryFn: () => getAccountEnergyRevenue({ account_id, params, ...rest }),
  });
};
