import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LocationPinIcon } from '../../../assets/icons/location-pin.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as MobileIcon } from '../../../assets/icons/mobile.svg';
import { formatText, getContactValueByType } from '../../../helpers/utils';
import { cn, getContactName, getContactNameInitials } from '../../../lib/utils';
import { Avatar } from '../../common/avatar-group';
import Card from '../../common/card';
import IconContainer from '../../common/icon-container';
import Label from '../../common/label';

export const ContactAdditionalInfo = ({ value, Icon, loading, label = '' }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-start">
      <IconContainer
        Icon={Icon}
        backgroundColor="natural_100"
        iconContainerClassname="rounded-2"
        iconColor="natural_600"
        iconWidth={16}
        iconHeight={16}
      />
      <Label
        className={cn('ml-2 inter-400-text text-sm min-h-6 flex items-center', !value && 'natural-400-text')}
        loading={loading}>
        {value || t('NO_TYPE', { type: formatText(label) })}
      </Label>
    </div>
  );
};

const ContactInfo = ({ contact, loading, service_address, billing_address, billing_email }) => {
  const { emails, phones, formatted_address } = contact || {};
  const { formatted_address: billing_formatted_address } = billing_address || {};

  const contactName = getContactName(contact);
  const email = getContactValueByType(emails, 'is_primary', true, 'value') || '';
  const phone = getContactValueByType(phones, 'contact_type', 'MOBILE', 'value') || '';
  const serviceAddressFormatted = service_address ? service_address.formatted_address : '';

  return (
    <Card className="p-6 flex flex-col gap-y-5 rounded-xl">
      <div className="flex flex-col gap-y-2">
        <Label className="inter-600-text font-16">Service Address</Label>
        <ContactAdditionalInfo
          value={serviceAddressFormatted}
          Icon={LocationPinIcon}
          loading={loading}
          label="service address"
        />
      </div>
      <div className="flex flex-col gap-y-2 border-top pt-5">
        <Label className="inter-600-text font-14">Account Billing Information</Label>
        <ContactAdditionalInfo value={billing_formatted_address} Icon={LocationPinIcon} loading={loading} />
        <ContactAdditionalInfo value={billing_email} Icon={MailIcon} loading={loading} />
      </div>
      <div className="border-top pt-5">
        <div className="flex items-center mb-4">
          <Avatar getAvatarName={getContactNameInitials} avatar={contact} variant="size_32" backgroundColor={'black'} />
          <Label className="ml-1 inter-600-text font-semibold text-lg" loading={loading}>
            {contactName}
          </Label>
        </div>
        {/* <IconContainer
          Icon={EditIcon}
          backgroundColor="natural_100"
          iconContainerClassname="p-2 rounded-full cursor-pointer"
          iconColor="natural_700"
          iconWidth={14}
          iconHeight={14}
        /> */}
        <div className="flex flex-col gap-y-2">
          <ContactAdditionalInfo value={phone} Icon={MobileIcon} loading={loading} label="mobile" />
          <ContactAdditionalInfo value={email} Icon={MailIcon} loading={loading} label="email" />
          <ContactAdditionalInfo value={formatted_address} Icon={LocationPinIcon} loading={loading} label="address" />
        </div>
      </div>
    </Card>
  );
};

export default ContactInfo;
