import moment from 'moment';

export const unitOptions = [
  { label: 'Days', value: 'DAY' },
  { label: 'Hours', value: 'HOUR' },
  { label: 'Minutes', value: 'MINUTE' },
];

export const typeOptions = [
  { label: 'Before', value: 'BEFORE' },
  { label: 'After', value: 'AFTER' },
];

export const fieldOptions = [
  { label: 'Appointment date', value: 'appointment.start_date' },
  { label: 'Trigger date', value: 'event.created_on' },
];

export const mainTimeSelectors = [
  {
    label: 'Today',
    value: 'TODAY',
    units: ['HOUR'],
    defaultUnit: 'HOUR',
    start_date: moment().startOf('day').unix(),
    end_date: moment().endOf('day').unix(),
  },
  {
    label: 'Last 7 days',
    value: 'LAST_7_DAYS',
    units: ['DAY'],
    defaultUnit: 'DAY',
    start_date: moment().subtract(6, 'days').startOf('day').unix(),
    end_date: moment().endOf('day').unix(),
  },
  {
    label: 'Last 4 weeks',
    value: 'LAST_4_WEEKS',
    units: ['DAY', 'WEEK'],
    defaultUnit: 'WEEK',
    start_date: moment().subtract(3, 'weeks').startOf('isoWeek').unix(),
    end_date: moment().endOf('isoWeek').unix(),
  },
  {
    label: 'Last 3 months',
    value: 'LAST_3_MONTHS',
    units: ['MONTH', 'WEEK', 'DAY'],
    defaultUnit: 'MONTH',
    start_date: moment().subtract(2, 'months').startOf('month').unix(),
    end_date: moment().endOf('month').unix(),
  },
  {
    label: 'Last 12 months',
    value: 'LAST_12_MONTHS',
    units: ['MONTH', 'WEEK'],
    defaultUnit: 'MONTH',
    start_date: moment().subtract(11, 'months').startOf('month').unix(),
    end_date: moment().endOf('month').unix(),
  },
  {
    label: 'Month to date',
    value: 'MONTH_TO_DATE',
    units: ['DAY', 'WEEK'],
    defaultUnit: 'DAY',
    start_date: moment().startOf('month').unix(),
    end_date: moment().endOf('day').unix(),
  },
  {
    label: 'Quarter to date',
    value: 'QUARTER_TO_DATE',
    units: ['MONTH', 'WEEK', 'DAY'],
    defaultUnit: 'MONTH',
    start_date: moment().startOf('quarter').unix(),
    end_date: moment().endOf('day').unix(),
  },
  {
    label: 'Year to date',
    value: 'YEAR_TO_DATE',
    units: ['MONTH'],
    defaultUnit: 'MONTH',
    start_date: moment().startOf('year').unix(),
    end_date: moment().endOf('day').unix(),
  },
  {
    label: 'All time',
    value: 'ALL_TIME',
    units: ['YEAR'],
    defaultUnit: 'YEAR',
    start_date: 0,
    end_date: moment().endOf('day').unix(),
  },
];

export const timeSelectorUnits = [
  {
    label: 'Hourly',
    value: 'HOUR',
    format: 'HH',
    format2: 'HH:mm',
    momentUnit: 'hour',
    durationRequired: { min: 1, max: 24 },
  },
  {
    label: 'Daily',
    value: 'DAY',
    format: 'DD',
    format2: 'DD MMM',
    momentUnit: 'day',
    durationRequired: { min: 1, max: 31 },
  },
  {
    label: 'Weekly',
    value: 'WEEK',
    format: 'ww',
    format2: 'ww YYYY',
    momentUnit: 'isoWeek',
    durationRequired: { min: 1, max: 4 },
  },
  {
    label: 'Monthly',
    value: 'MONTH',
    format: 'MMM',
    format2: 'MMM YYYY',
    momentUnit: 'month',
    durationRequired: { min: 1, max: 12 },
  },
  {
    label: 'Yearly',
    value: 'YEAR',
    format: 'YYYY',
    format2: 'YYYY',
    momentUnit: 'year',
    durationRequired: { min: 1 },
  },
];

export const weekDaysData = [
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 7 },
];
