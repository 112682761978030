import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as LocationPinIcon } from '../../../assets/icons/location-pin.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as MobileIcon } from '../../../assets/icons/mobile.svg';
import { getContactValueByType } from '../../../helpers/utils';
import { getContactName } from '../../../lib/utils';
import { useAccountsStore } from '../../../store/accounts-store';
import { useSitesStore } from '../../../store/sites-store';
import Button from '../../common/button/button';
import Card from '../../common/card';
import IconContainer from '../../common/icon-container';
import Label from '../../common/label';

const SiteHeader = ({ loading = false, className }) => {
  const navigate = useNavigate();

  const { siteDetails } = useSitesStore();
  const { accountDetails } = useAccountsStore();

  const { property: { formatted_address, name = '' } = {} } = siteDetails || {};

  const { contact, id: account_id } = accountDetails || {};

  const {
    title = '',
    forename = '',
    surname = '',
    emails = [],
    phones = [],
  } = contact || {
    title: '',
    forename: '',
    surname: '',
    emails: [],
    phones: [],
  };

  const contactName = getContactName(contact);
  const email = getContactValueByType(emails, 'is_primary', true, 'value') || '';
  const phone = getContactValueByType(phones, 'contact_type', 'MOBILE', 'value') || '';

  const onAccountDetailsClick = () => {
    navigate(`account/${account_id}`);
  };

  return (
    <SiteHeaderWrapper className="flex col-gap-4 items-center justify-between">
      <Card className="flex-column row-gap-2 flex-1 overflow-hidden px-6 py-6">
        <Label className="inter-600-text font-20" loading={loading}>
          {name}
        </Label>
        <div className="flex items-center">
          <IconContainer
            Icon={LocationPinIcon}
            iconContainerClassname="mr-1 p-0"
            iconColor="natural_500"
            backgroundColor="transparent"
          />
          <Label className="inter-400-text font-14 one-line flex-1" loading={loading}>
            {formatted_address}
          </Label>
        </div>
      </Card>
      <Card className="flex-column row-gap-2 flex-1 overflow-hidden px-6 py-6">
        <div className="flex items-center justify-between col-gap-2">
          <Label className="inter-600-text font-20 flex-1" loading={loading}>
            {contactName}
          </Label>
          <Button
            onClick={onAccountDetailsClick}
            className="primary specified-width px-3 py-1 radius-2"
            label="Details"
            width="fit-content"
          />
        </div>
        <div className="flex items-center col-gap-4">
          <div className="flex items-center">
            <IconContainer
              Icon={MobileIcon}
              iconContainerClassname="mr-1 p-0"
              iconColor="natural_500"
              backgroundColor="transparent"
            />
            <Label
              loading={loading}
              className={classNames('inter-400-text one-line', !phone && 'natural-400-text font-12')}>
              {phone || 'No mobile number added'}
            </Label>
          </div>
          <div className="flex items-center">
            <IconContainer
              Icon={MailIcon}
              iconContainerClassname="mr-1 p-0"
              iconColor="natural_500"
              backgroundColor="transparent"
            />
            <Label
              loading={loading}
              className={classNames('inter-400-text one-line', !email && 'natural-400-text font-12')}>
              {email || 'No email added'}
            </Label>
          </div>
        </div>
      </Card>
    </SiteHeaderWrapper>
  );
};

const SiteHeaderWrapper = styled.div`
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
`;

export default SiteHeader;
