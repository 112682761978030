import { useQuery } from '@tanstack/react-query';
import { getAccountEnergyTariffs, getEnergyTariffDetails } from '../apis/tariffs';

export const useAccountEnergyTariffs = ({ account_id, ...rest }) => {
  return useQuery({
    queryKey: ['account-energy-tariffs', { account_id }],
    queryFn: () => getAccountEnergyTariffs({ account_id, ...rest }),
  });
};

export const useEnergyTariffDetails = ({ energy_tariff_id, params = {}, ...rest }) => {
  return useQuery({
    queryKey: ['energy-tariff-details', { energy_tariff_id, ...params }],
    queryFn: () => getEnergyTariffDetails({ energy_tariff_id, params, ...rest }),
  });
};
