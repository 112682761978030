import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { cn } from '../../../lib/utils';

const IconContainer = ({
  Icon,
  iconHeight = 16,
  iconWidth = 16,
  backgroundColor = 'natural_150',
  iconColor = 'natural_500',
  iconClassName = '',
  iconContainerClassname = '',
  disabled = false,
  onClick = () => {},
  ...rest
}) => {
  return (
    <IconContainerWrapper
      backgroundColor={backgroundColor}
      iconColor={iconColor}
      disabled={disabled}
      onClick={onClick}
      className={cn('flex items-center justify-center p-1 rounded', disabled && 'disabled', iconContainerClassname)}
      {...rest}>
      <Icon height={iconHeight} width={iconWidth} className={classNames('icon', iconClassName)} />
    </IconContainerWrapper>
  );
};

const IconContainerWrapper = styled.div`
  background: ${({ theme, backgroundColor }) => theme[backgroundColor]};

  .icon {
    color: ${({ theme, iconColor }) => theme[iconColor]};
  }

  &.disabled {
    cursor: not-allowed !important;
    pointer-events: none;
    opacity: 0.5;
  }
`;

export default IconContainer;
