import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Loading from '../../../assets/icons/loading.svg';

const InfiniteScrollV2 = ({
  hasMore,
  children,
  fetchMoreData,
  infiniteScrollClassName = '',
  error = false,
  containerId = 'srcollableDiv',
}) => {
  const loader = useRef(null);

  const handleObserver = entities => {
    const target = entities[0];
    if (target.isIntersecting && !error && hasMore) {
      fetchMoreData();
    }
  };

  useEffect(() => {
    const options = {
      root: document.getElementById(containerId),
      rootMargin: '0px',
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [loader.current]);

  return (
    <InfiniteScrollComponentWrapper
      className={classNames('flex-column flex-1 overflow-y-auto overflow-x-hidden', infiniteScrollClassName)}
      id={containerId}>
      {children}
      {hasMore && (
        <div ref={loader} className="item flex items-center justify-center my-1">
          <img alt="loading" height="24px" src={Loading} />
        </div>
      )}
    </InfiniteScrollComponentWrapper>
  );
};

const InfiniteScrollComponentWrapper = styled('div')``;

export default InfiniteScrollV2;
