import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Button from '../button/button';

const UpdateAction = ({
  onUpdate = () => {},
  onCancel = () => {},
  className = '',
  btnClassname = '',
  primaryBtnProps = {},
  ...rest
}) => {
  return (
    <UpdateActionWrapper className={classNames('flex w-full col-gap-2 pxy-2', className)}>
      <Button
        className={classNames('primary-grey specified-width', btnClassname)}
        label="Cancel"
        onClick={onCancel}
        {...rest}
      />
      <Button
        className={classNames('primary', btnClassname)}
        label="Save"
        onClick={onUpdate}
        {...rest}
        {...primaryBtnProps}
      />
    </UpdateActionWrapper>
  );
};

const UpdateActionWrapper = styled('div')``;

export default UpdateAction;
