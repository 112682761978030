import React from 'react';
import { uppercase } from '../../../helpers/utils';
import { cn } from '../../../lib/utils';
import Checkbox from '../checkbox';

const TableHeader = ({
  headers,
  headerClassName = '',
  allSelected = false,
  is_checked_done = false,
  onSelectorClick = () => {},
  headerLabelClassName = '',
}) => {
  return (
    <div
      className={cn('border-bottom header-container letter-spacing-1 flex items-center', headerClassName)}
      style={{ position: 'sticky', top: 0, zIndex: 9 }}>
      {headers.map(header =>
        header.key === 'ITEM_SELECTOR' ? (
          <Checkbox
            key={header.key}
            inputClassname=""
            checked={allSelected}
            onChange={() => onSelectorClick()}
            is_checked_done={is_checked_done}
          />
        ) : (
          <label
            key={header.key}
            className={cn('inter-600-text natural-700-text font-12', headerLabelClassName, header.classname)}>
            {uppercase(header.name)}
          </label>
        ),
      )}
    </div>
  );
};

export default TableHeader;
