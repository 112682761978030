import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAccountDetails } from '../../../hooks/useAccounts';
import { useError } from '../../../hooks/useError';
import { useSiteDetails } from '../../../hooks/useSites';
import { useAccountsStore } from '../../../store/accounts-store';
import { useSitesStore } from '../../../store/sites-store';
import SiteDetails from './site-details';
import SiteHeader from './site-header';
import SiteStats from './site-stats';

const SiteDetailContainer = () => {
  const { t } = useTranslation();

  const { site_id } = useParams();

  const { setSiteDetails } = useSitesStore();
  const { setAccountDetails } = useAccountsStore();

  const {
    data: siteDetails,
    isFetching: isSiteDetailsFetching,
    error: siteDataError,
  } = useSiteDetails({
    site_id,
    setDataFunc: setSiteDetails,
  });

  const { primary_account } = siteDetails || {};
  const { id: account_id } = primary_account || {};

  const {
    data: accountDetails,
    isFetching: isAccountDetailsFetching,
    error: accountDataError,
    refetch: refetchAccountDetails,
    isFetched,
  } = useAccountDetails({ account_id, setDataFunc: setAccountDetails });

  useError({ error: siteDataError, default_message: t('ERROR_FETCHING_SITE_DETAILS') });
  useError({ error: accountDataError, default_message: t('ERROR_FETCHING_ACCOUNT_DETAILS') });

  useEffect(() => {
    const { primary_account } = siteDetails || {};
    setSiteDetails(siteDetails);
    if (primary_account && primary_account.id && !isFetched) {
      refetchAccountDetails();
    }
  }, [siteDetails, isFetched]);

  useEffect(() => {
    setAccountDetails(accountDetails);
  }, [accountDetails]);

  return (
    <div className="flex-column row-gap-4 min-w-fit-content">
      <SiteHeader loading={isSiteDetailsFetching || isAccountDetailsFetching} />
      <div className="flex col-gap-4 flex-1">
        <SiteDetails className="flex-1" />
        <SiteStats loading={isSiteDetailsFetching} />
      </div>
    </div>
  );
};

export default SiteDetailContainer;
