import classNames from 'classnames';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { OrganisationContext } from '../../../context/organisationContext';

const variants = {
  large: { height: 24, width: 40 },
  medium: { height: 20, width: 36 },
  small: { height: 16, width: 20 },
};

const Switch = ({
  enabled,
  onClick,
  permission = '',
  disabled = false,
  switchWrapperClassName = '',
  variant = 'large',
}) => {
  const { checkPermission } = useContext(OrganisationContext);

  const onSwitchChange = () => {
    checkPermission(() => {
      onClick && onClick();
    }, permission);
  };

  return (
    <SwitchWrapper variant={variants[variant]}>
      <div
        className={classNames(
          'flex items-center cursor switch ml-1',
          enabled && 'switch-selected',
          disabled ? 'not-allowed' : 'cursor',
          switchWrapperClassName,
        )}
        onClick={e => {
          e.stopPropagation();
          onSwitchChange();
        }}>
        <div className="selector" />
      </div>
    </SwitchWrapper>
  );
};

const SwitchWrapper = styled('div')`
  .switch {
    width: ${({ variant }) => variant.width}px;
    height: ${({ variant }) => variant.height}px;
    background: ${({ theme }) => theme.lightGrey};
    border-radius: 12px;

    &:hover {
      background: ${({ theme }) => theme.lightGrey};
    }

    .selector {
      min-width: ${({ variant }) => variant.height - 4}px;
      min-height: ${({ variant }) => variant.height - 4}px;
      background: #ffffff;
      border-radius: 50%;
      margin: 0 2px;
      cursor: pointer;
      transition: transform 400ms;
    }
  }

  .switch-selected {
    background: ${({ theme }) => theme.selectedText};

    &:hover {
      background: ${({ theme }) => theme.selectedText};
    }

    .selector {
      transform: translate(16px);
    }
  }
`;

export default Switch;
