import classNames from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as FlashIcon } from '../../../assets/icons/flash-icon.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as RetryIcon } from '../../../assets/icons/retry-new-icon.svg';
import { ReactComponent as SquareMethodIcon } from '../../../assets/icons/square-method-icon.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/time.svg';
import { ReactComponent as AlertIcon } from '../../../assets/icons/vector.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import { capitalize, formatText, isJson, uppercase } from '../../../helpers/utils';

const AutomationLogAction = ({ automationAction, index }) => {
  const { t } = useTranslation();

  const { setModal } = useContext(OrganisationContext);

  const lstErrors = isJson(automationAction?.status_message)
    ? JSON.parse(automationAction?.status_message).errors || []
    : typeof automationAction?.status_message === 'string'
    ? [{ field: automationAction?.status_message, message: automationAction?.status_message }]
    : [];

  const handleRetryAction = () => {
    setModal({
      type: 'automation-log-action',
      content: {
        automationAction: automationAction,
        isRetry: true,
        title: t('RETRY_THIS_ACTION'),
      },
    });
  };

  return (
    <AutomationLogActionWrapper className="automation-log-action-wrapper flex-column border items-start justify-start w-full radius-1_5 px-6 py-5 gap-6">
      <div className="flex-column items-start justify-start w-full">
        <div className="flex items-center w-full border-bottom pb-4">
          <div className="flex items-center gap-3 flex-1">
            <div className="flash-icon border pxy-1-5 radius-1_5">
              <FlashIcon className="primary-text" />
            </div>
            <label className="font-18 inter-600-text natural-700-text">{`${t('ACTION')} ${index + 1}`}</label>
            {automationAction?.status === 'FAILURE' && (
              <div
                className="flex items-center justify-start ml-4 cursor bg-lightgray-1-active gap-6px py-1_5 px-3 radius-full"
                onClick={() => handleRetryAction()}>
                <label className="inter-500-text primary-500-text font-12 line-height-20">{t('RETRY')}</label>
                <RetryIcon className="flex item-center justify-center primary-text" height={14} width={14} />
              </div>
            )}
          </div>
          <div className="flex items-center justify-start">
            <div
              className={classNames(
                'flex items-center justify-center radius-1 status-container w-fit-content',
                formatText(automationAction?.status),
              )}>
              <label className="font-12 success-500-text inter-500-text">
                {capitalize(formatText(automationAction?.status))}
              </label>
            </div>
          </div>
        </div>

        {automationAction?.status === 'FAILURE' && (
          <div className="flex-column items-start justify-start w-full mt-2">
            {lstErrors.map((err, index) => (
              <div
                className={classNames(
                  'flex items-center justify-start alert-conditon-wrapper radius-1_5 pxy-2 action-container mt-2',
                  index === 0 ? '' : 'mt-1',
                )}
                key={err.field}>
                <AlertIcon className="flex justify-center items-center" height={16} width={16} />
                <label className="inter-500-text error-text font-12 ml-2">{err.message}</label>
              </div>
            ))}
          </div>
        )}

        <div className="send-email-box flex items-center justify-start gap-3 radius-1_5 mt-6 pxy-3 border">
          <div className="flex items-center justify-center border radius-1_5 pxy-1_5">
            <MailIcon className="flex items-center justify-center" color="#404040" height={24} width={24} />
          </div>
          <div className="flex-column items-start justify-start flex-1">
            <label className="natural-700-text inter-500-text font-14 line-height-20">
              {automationAction?.action?.action_type?.name}
            </label>
            <label className="natural-500-text inter-400-text font-12 line-height-20">
              {automationAction?.action?.action_type?.description}
            </label>
          </div>
        </div>
      </div>

      <div className="flex-column w-full gap-3">
        <label className="font-12 inter-600-text natural-700-text">{uppercase(formatText(t('DETAILS')))}</label>
        <div className="flex-column items-start justify-start w-full border radius-1_5 pxy-5">
          <div className="flex items-center gap-3 justify-start w-full border-bottom pb-4">
            <div className="icon-wrapper flex items-center justify-center border bg-white radius-1_3">
              <img
                src={`${automationAction?.action?.integration?.connector?.icon?.active}`}
                style={{ width: 17, height: 17 }}
              />
            </div>
            <label className="font-14 inter-500-text natural-700-text">
              {automationAction?.action?.integration?.name}
            </label>
          </div>

          {automationAction?.action?.params.length > 0 && (
            <div className="flex-column items-start justify-start w-full mt-5 gap-3">
              {automationAction?.action?.params?.map((param, index) => (
                <div className={`flex items-center justify-start w-full ${index === 0 ? '' : 'mt-2'}`} key={param.id}>
                  <label className="inter-400-text natural-500-text font-14 line-height-20">
                    {capitalize(formatText(param.param_type, ' '))}: &nbsp;
                  </label>
                  <label className="inter-400-text natural-900-text font-14 line-height-20">
                    {' '}
                    {param.param_value || param.param_value}
                  </label>
                </div>
              ))}
            </div>
          )}

          <div className="flex-column items-start justify-start mt-3">
            {(automationAction?.action?.conditions || []).map((condition, index) => {
              return (
                <div className="flex item-center justify-start schedule-conditon-wrapper py-2 px-4 radius-2">
                  <SquareMethodIcon className="flex mr-1_5 items-center justify-center" height={18} width={18} />
                  <label className="flex font-14 inter-400-text natural-900-text line-height-20">
                    {index == 0 ? 'When' : 'And'} {condition?.field} =
                  </label>
                  <label className="flex font-14 inter-400-text natural-900-text ml-1 line-height-20">
                    {condition?.value}
                  </label>
                </div>
              );
            })}
          </div>

          {automationAction?.action?.schedule?.offset &&
            automationAction?.action?.schedule?.unit &&
            automationAction?.action?.schedule?.field && (
              <div className="flex item-center justify-start schedule-conditon-wrapper py-2 px-4 radius-2 mt-3">
                <ClockIcon className="flex mr-1_5 items-center justify-center" height={18} width={18} />
                <label className="font-14 inter-400-text natural-900-text ml-1">{`${Math.abs(
                  automationAction?.action?.schedule?.offset,
                )}
                      ${formatText(automationAction?.action?.schedule?.unit, ' ')}
                      ${automationAction?.action?.schedule?.offset < 0 ? 'before' : 'after'}
                      ${automationAction?.action?.schedule?.field?.toLowerCase().replaceAll('.', ' ')}`}</label>
              </div>
            )}
        </div>
      </div>
    </AutomationLogActionWrapper>
  );
};

const AutomationLogActionWrapper = styled.div`
  .send-email-box {
    width: 100% !important;
  }

  .flash-icon {
    height: 40px;
    width: 40px;
  }

  .send-email-box {
    background-color: ${({ theme }) => theme.natural_50};
  }

  .icon-wrapper {
    width: 24px;
    height: 24px;
    padding: 3px;
    border-width: 0.512px;
  }

  .alert-conditon-wrapper {
    width: 100% !important;
    border-left: 4px solid ${({ theme }) => theme.error_500};
    background-color: ${({ theme }) => theme.error_50};
  }
`;

export default AutomationLogAction;
