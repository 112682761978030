import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { removeAutomationFromList, updateAutomationStateInList } from '../../store/features/automationsSlice';
import { Avatar } from '../common/avatar-group';
import MenuV2 from '../common/menu/menu-v2';
import Switch from '../common/switch';

const AutomationsListItem = ({ automation, isFirst = false, menuPortal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setModal } = useContext(OrganisationContext);

  const { id: automationId, name, description, is_enabled } = automation;

  const onAutomation = () => {
    navigate(`/platforms/automations/${automationId}/configuration`);
  };

  const onDeleteAutomation = () => {
    setModal({
      type: 'automation-action',
      content: {
        automation: automation,
        top: false,
        isDelete: true,
        onSuccess: () => dispatch(removeAutomationFromList(automation)),
      },
    });
  };

  const onChangeStatus = async () => {
    setModal({
      type: 'automation-action',
      content: {
        automation: automation,
        top: false,
        is_enabled: is_enabled,
        onSuccess: () => dispatch(updateAutomationStateInList(automation)),
      },
    });
  };

  return (
    <AutomationsListItemWrapper
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={automationId}
      className={classNames('data-container py-2 cursor', {
        'border-top': !isFirst,
      })}
      onClick={onAutomation}>
      <div className="flex items-center overflow-hidden">
        <label className="inter-500-text natural-900-text one-line">{name}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="inter-400-text natural-900-text two-lines">{description}</label>
      </div>
      <div className="flex items-center justify-between">
        <Switch onClick={() => onChangeStatus()} enabled={is_enabled} disabled={false} />
      </div>
      <div className="flex items-center">
        {automation.owner?.name && (
          <div className="flex items-center col-gap-1 one-line">
            <Avatar avatar={automation.owner} variant="medium" />
            <span className="inter-400-text natural-900-text one-line flex-1">{automation.owner?.name}</span>
          </div>
        )}
      </div>
      <div className="flex items-center justify-center">
        <MenuV2
          menuList={[
            { name: t('EDIT'), onClick: () => onAutomation(automation), permission: 'AUTOMATIONS_MANAGE' },
            { name: t('DELETE'), onClick: () => onDeleteAutomation(), permission: 'AUTOMATIONS_MANAGE' },
          ]}
          portal={menuPortal}
        />
      </div>
    </AutomationsListItemWrapper>
  );
};

const AutomationsListItemWrapper = styled(motion.div)`
  min-height: 56px;
  max-height: 56px;

  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }

  .status-text {
    font-size: 14px;
  }
`;

export default AutomationsListItem;
