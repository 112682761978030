import moment from 'moment';
import React from 'react';
import { capitalize, formatText, getFormattedDateV2 } from '../../../../../helpers/utils';
import FlexBox from '../../../../common/flex-box/flex';
import Label from '../../../../common/label';

const TariffDetails = ({ tariff = {} }) => {
  const { name, energy_type, billing_frequency, valid_to, valid_from } = tariff;

  const valid_to_moment = valid_to ? moment(valid_to, 'YYYY-MM-DD', true) : null;
  const valid_from_moment = valid_from ? moment(valid_from, 'YYYY-MM-DD', true) : null;
  const contract_length_years =
    valid_to_moment && valid_from_moment ? parseInt(valid_to_moment.diff(valid_from_moment, 'years')) : 0;

  return (
    <div className="flex flex-col px-6 py-6 bg-natural-50 rounded-xl gap-y-2">
      <Label className="inter-600-text font-20 mb-1">{name}</Label>
      <FlexBox className="gap-x-3">
        <Label className="inter-400-text natural-500-text">
          Type: <span className="inter-500-text">{capitalize(formatText(energy_type || '-'))}</span>
        </Label>
        <div className="w-px max-h-4 min-h-4 bg-natural-500" />
        <Label className="inter-400-text natural-500-text">
          Billing Frequency: <span className="inter-500-text">{capitalize(formatText(billing_frequency || '-'))}</span>
        </Label>
      </FlexBox>
      <FlexBox className="gap-x-3 flex-wrap gap-y-1">
        <Label className="inter-400-text natural-500-text">
          Contract Length: <span className="inter-500-text">{contract_length_years || '0'} years</span>
        </Label>
        <div className="w-px max-h-4 min-h-4 bg-natural-500" />
        <Label className="inter-400-text natural-500-text">
          Contract Start:{' '}
          <span className="inter-500-text">
            {valid_from_moment ? getFormattedDateV2({ dateInUnix: valid_from_moment.unix() }) : '-'}
          </span>
        </Label>
        <div className="w-px max-h-4 min-h-4 bg-natural-500" />
        <Label className="inter-400-text natural-500-text">
          Contract End:{' '}
          <span className="inter-500-text">
            {valid_to_moment ? getFormattedDateV2({ dateInUnix: valid_to_moment.unix() }) : '-'}
          </span>
        </Label>
      </FlexBox>
    </div>
  );
};

export default TariffDetails;
