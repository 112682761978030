import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import useDebounce from '../../helpers/useDebounceHook';
import PageHeader from '../page-header';
import AccountsList from './accounts-list';

const AccountsPage = () => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  return (
    <Routes>
      <Route
        index
        element={
          <div className="flex-column flex-1">
            <PageHeader
              headerTitle={t('ACCOUNTS')}
              // showAddNewBtn
              showSearch
              // showSort
              onSortClick={() => {}}
              onAddClick={() => {}}
              addButtonLabel={t('ADD_SITE')}
              addBtnPermission="ACCOUNTS_MANAGE"
              searchText={searchText}
              onSearchChange={setSearchText}
            />
            <AccountsList debouncedSearch={debouncedSearch} />
          </div>
        }
      />
    </Routes>
  );
};

export default AccountsPage;
