import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as HorizontalDotsIcon } from '../../assets/icons/HorizontalDots.svg';
import { capitalizeFirstLetterOfWords, getContactValueByType, getFormattedNumber } from '../../helpers/utils';
import MenuV2 from '../common/menu/menu-v2';
import Status from '../common/status';

const InvoiceListItem = ({ invoice = {}, index = 0 }) => {
  const { t } = useTranslation();

  const {
    id: invoiceId,
    account,
    due_date,
    issued_date,
    status,
    code,
    total_amount,
    created_on,
    paid_date,
    payment_count,
  } = invoice || {};
  const { account_number, contact, billing_email } = account || {};
  const { name, phones, contact_type } = contact || {};
  const isCompany = contact_type === 'COMPANY';
  const mobile = getContactValueByType(phones, 'contact_type', 'MOBILE', 'value') || '';
  const landline = getContactValueByType(phones, 'contact_type', 'LANDLINE', 'value') || '';

  return (
    <InvoiceListItemWrapper
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={invoiceId}
      className={classNames('data-container py-1 cursor', index > 0 && 'border-top')}>
      <div className="flex-column row-gap-2 overflow-hidden py-4">
        <label className="inter-400-text natural-900-text font-14">{code}</label>
      </div>
      <div className="flex-column row-gap-1 overflow-hidden py-4">
        <label className="inter-400-text natural-900-text font-14">{name}</label>
        <label className="inter-400-text natural-500-text font-12">Account number: {account_number}</label>
        <label className="inter-400-text natural-500-text font-12">
          {isCompany ? landline || t('NO_TYPE', { type: 'landline' }) : mobile || t('NO_TYPE', { type: 'phone' })}
        </label>
        <label className="inter-400-text natural-500-text font-12">{billing_email}</label>
      </div>
      <div className="flex-column row-gap-2 overflow-hidden py-4">
        <label className="inter-400-text natural-900-text font-14">
          {created_on ? moment.unix(created_on).format('DD/MM/YYYY') : '-'}
        </label>
      </div>
      <div className="flex-column row-gap-2 overflow-hidden py-4">
        <label className="inter-400-text natural-900-text font-14">
          {issued_date ? moment.unix(issued_date).format('DD/MM/YYYY') : '-'}
        </label>
      </div>
      <div className="flex-column row-gap-2 overflow-hidden py-4">
        <label className="inter-400-text natural-900-text font-14">
          {due_date ? moment.unix(due_date).format('DD/MM/YYYY') : '-'}
        </label>
      </div>
      <div className="flex-column row-gap-2 overflow-hidden py-4">
        <label className="inter-400-text natural-900-text font-14">
          {paid_date ? moment.unix(paid_date).format('DD/MM/YYYY') : '-'}
        </label>
      </div>
      <div className="flex-column row-gap-2 overflow-hidden py-4">
        <label className="inter-400-text natural-900-text font-14">{payment_count}</label>
      </div>
      <div className="flex col-gap-2 overflow-hidden py-4">
        <Status
          status={status}
          statusText={capitalizeFirstLetterOfWords(status?.toLowerCase())}
          statusTextClassName="inter-400-text font-12"
          statusWrapperClassName=""
        />
      </div>
      <div className="flex-column row-gap-2 overflow-hidden py-4">
        <label className="inter-400-text natural-900-text font-14">{getFormattedNumber(total_amount)}</label>
      </div>
      <MenuV2
        menuList={[
          {
            name: t('DETAILS'),
          },
        ]}
        Icon={HorizontalDotsIcon}
        menuWrapperClassname="flex"
        menuClassName="property-menu"
        iconClassname="my-4"
      />
    </InvoiceListItemWrapper>
  );
};

const InvoiceListItemWrapper = styled(motion.div)`
  min-height: 120px;
  max-height: 120px;

  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }

  .address-tooltip {
    padding: 8px 12px;
  }
`;

export default InvoiceListItem;
