import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getErrorDescription } from '../helpers/utils';
import { addToast } from '../store/features/toastSlice';

export const useError = ({
  showToast = true,
  toastMeta = {},
  error = null,
  default_message = '',
  getErrorTextFunc = null,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (error && showToast) {
      const errorText = getErrorTextFunc ? getErrorTextFunc(error) : getErrorDescription(error) || default_message;
      dispatch(addToast({ ...toastMeta, text: errorText, error: true, id: nanoid() }));
    }
  }, [error, showToast]);

  return error;
};
