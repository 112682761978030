import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styled, { useTheme } from 'styled-components';
import Card from '../card';

const CustomTooltip = ({ active, payload, label, yAxisFormatter, xAxisFormatter, ...rest }) => {
  if (active && payload && payload.length) {
    const [firstPayload] = payload;
    return (
      <CustomChartTooltipWrapper className="flex-column row-gap-2 w-fit-content px-3 py-2">
        <div className="inter-600-text natural-700-text">{xAxisFormatter(label, null, firstPayload.payload)}</div>
        {payload.map(({ stroke, name, value }, index) => (
          <div key={index} className="flex items-center justify-between col-gap-4">
            <div className="flex items-center col-gap-2">
              <div style={{ width: '8px', height: '8px', backgroundColor: stroke || 'red', borderRadius: '100%' }} />
              <div className="inter-400-text natural-700-text font-12">{name}</div>
            </div>
            <div className="inter-600-text natural-700-text font-12 ml-8">{yAxisFormatter(value)}</div>
          </div>
        ))}
      </CustomChartTooltipWrapper>
    );
  }

  return null;
};

const LineChartUI = ({
  className,
  data,
  useDummy = false,
  xAxisDataKey,
  linesArray = [],
  xAxisFormatter = value => value,
  yAxisFormatter = value => value,
}) => {
  const theme = useTheme();
  const axisColor = useDummy ? theme.natural_400 : theme.natural_700;

  return (
    <ResponsiveContainer className={className} width="100%" height="100%">
      <LineChart data={data} margin={{ left: -10, top: 10, right: 10 }}>
        <XAxis
          dataKey={xAxisDataKey}
          tickLine={false}
          tick={{ fontSize: 10, fill: axisColor }}
          tickFormatter={xAxisFormatter}
          axisLine={false}
        />
        <YAxis
          tickFormatter={yAxisFormatter}
          tick={{ fontSize: 10, fill: axisColor }}
          tickLine={false}
          axisLine={false}
          width={90}
          tickMargin={10}
        />
        <CartesianGrid strokeDasharray="0" vertical={false} stroke={theme.natural_200} />
        <Tooltip
          cursor={{
            stroke: theme.natural_400,
            strokeWidth: 1,
            strokeDasharray: '1 1',
          }}
          xAxisFormatter={xAxisFormatter}
          yAxisFormatter={yAxisFormatter}
          content={<CustomTooltip />}
        />
        {linesArray.map((line, index) => (
          <Line
            key={index}
            name={line.name}
            type={line.type || 'monotone'}
            dataKey={line.dataKey}
            stroke={line.stroke}
            strokeWidth={line.strokeWidth || 4}
            strokeLinecap="round"
            dot={false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

const CustomChartTooltipWrapper = styled(Card)`
  box-shadow: 0px 2px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
`;

export default LineChartUI;
