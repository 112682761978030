import React, { Fragment } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AccountActivation from '../components/account-activation';
import ForgotPassword from '../pages/forgot-password/forgot-password';
import Login from '../pages/login';
import Main from '../pages/main/main';
import Oauth2Redirect from '../pages/oauth2-redirect';
import Toast from '../popup/toast/toast';
import history from './history';
import { PrivateRoute } from './privateRoute';

const routes = createBrowserRouter([
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'password',
    element: <ForgotPassword />,
  },
  {
    path: 'activate',
    element: <AccountActivation />,
  },
  {
    path: 'oauth2_redirect',
    element: <Oauth2Redirect />,
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: '*',
        element: <Main />,
      },
    ],
  },
]);

export const Router = () => {
  return (
    <Fragment>
      <RouterProvider history={history} router={routes} />
      <Toast />
    </Fragment>
  );
};
