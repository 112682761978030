import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { getFormattedDateV2, getFormattedNumber, getFormattedNumberStyle } from '../../../../../helpers/utils';
import { cn } from '../../../../../lib/utils';
import FlexBox from '../../../../common/flex-box/flex';
import Label from '../../../../common/label';
import TableHeader from '../../../../common/table-header';

const aggregationHeaders = [
  { name: 'UNIT', key: 'UNIT', classname: 'pl-4' },
  { name: 'TOTAL CONSUMED', key: 'TOTAL_CONSUMED' },
  { name: 'CONSUMPTION REVENUE', key: 'CONSUMPTION_REVENUE' },
  { name: 'TOTAL EXPORT', key: 'TOTAL_EXPORT' },
  { name: 'EXPORT REVENUE', key: 'EXPORT_REVENUE' },
  { name: 'TOTAL REVENUE', key: 'TOTAL_REVENUE' },
];

const AggregationItem = ({ aggregation, dateFormat, showBorderTop = false }) => {
  const { date, autoconsumption, export: aggregationExport, net_revenue } = aggregation || {};
  const { total_kwh: consumption_kwh, net_revenue: consumption_revenue } = autoconsumption || {};
  const { total_kwh: export_kwh, net_revenue: export_revenue } = aggregationExport || {};

  const infoData = [
    { key: 'UNIT', showRightBorder: true, value: getFormattedDateV2({ dateInUnix: date, format: dateFormat }) },
    { key: 'TOTAL CONSUMED', value: getFormattedNumberStyle(consumption_kwh, 'decimal', 0, 0), unit: 'kWh' },
    { key: 'CONSUMPTION REVENUE', value: getFormattedNumber(consumption_revenue || 0) },
    { key: 'TOTAL EXPORT', value: getFormattedNumberStyle(export_kwh, 'decimal', 0, 0), unit: 'kWh' },
    { key: 'EXPORT REVENUE', value: getFormattedNumber(export_revenue || 0) },
    { key: 'TOTAL REVENUE', value: getFormattedNumber(net_revenue || 0) },
  ];

  return (
    <div
      className={cn(
        'pr-4 min-h-[52px] grid grid-cols-account-revenue-aggregation-grid gap-x-4',
        showBorderTop && 'border-top',
      )}>
      {infoData.map(({ key, value, unit = '', showRightBorder = false }, index) => (
        <FlexBox key={key} className={cn(showRightBorder && 'border-right bg-natural-100', index === 0 && 'px-4')}>
          <Label className="inter-400-text natural-700-text">
            {value} {unit}
          </Label>
        </FlexBox>
      ))}
    </div>
  );
};

const RevenueAggregation = ({ dateSelection, isFetching, time_aggregation }) => {
  const { t } = useTranslation();
  const { unit, format } = dateSelection;

  const isDataEmpty = !time_aggregation || time_aggregation.length === 0;

  return isFetching ? (
    <Skeleton containerClassName="h-full tracking-[1px]" height={'100%'} />
  ) : (
    <div className="flex flex-col rounded-lg border overflow-x-auto">
      <TableHeader
        headerClassName="pr-4 bg-natural-50 min-w-full w-fit min-h-[52px] grid grid-cols-account-revenue-aggregation-grid gap-x-4"
        headers={aggregationHeaders.map(({ key, name, ...rest }) => ({
          ...rest,
          key,
          name: key === 'UNIT' ? unit : name,
        }))}
      />
      {isDataEmpty ? (
        <div className="flex flex-1 items-center justify-center py-4">
          <span className="inter-400-text natural-500-text">{t('NO_TYPE', { type: 'usages' })}</span>
        </div>
      ) : (
        time_aggregation.map((aggregation, index) => (
          <AggregationItem
            key={aggregation.date}
            unit={unit}
            aggregation={aggregation}
            showBorderTop={index !== 0}
            dateFormat={format}
          />
        ))
      )}
    </div>
  );
};

export default RevenueAggregation;
