import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as NoDocumentIcon } from '../../../../assets/icons/empty-document.svg';
import InfiniteScrollV2 from '../../../common/infinite-scroll-v2';
import NoData from '../../../common/no-data';
import SkeletonTransition from '../../../common/skeleton-transition';
import TableHeader from '../../../common/table-header';
import AutomationLogItem from './automation-table-items';

const automationLogTableHeaders = [
  { name: 'ID', key: 'ID' },
  { name: 'DATE', key: 'DATE' },
  { name: 'STATUS', key: 'STATUS' },
  { name: '', key: 'MENU' },
];

const AutomationTable = ({ debouncedSearch = '', showLoading = false, fetchMoreData = () => {} }) => {
  const { t } = useTranslation();
  const { automationLogDetailsList } = useSelector(state => state.automations);
  const { content: automation, last } = automationLogDetailsList || {};

  return (
    <AutomationLogsDetailsWrapper className="flex-column flex-1 radius-1 mt-6 px-6 overflow-hidden w-full pb-5">
      <SkeletonTransition
        loading={showLoading}
        loaderClassName={'item flex items-center justify-center flex-1 mb-6'}
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        {automation?.length ? (
          <div className="w-full card radius-1_5 border overflow-scroll">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader headers={automationLogTableHeaders} />
              <InfiniteScrollV2 hasMore={!last} fetchMoreData={fetchMoreData}>
                {(automation || []).map((automationLog, index) => (
                  <AutomationLogItem key={automationLog.id} automationLog={automationLog} />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        ) : debouncedSearch ? (
          <NoData className="flex-1" title={t('NO_RESULTS_FOUND')} description={t('NO_RESULTS_DESCRIPTION')} />
        ) : (
          <div className="flex-column flex-1 items-center justify-center">
            <NoData
              title={t('NO_DATA')}
              className="search-terms"
              description={t('NO_DATA_DESCRIPTION')}
              EmptyIcon={NoDocumentIcon}
              iconClassName="relative"
            />
          </div>
        )}
      </SkeletonTransition>
    </AutomationLogsDetailsWrapper>
  );
};

export default AutomationTable;

export const AutomationLogsDetailsWrapper = styled.div`
  border-radius: 6px;

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) 200px 150px 80px;
    column-gap: 48px;
    padding: 0px 24px;
  }
`;
