import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const sitesSlice = createSlice({
  name: 'sites',
  initialState: {
    sites: {},
    siteDetails: {},
    energy_readings: {},
    accountDetails: {},
  },
  reducers: {
    setSites: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.sites = { ...data, content: [...state.sites.content, ...data.content] };
      } else {
        state.sites = data;
      }
    },
    setSiteDetails: (state, { payload }) => {
      state.siteDetails = payload;
    },
    setEnergyReadings: (state, { payload }) => {
      state.energy_readings = payload;
    },
    setAccountDetails: (state, { payload }) => {
      state.accountDetails = payload;
    },
  },
});

export const getSites =
  ({ params = {}, merge = false }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/sites`, { params: params });
      dispatch(setSites({ data, merge }));
      return Promise.resolve({ data, merge });
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getSiteDetails =
  ({ site_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/sites/${site_id}`, { params: params });
      dispatch(setSiteDetails(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getEnergyReadings =
  ({ type, storeData = false, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/energy_readings/${type}`, { params: params });
      storeData && dispatch(setEnergyReadings(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getAccountDetails =
  ({ account_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/accounts/${account_id}`, { params: params });
      dispatch(setAccountDetails(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setSites, setSiteDetails, setEnergyReadings, setAccountDetails } = sitesSlice.actions;
export default sitesSlice.reducer;
