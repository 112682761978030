import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getOrganizationMasquerade, getOrganizationNetworks } from '../apis/organizations';

export const useOrganizationNetworks = ({ organization_id, params, hasNextPage, ...rest }) => {
  return useInfiniteQuery({
    enabled: !!organization_id,
    initialPageParam: 0,
    queryKey: ['organization-networks-list', { organization_id, ...params }],
    select: data => {
      return data?.pages?.reduce((acc, page) => {
        return [...acc, ...page.content];
      }, []);
    },
    placeholderData: keepPreviousData,
    queryFn: ({ pageParam = 0 }) =>
      getOrganizationNetworks({ organization_id, params: { ...params, page: pageParam }, ...rest }),
    getNextPageParam: lastPage => {
      const { last, number } = lastPage;
      return last ? undefined : number + 1;
    },
  });
};

export const useOrganizationMasquerade = ({ organization_id, ...rest }) => {
  return useQuery({
    enabled: false,
    queryKey: ['organization-masquerade', { organization_id }],
    queryFn: () => getOrganizationMasquerade({ organization_id, ...rest }),
  });
};
