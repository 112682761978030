import moment from 'moment';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFormattedNumber, getFormattedNumberStyle } from '../../../../../helpers/utils';
import { useAccountEnergyRevenue } from '../../../../../hooks/useAccounts';
import { useError } from '../../../../../hooks/useError';
import { cn } from '../../../../../lib/utils';
import Button from '../../../../common/button/button';
import FlexBox from '../../../../common/flex-box/flex';
import Label from '../../../../common/label';
import MultiDateSelector from '../../../../common/multi-date-selector';
import RevenueAggregation from './revenue-aggregation';

const dateUnits = [
  {
    label: 'Daily',
    value: 'DAY',
    unit: 'DAY',
    format: 'DD/MM/YYYY',
    start_date: moment().startOf('month').unix(),
    end_date: moment().endOf('day').unix(),
  },
  {
    label: 'Monthly',
    value: 'MONTH',
    unit: 'MONTH',
    format: 'MMMM',
    start_date: moment().startOf('year').unix(),
    end_date: moment().endOf('month').unix(),
  },
];

const UsageActionSelections = ({ dateSelection, setDateSelection }) => {
  const { start_date, end_date } = dateSelection;

  const onDateChange = ({ start_date, end_date }) => {
    setDateSelection({ ...dateSelection, start_date, end_date });
  };

  return (
    <FlexBox className="gap-x-4">
      <FlexBox>
        {dateUnits.map(unit => (
          <Button
            label={unit.label}
            key={unit.value}
            size="medium"
            onClick={() => setDateSelection(unit)}
            width="100px"
            borderRadius="6px"
            className={cn('transparent specified-width px-4 py-2 min', {
              secondary: dateSelection.value === unit.value,
            })}>
            {unit.label}
          </Button>
        ))}
      </FlexBox>
      <MultiDateSelector
        selectedDates={{ start_date: start_date, end_date: end_date }}
        setSelectedDates={dates => onDateChange(dates)}
      />
    </FlexBox>
  );
};

const UsageRevenueInfo = ({ data, isFetching }) => {
  const { autoconsumption_kwh, autoconsumption_net_revenue, export_kwh, export_net_revenue, net_revenue } = data || {};
  const revenueInfoBricks = [
    { label: 'Total Consumption', value: getFormattedNumberStyle(autoconsumption_kwh, 'decimal', 0, 0), unit: 'kWh' },
    { label: 'Total Consumption Revenue', value: getFormattedNumber(autoconsumption_net_revenue || 0) },
    { label: 'Total Export', value: getFormattedNumberStyle(export_kwh, 'decimal', 0, 0), unit: 'kWh' },
    { label: 'Total Export Revenue', value: getFormattedNumber(export_net_revenue || 0) },
    { label: 'Total Revenue', value: getFormattedNumber(net_revenue || 0) },
  ];

  return (
    <FlexBox className="gap-x-2 items-stretch">
      {revenueInfoBricks.map(({ label, value, unit }) => (
        <FlexBox
          key={label}
          className="flex items-start justify-between flex-wrap flex-1 flex-col gap-y-1 px-4 py-4 bg-natural-100 rounded-md">
          <Label className="inter-400-text natural-500-text">{label}</Label>
          <Label className="inter-600-text font-20" loading={isFetching}>
            {value} {unit || ''}
          </Label>
        </FlexBox>
      ))}
    </FlexBox>
  );
};

const AccountUsages = () => {
  const { account_id } = useParams();

  const [dateSelection, setDateSelection] = useState(dateUnits.find(({ value }) => value === 'DAY'));

  const { start_date, end_date, unit } = dateSelection;

  const { data, isFetching, error } = useAccountEnergyRevenue({ account_id, params: { start_date, end_date, unit } });
  useError({ error, default_message: 'Failed to fetch account energy revenue' });

  const { time_aggregation } = data || {};

  return (
    <div className="flex flex-col flex-1 gap-y-6 h-full overflow-hidden pt-2">
      <div className="flex justify-between">
        <UsageActionSelections dateSelection={dateSelection} setDateSelection={setDateSelection} />
      </div>
      <UsageRevenueInfo data={data} isFetching={isFetching} />
      <RevenueAggregation dateSelection={dateSelection} time_aggregation={time_aggregation} isFetching={isFetching} />
    </div>
  );
};

export default AccountUsages;
