import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as BankAccount } from '../../../assets/icons/bank-icon.svg';
import { ReactComponent as AmericanExpress } from '../../../assets/icons/cards/american-express.svg';
import { ReactComponent as Discover } from '../../../assets/icons/cards/discover.svg';
import { ReactComponent as Master } from '../../../assets/icons/cards/master.svg';
import { ReactComponent as Visa } from '../../../assets/icons/cards/visa.svg';
import { capitalize, formatText, getFormattedDateV2 } from '../../../helpers/utils';
import { cn } from '../../../lib/utils';
import Card from '../../common/card';
import IconContainer from '../../common/icon-container';
import Label from '../../common/label';

const getPaymentMethodImage = brand => {
  switch (brand) {
    case 'visa':
      return Visa;
    case 'amex':
      return AmericanExpress;
    case 'discover':
      return Discover;
    case 'mastercard':
      return Master;
    default:
      return BankAccount;
  }
};

const PaymentMethodData = ({ payment, loading }) => {
  const { bank, card, brand, type, last_successful_payment } = payment || {};
  const { t } = useTranslation();
  const { funding } = card || {};
  const { name: bank_name, account_number, sort_code } = bank || {};
  const isCardPayment = type === 'CARD';
  const card_funding = capitalize(formatText(funding || ''));
  const paymentMethodName = isCardPayment ? `${card_funding} Card` : capitalize(formatText(bank_name || type || ''));
  const subPaymentMethodName = isCardPayment ? capitalize(formatText(brand)) : t('BANK_ACCOUNT');
  const paymentMethodImage = getPaymentMethodImage(brand);

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-x-2">
        <IconContainer
          Icon={paymentMethodImage}
          iconWidth={isCardPayment ? 52 : 32}
          iconHeight={32}
          iconContainerClassname="p-0"
          iconColor="natural_400"
          backgroundColor="transparent"
        />
        <div className="flex flex-col items-start h-full">
          <Label className="inter-500-text leading-none">{paymentMethodName || '-'}</Label>
          <Label className="inter-400-text natural-500-text font-12">{subPaymentMethodName}</Label>
        </div>
      </div>
      {!isCardPayment && (
        <div className="flex items-center justify-between gap-2 mt-2">
          <div className="p-2 bg-natural-100 rounded-md w-1/2">
            <p className="inter-400-text natural-500-text font-10">{t('ACCOUNT_NUMBER')}</p>
            <p className="inter-500-text natural-900-text font-12">{account_number}</p>
          </div>
          <div className="p-2 bg-natural-100 rounded-md w-1/2">
            <p className="inter-400-text natural-500-text font-10">{t('SORT_CODE')}</p>
            <p className="inter-500-text natural-900-text font-12">{sort_code}</p>
          </div>
        </div>
      )}
      {last_successful_payment && (
        <Label className="inter-400-text natural-400-text font-12">
          Last successful payment on {getFormattedDateV2({ dateInUnix: last_successful_payment })}
        </Label>
      )}
    </div>
  );
};

const PaymentMethod = ({ loading, paymentMethods = [] }) => {
  const { t } = useTranslation();

  const isPaymentMethodsAvailable = paymentMethods && paymentMethods.length > 0;

  return (
    <Card className="p-6 rounded-xl flex flex-col gap-y-2">
      <div className="flex items-center justify-between border-b pb-2 mb-2">
        <div className="flex w-full items-center justify-between">
          <h1 className={cn('inter-600-text font-16 font-semibold text-lg')}>{t('PAYMENT_METHOD')}</h1>
          {/* <IconContainer
            Icon={AddIcon}
            backgroundColor="primary_50"
            iconContainerClassname="p-2 rounded-full cursor-pointer"
            iconColor="primary_500"
            iconWidth={14}
            iconHeight={14}
          /> */}
        </div>
      </div>
      {loading ? (
        <Skeleton height={50} />
      ) : (
        <Fragment>
          {!isPaymentMethodsAvailable && (
            <Label className="inter-400-text natural-400-text text-sm min-h-6 flex items-center">
              {t('NO_TYPE', { type: 'payment methods' })}
            </Label>
          )}
          {isPaymentMethodsAvailable && (
            <div className="flex flex-col gap-y-5">
              {paymentMethods.map(payment => (
                <PaymentMethodData key={payment.id} payment={payment} />
              ))}
            </div>
          )}
        </Fragment>
      )}
    </Card>
  );
};

export default PaymentMethod;
