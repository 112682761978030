import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useBlocker } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import HidePassword from '../../assets/icons/hide-password.svg';
import ShowPassword from '../../assets/icons/show-password.svg';
import { getErrorDescription } from '../../helpers/utils';
import {
  connectOauth2,
  disconnectOauth2,
  getIntegrationConfig,
  getIntegrationTemplateListOptions,
  setIntegrationConfigsDetail,
  setIntegrationDetails,
  updateIntegration,
} from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';
import { IntegrationsDetailRightWrapper } from '../../styles/components/integrations-detail/integrations-detail.styled';
import Button from '../common/button/button';
import DropDown from '../common/dropdown';
import UpdateAction from '../common/update-action';

const BlockerPrompt = ({ when }) => {
  const dispatch = useDispatch();
  let blocker = useBlocker(when);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      dispatch(addToast({ error: true, text: 'Please fill and save all required fields', id: nanoid() }));
    }
  }, [blocker]);

  return null;
};

const integrationDetailsRight = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const configClosedBefore = useRef(new Map());

  const { integrationDetails, integrationConfigsDetail } = useSelector(state => state.integrations);
  const { integration_params, oauth2, id: integration_id } = integrationDetails || {};
  const isOauth2Connected = oauth2 === 'CONNECTED';

  const [configDetails, setConfigDetails] = useState([]);
  const [configDetailValues, setConfigDetailValues] = useState([]);
  const [updateIntegrationDetail, setUpdateIntegrationDetail] = useState({});
  const [showPassword, setShowPassword] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [oauth2Loading, setOauth2Loading] = useState(false);
  const [listConfigOptions, setListConfigOptions] = useState({});
  const [allValuesAvailable, setAllValuesAvailable] = useState(false);

  const getOptions = async curr => {
    const requiredParamValues = curr?.required_params?.reduce((acc, curr) => {
      const param = configDetailValues.find(p => p.param_name === curr);
      if (param) {
        acc[curr] = param.param_value;
      }
      return acc;
    }, {});

    return await dispatch(
      getIntegrationTemplateListOptions({
        integration_id: integration_id,
        list_id: curr?.id,
        params: requiredParamValues,
      }),
    )
      .then(response => {
        const responseData = response?.data?.map(item => ({ ...item, value: item.id, label: item.name })) || [];
        return responseData;
      })
      .catch(err => {
        return [];
      });
  };

  const getConfigChoices = async config => {
    const { choices, required_params } = config;
    const listChoice = listConfigOptions[config.id];
    if (choices) {
      return Promise.resolve(choices);
    }
    if (listChoice) {
      return Promise.resolve(listChoice);
    }
    const isValueAvailable = required_params?.length
      ? configDetailValues?.find(param => required_params?.includes(param.param_name))
      : true;
    if (!isValueAvailable) {
      return [];
    }
    const response = await getOptions(config);
    setListConfigOptions(prev => ({ ...prev, [config.id]: response }));
    return response;
  };

  const getConditional = async config => {
    const findCondition = integrationConfigsDetail.filter(
      c => c.conditional?.param_name === config.id || c.required_params?.includes(config.id),
    );
    const finalData = await Promise.all(
      findCondition.map(async f => ({
        ...f,
        value: getIntegrationParamValue(f.id),
        depends: await getConditional(f),
        choices: await getConfigChoices(f),
      })),
    );
    return finalData;
  };

  useEffect(() => {
    const { oauth2, connector } = integrationDetails || {};
    if (oauth2 === 'DISCONNECTED') {
      onOauth2Connect();
    }
  }, [integrationDetails.id]);

  useEffect(() => {
    if (integrationDetails?.id && oauth2 !== 'DISCONNECTED') {
      setLoading(true);
      dispatch(getIntegrationConfig({ connectorId: integrationDetails?.connector?.id }))
        .catch(error => {
          const errorText = getErrorDescription(error, t('ERROR_WHILE_FETCHING_INTEGRATION_CONFIG'));
          dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
        })
        .finally(() => setLoading(false));
    }
    return () => {
      dispatch(setIntegrationConfigsDetail([]));
    };
  }, [integrationDetails?.id, oauth2]);

  useEffect(() => {
    setConfigDetailValues(integration_params);
  }, [integration_params]);

  useEffect(() => {
    const fetchData = async () => {
      const configs = await Promise.all(
        integrationConfigsDetail?.map(async curr => {
          if (!curr.conditional && !curr.required_params?.length) {
            const data = await getConditional(curr);
            const choices = await getConfigChoices(curr);
            return { ...curr, value: getIntegrationParamValue(curr.id), depends: data, choices };
          }
          return Promise.resolve(null);
        }),
      );

      const availableConfigs = configs?.filter(c => c);
      const isAllValuesAvailable = availableConfigs
        .reduce((acc, curr) => {
          if (curr.depends) {
            acc.push(...curr.depends);
          }
          return [...acc, curr];
        }, [])
        .filter(param => !param.optional)
        .every(param => param.value);
      setAllValuesAvailable(isAllValuesAvailable);

      setConfigDetails(availableConfigs);
    };
    fetchData();
  }, [integrationConfigsDetail, configDetailValues]);

  const getIntegrationParamValue = name => {
    return configDetailValues?.find(param => param.param_name === name)?.param_value || '';
  };

  const updateIntegrationParams = (name, value, text) => {
    const isAvailable = configDetailValues?.find(param => param.param_name === name);
    if (isAvailable) {
      setConfigDetailValues([
        ...configDetailValues.map(param =>
          param.param_name === name ? { ...param, param_value: value, param_text: text ?? value } : param,
        ),
      ]);
    } else {
      setConfigDetailValues([
        ...configDetailValues,
        { param_name: name, param_value: value, param_text: text ?? value },
      ]);
    }
    setErrorFields({ ...errorFields, [name]: false });
  };

  const getConfigValueToDisplay = config => {
    const { param_type, value, choices } = config;
    if (param_type === 'LIST') {
      return choices?.find(c => c.id === value)?.name || '';
    }
    return value || '';
  };

  const onEditConfig = config => {
    const { config: previousConfig, previousParams } = updateIntegrationDetail;
    let detailValue = configDetailValues;
    if (previousConfig && previousConfig?.id !== config?.id) {
      detailValue = previousParams;
    }
    setConfigDetailValues(detailValue);
    setUpdateIntegrationDetail({ config, previousParams: detailValue });
  };

  const onCancelUpdate = config => {
    if (loading) {
      return;
    }
    configClosedBefore.current = { ...configClosedBefore.current, [config.id]: true };
    setUpdateIntegrationDetail({});
    if (updateIntegrationDetail?.config?.id) {
      setConfigDetailValues(updateIntegrationDetail.previousParams);
    }
  };

  const getSelectedConfigs = () => {
    const selectedConfigs = integrationConfigsDetail.reduce((acc, curr) => {
      if (curr.conditional) {
        const isAvailable = getIntegrationParamValue(curr.conditional.param_name) === curr.conditional.param_value;
        if (isAvailable) {
          acc.push(curr);
        }
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
    return selectedConfigs;
  };

  const onUpdate = config => {
    if (loading) {
      return;
    }
    const selectedConfigs = getSelectedConfigs();
    const request = {
      ...integrationDetails,
      integration_params:
        configDetailValues
          .filter(p => selectedConfigs.find(config => config.id === p.param_name))
          .map(param => ({ ...param, param_value: param.param_value || null })) || [],
    };
    setLoading(true);
    dispatch(updateIntegration({ id: integrationDetails.id, request }))
      .then(() => {
        configClosedBefore.current = {
          ...configClosedBefore.current,
          [config.id]: true,
        };
        setLoading(false);
        setUpdateIntegrationDetail({});
        dispatch(
          addToast({
            error: false,
            text: t('INTEGRATION_UPDATED_SUCCESSFULLY'),
            id: nanoid(),
          }),
        );
      })
      .catch(error => {
        console.log('error', error);
        setLoading(false);
        dispatch(
          addToast({
            error: true,
            text: t('ERROR_WHILE_UPDATING_INTEGRATION'),
            id: nanoid(),
          }),
        );
      });
  };

  const renderInput = (config, dependentConfig = null) => {
    return config.conditional ? (
      <>
        {config.conditional.param_value === dependentConfig?.value && (
          <div className="flex-column row-gap-1 w-full relative py-2 px-3 mb-4">
            <label className="inter-500-text natural-900-text font-14">{config.name}</label>
            <div className="flex integration-input relative w-full">
              <input
                autoComplete="turnoff"
                className={`input w-full ${errorFields[config.id] && 'error-info'} ${
                  config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask'
                }`}
                onChange={e => updateIntegrationParams(config.id, e.target.value)}
                placeholder={config.name}
                type="text"
                value={config.value || ''}
              />
              {config.param_type === 'PASSWORD' && (
                <div className="flex items-center cursor mr-16 input-show-img">
                  <img
                    alt="icon"
                    onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
                    src={showPassword[config.id] ? ShowPassword : HidePassword}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </>
    ) : config.param_type === 'LIST' ? (
      <div className="flex-column row-gap-1 w-full relative py-2 px-3 mb-4">
        <label className="inter-500-text natural-900-text font-14">{config.name}</label>
        <div className="flex-column">
          <DropDown
            className={`integration-input-dropdown ${!errorFields[config.id] ? 'error-info' : ''}`}
            setSelected={option => updateIntegrationParams(config.id, option.id, option.name)}
            options={config.choices}
            placeholder={config.name}
            selected={config.choices?.find(type => type.id === config.value)}
            withIcon={false}
          />
        </div>
      </div>
    ) : (
      <div className="flex-column row-gap-1 w-full relative py-2 px-3 mb-4">
        <label className="inter-500-text natural-900-text font-14">{config.name}</label>
        <div className="flex relative integration-input">
          <input
            autoComplete="turnoff"
            className={`input w-full ${errorFields[config.id] && 'error-info'} ${
              config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask'
            }`}
            onChange={e => updateIntegrationParams(config.id, e.target.value)}
            placeholder={config.name}
            type="text"
            value={config.value || ''}
          />
          {config.param_type === 'PASSWORD' && (
            <div className="flex items-center cursor mr-16 input-show-img">
              <img
                alt="icon"
                onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
                src={showPassword[config.id] ? ShowPassword : HidePassword}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderConditionalConfigs = (config, dependentConfig = null, parentConfig = null) => {
    const isConfigOpened =
      updateIntegrationDetail?.config?.id === config.id ||
      (!dependentConfig && !configClosedBefore.current?.[config.id]);
    const isDependentConfigOpened =
      updateIntegrationDetail?.config?.id === dependentConfig?.id || !configClosedBefore.current?.[dependentConfig?.id];

    return (
      <Fragment key={config.id}>
        {(!config.conditional || (config.conditional && config.conditional.param_value === dependentConfig?.value)) &&
          (!config.required_params?.length || dependentConfig?.value) && (
            <div
              className={classNames(
                'flex-column action-container relative mb-2 w-full',
                !dependentConfig && 'hover-edit hover-edit-right mx-1',
                isConfigOpened && 'update-detail-container',
              )}>
              <div className="flex-column w-full">
                {isConfigOpened || (dependentConfig && isDependentConfigOpened) ? (
                  renderInput(config, dependentConfig)
                ) : (
                  <div
                    className="flex-column pt-2 px-3 pb-4 cursor relative"
                    onClick={() =>
                      !loading &&
                      onEditConfig(config.conditional || config.required_params?.length ? parentConfig : config)
                    }>
                    <label className="inter-400-text natural-400-text font-12 mb-1">{config.name}</label>
                    <label
                      className={`regular-text font-16 flex-1 ${config.param_type === 'PASSWORD' && 'password-mask'}`}>
                      {getConfigValueToDisplay(config)}
                    </label>
                  </div>
                )}
              </div>
              {config.depends?.map(c => renderConditionalConfigs(c, config, dependentConfig ? parentConfig : config))}
              {!dependentConfig && !isConfigOpened && (
                <EditIcon width={16} height={16} className="edit-icon" color="#2496FF" />
              )}
              {isConfigOpened && (
                <div className="flex justify-end w-full">
                  <UpdateAction
                    size="medium"
                    width="100px"
                    btnClassname="specified-width"
                    onCancel={() => onCancelUpdate(config)}
                    onUpdate={() => onUpdate(config)}
                    className="update-action justify-end"
                  />
                </div>
              )}
            </div>
          )}
      </Fragment>
    );
  };

  const onOauth2Connect = () => {
    setOauth2Loading(true);
    dispatch(connectOauth2({ integration_id: integration_id }))
      .then(data => {
        window.location.href = data?.authorization_url;
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: t('ERROR_WHILE_CONNECTING'), id: nanoid() }));
      })
      .finally(() => setOauth2Loading(false));
  };

  const onOauth2Disconnect = () => {
    setOauth2Loading(true);
    dispatch(disconnectOauth2({ integration_id: integration_id }))
      .then(data => {
        dispatch(
          addToast({
            error: false,
            text: t('DISCONNECTED_SUCCESSFULLY'),
            id: nanoid(),
          }),
        );
        dispatch(setIntegrationDetails(data));
      })
      .catch(error => {
        const errorText = getErrorDescription(error, t('ERROR_WHILE_CONNECTING'));
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      })
      .finally(() => setOauth2Loading(false));
  };

  return (
    <IntegrationsDetailRightWrapper className="flex-column flex-1 border py-6 px-2">
      <div className="flex items-center justify-between border-bottom pb-3 mx-3">
        <span className="regular-text font-16 inter-500-text">{t('SETTINGS')}</span>
        {oauth2 === 'DISCONNECTED' && (
          <Button
            width="100px"
            size="medium"
            className="primary specified-width"
            label="Connect"
            loading={oauth2Loading}
            onClick={onOauth2Connect}
          />
        )}
        {oauth2 === 'CONNECTED' && (
          <Button
            width="100px"
            size="medium"
            className="negative specified-width"
            label="Disconnect"
            loading={oauth2Loading}
            onClick={onOauth2Disconnect}
          />
        )}
      </div>
      {oauth2 === 'DISCONNECTED' ? (
        <div className="flex justify-center items-center flex-1">
          <label className="inter-500-text natural-500-text font-16">Connect to view settings</label>
        </div>
      ) : (
        <div className="flex-column py-4 flex-1 overflow-scroll">
          {configDetails.map(config => renderConditionalConfigs(config))}
        </div>
      )}
      <BlockerPrompt when={isOauth2Connected && !allValuesAvailable} />
    </IntegrationsDetailRightWrapper>
  );
};

export default integrationDetailsRight;
