import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import useDebounce from '../../helpers/useDebounceHook';
import PageHeader from '../page-header';
import CustomersList from './contacts-list';

const Contacts = () => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  return (
    <Routes>
      <Route
        index
        element={
          <div className="flex-column flex-1 max-h-[100vh] overflow-hidden">
            <PageHeader
              headerTitle={t('CUSTOMERS')}
              showAddNewBtn={false}
              // showFilter
              showSearch
              // showSort
              onSortClick={() => {}}
              searchText={searchText}
              onSearchChange={setSearchText}
            />
            <CustomersList debouncedSearch={debouncedSearch} />
          </div>
        }
      />
    </Routes>
  );
};

export default Contacts;
