import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import { useError } from '../../hooks/useError';
import { useInvoices } from '../../hooks/useInvoices';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import InvoiceListItem from './invoice-list-item';

const invoicesTableHeaders = [
  { name: 'NUMBER', key: 'NUMBER' },
  { name: 'ACCOUNT CONTACT NAME', key: 'ACCOUNT_CONTACT_NAME' },
  { name: 'CREATED DATE', key: 'CREATED_DATE' },
  { name: 'ISSUED DATE', key: 'ISSUED_DATE' },
  { name: 'DUE DATE', key: 'DUE_DATE' },
  { name: 'PAYMENT DATE', key: 'PAYMENT_DATE' },
  { name: 'PAYMENT COUNT', key: 'PAYMENT_COUNT' },
  { name: 'STATUS', key: 'STATUS' },
  { name: 'AMOUNT', key: 'AMOUNT' },
  { name: '', key: 'MENU' },
];

const InvoiceList = ({ debouncedSearch, orderBy, sortBy }) => {
  const { t } = useTranslation();

  const {
    data: invoicesList,
    isLoading,
    hasNextPage,
    fetchNextPage,
    error,
  } = useInvoices({ params: { search: debouncedSearch, orderBy, sortBy } });

  useError({ error, default_message: t('ERROR_WHILE_FETCHING_INVOICES') });

  const isDataEmpty = !invoicesList || (invoicesList && invoicesList.length === 0);

  return (
    <InvoiceListWrapper className="flex-column flex-1  radius-1 mt-6 mx-6 overflow-hidden">
      <SkeletonTransition
        loading={isLoading}
        loaderClassName="item flex items-center justify-center flex-1 mb-6"
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        {isDataEmpty && (
          <div className="flex flex-col flex-1 mb-6 items-center justify-center">
            <NoData
              title={debouncedSearch ? t('NO_RESULTS_FOUND') : t('NO_INVOICES')}
              description={debouncedSearch ? t('NO_RESULTS_DESCRIPTION') : t('NO_INVOICES_HERE')}
              className="search-terms"
              EmptyIcon={NoDocumentIcon}
              iconClassName="mb-6 relative"
            />
          </div>
        )}
        {!isDataEmpty && (
          <div className="w-full card radius-1_5 border overflow-scroll">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader headers={invoicesTableHeaders} />
              <InfiniteScrollV2
                hasMore={hasNextPage}
                fetchMoreData={fetchNextPage}
                infiniteScrollClassName="custom-scrollbar thin-scrollbar">
                {(invoicesList || []).map((invoice, index) => (
                  <InvoiceListItem invoice={invoice} key={invoice.id} index={index} />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        )}
      </SkeletonTransition>
    </InvoiceListWrapper>
  );
};

const InvoiceListWrapper = styled.div`
  border-radius: 6px;
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns:
      minmax(120px, 0.5fr) minmax(200px, 1fr) minmax(120px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr)
      minmax(120px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr) 30px;
    column-gap: 24px;
    padding: 0 24px;
  }

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .no-result-container,
  .no-data-container {
    width: 300px;
  }

  .search-terms {
    max-width: 343px;
  }
`;

export default InvoiceList;
