import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';
import { CSSTransition } from 'react-transition-group';
import uuid from 'react-uuid';
import { ReactComponent as CrossIcon } from '../assets/icons/toast-close.svg';
import Button from '../components/common/button/button';
import PhoneInput from '../components/common/phone-input';
import SearchableDropdown from '../components/common/searchable-dropdown';
import Dropdown from '../components/common/select-dropdown';
import Switch from '../components/common/switch';
import { OrganisationContext } from '../context/organisationContext';
import { emailRegEx, initModal } from '../helpers/utils';
import { getTeamsList } from '../store/features/teamsManagmentSlice';
import { addToast } from '../store/features/toastSlice';
import { addUsers, addUsersToTeam, getUserRoles } from '../store/features/userManagementSlice';
import { InviteUserWrapper } from './modal.styled';

const InviteUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setModal, modal } = useContext(OrganisationContext);
  const { onSuccess } = modal?.content || {};

  const { userRoles } = useSelector(state => state.usersManagement);
  const userRoleOptions = userRoles?.map(role => ({ ...role, label: role.name, value: role.id }));

  const [user, setUser] = useState({ phoneCountry: '+44' });
  const [error, setError] = useState({ name: false, email: false, role: false });
  const [loading, setLoading] = useState(false);
  const [search, setSearchText] = useState('');
  const [sendInvite, setSendInvite] = useState(false);

  useEffect(() => {
    dispatch(getUserRoles());
  }, []);

  const checkUserErrors = () => {
    if (!user?.name?.trim() || !user?.email?.trim() || !user?.role?.id) {
      setError({
        name: !user?.name?.trim(),
        email: !user?.email?.id,
        role: !user?.role?.id,
      });
      let errorMessage =
        !user?.name?.trim() && user?.email?.trim() && user?.role?.id
          ? t('FILL_USER_NAME')
          : user?.name?.trim() && !user?.email?.trim() && user?.role?.id
          ? t('FILL_EMAIL')
          : user?.name?.trim() && user?.email?.trim() && !user?.role?.id
          ? t('FILL_ROLE')
          : !user?.name?.trim() && !user?.email?.trim() && user?.role?.id
          ? t('FILL_USER_NAME_EMAIL')
          : !user?.name?.trim() && user?.email?.trim() && !user?.role?.id
          ? t('FILL_USER_NAME_ROLE')
          : user?.name?.trim() && !user?.email?.trim() && !user?.role?.id
          ? t('FILL_EMAIL_ROLE')
          : t('FILL_USER_NAME_EMAIL_ROLE');
      dispatch(addToast({ error: true, text: errorMessage }));
      return true;
    } else if (!(user?.name && user?.name?.length > 2 && user?.name?.split(' ').length > 1)) {
      setError({ ...error, name: true });
      dispatch(addToast({ error: true, text: t('ENTER_USER_FULL_NAME') }));
      return true;
    } else if (!emailRegEx.test(user.email)) {
      setError({ ...error, email: true });
      dispatch(addToast({ error: true, text: t('ENTER_VALID_EMAIL') }));
      return true;
    }
    return false;
  };

  const onAddUsersToTeam = async (teamIds, userId) => {
    await Promise.all(
      teamIds.map(team =>
        dispatch(
          addUsersToTeam({
            team_id: team.id,
            request: [
              {
                user: {
                  id: userId,
                },
                level: 'MEMBER',
              },
            ],
          }),
        ),
      ),
    ).catch(error => {
      dispatch(
        addToast({
          error: true,
          text: error?.response?.data?.error_description || t('ERROR_WHILE_ADDING_USER_TO_TEAM'),
          id: nanoid(),
        }),
      );
    });
  };

  const onDone = async () => {
    if (loading) {
      return;
    }
    if (checkUserErrors()) {
      return;
    }
    setLoading(true);
    const teamIds = user?.team && user?.team.length ? user?.team.map(team => ({ id: team.id })) : [];
    const request = {
      forename: user.name.split(' ')[0],
      surname: user.name.split(' ')[1],
      role: { id: user.role.id },
      email: user.email,
      title: '',
      timezone: '',
      language: '',
      is_owner: false,
      phone: `${user?.phone?.replaceAll('-', '')?.replaceAll(' ', '') || ''}`,
      country_code: user?.phone ? user?.phoneCountry || '+44' : '',
    };

    dispatch(addUsers({ user: { ...request }, isInviteUserAPI: sendInvite }))
      .then(async data => {
        if (teamIds.length) {
          await onAddUsersToTeam(teamIds, data?.data?.id);
        }
        onSuccess && onSuccess();
        dispatch(
          addToast({
            error: false,
            text: t('SUCCESSFULLY_USER_INVITED'),
            id: nanoid(),
          }),
        );
        setModal(initModal);
      })
      .catch(error => {
        dispatch(
          addToast({
            error: true,
            text: error?.response?.data?.error_description,
            id: uuid(),
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  const ValueContainer = ({ children, ...props }) => {
    const { selectProps, isMulti } = props;
    const selectedOption = children && children[0] && children[0].props && children[0].props.data;
    return (
      <components.ValueContainer {...props}>
        {isMulti && selectProps?.value?.length > 0 ? (
          <div className="flex items-center w-full">
            <span className="flex items-center inter-400-text natural-900-text option-text one-line">
              {selectProps?.value?.map(o => o.label).join(', ')}
            </span>
          </div>
        ) : (
          <div className="flex items-center w-full">
            {selectedOption && selectedOption.icon && (
              <div className={classNames(selectedOption?.code ? '' : 'mr-2')}>{selectedOption.icon}</div>
            )}
            <span className="flex items-center inter-400-text natural-900-text option-text one-line">
              {selectedOption?.code ? `+${selectedOption?.code}` : children}
            </span>
          </div>
        )}
      </components.ValueContainer>
    );
  };

  const fetchChildOpt = async (
    search,
    _prevOptions,
    { page, merge, fetchFunction, hasMore, pageable, params = {}, payload = {} },
  ) => {
    if (!fetchFunction) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
    try {
      const optionData = await dispatch(
        fetchFunction({
          forFetchOnly: true,
          params: {
            page: page,
            search: search,
            ...params,
          },
          ...payload,
        }),
      );
      let optionContent = [];
      let last = true;
      if (pageable) {
        const { content, ...restResponse } = optionData || {};
        optionContent = optionData ? content : [];
        last = restResponse.last;
      } else {
        optionContent = optionData || [];
      }
      const changedOptions = optionContent.map(option => ({ ...option, label: option.name, value: option.id }));

      return {
        options: changedOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: !last,
          pageable,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: hasMore,
        additional: {
          page: page,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <InviteUserWrapper className="flex-column items-center px-12 py-9">
        <div className="w-full flex justify-between items-center">
          <label className="font-20 inter-600-text text-black text-start">{t('INVITE_NEW_USER')}</label>
          <CrossIcon className="cursor" onClick={() => setModal(initModal)} />
        </div>
        <div className="w-full gap-5px flex-column mt-8 mb-2">
          <label className="inter-500-text font-14 color-neutral-900">{t('FULL_NAME')}</label>
          <input
            autoComplete="turnoff"
            className={`input ${error.name && 'error-border'}`}
            onChange={e => {
              setUser({ ...user, name: e.target.value });
              setError({ ...error, name: false });
            }}
            placeholder={t('TYPE_USER_FULL_NAME')}
            value={user?.name}
          />
        </div>
        <div className="w-full gap-5px flex-column mb-2 mt-4">
          <label className="inter-500-text font-14 color-neutral-900">{t('EMAIL')}</label>
          <input
            autoComplete="turnoff"
            className={`input ${error.email && !emailRegEx.test(user.email) && 'error-border'}`}
            onChange={e => {
              setUser({ ...user, email: e.target.value });
              setError({ ...error, email: false });
            }}
            placeholder={t('ENTER_USER_EMAIL')}
            value={user?.email}
          />
        </div>
        <div className="w-full gap-5px flex-column mb-2 mt-4">
          <label className="inter-500-text font-14 natural-900-text">
            {t('TEAM')}
            <span className="inter-400-text font-14 natural-400-text ml-1">{t('OPTIONAL')}</span>
          </label>
          <SearchableDropdown
            isSearchable={true}
            isMulti={true}
            isClearable
            inputValue={search}
            onInputChange={setSearchText}
            loadOptions={fetchChildOpt}
            className="w-full service-type"
            placeholder={t('SELECT_TEAMS_FOR_THE_USER')}
            value={user?.team}
            onChange={option => {
              setUser({ ...user, team: option });
              setError({ ...error, team: false });
            }}
            defaultAdditional={{ page: 0, fetchFunction: getTeamsList, pageable: true }}
            error={error?.team && !user?.team?.length}
            customStyle={{ control: { width: '100%' } }}
            customComponent={{ ValueContainer }}
          />
        </div>
        <div className="w-full gap-5px flex-column mb-2 mt-4">
          <label className="inter-500-text font-14 color-neutral-900">{t('ROLE')}</label>
          <Dropdown
            options={userRoleOptions}
            placeholder={t('SELECT_USER_ROLE')}
            value={user?.role}
            onChange={option => {
              setUser({ ...user, role: option });
              setError({ ...error, role: false });
            }}
            error={error?.role}
          />
        </div>
        <div className="w-full gap-5px flex-column mb-2 mt-4">
          <label className="inter-500-text font-14 natural-900-text">
            {t('PHONE_NUMBER')}
            <span className="inter-400-text font-14 natural-400-text ml-1">{t('OPTIONAL')}</span>
          </label>
          <PhoneInput
            selectedCountry={user.phoneCountry}
            setSelectedCountry={country => setUser({ ...user, phoneCountry: country, phone: '' })}
            phone={user.phone}
            setPhone={phone => setUser({ ...user, phone: phone })}
            error={error?.phone}
          />
        </div>
        <div className="w-full flex items-center justify-between mb-2 mt-4">
          <label className="inter-500-text font-14 natural-900-text">Send Invite</label>
          <Switch enabled={sendInvite} onClick={() => setSendInvite(prev => !prev)} />
        </div>
        <div className="flex col-gap-6 justify-center mt-10 w-full">
          <Button
            className={`default flex-1 ${loading && 'disabled'}`}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={`primary flex-1`}
            label={t('ADD_USER')}
            onClick={onDone}
            primary={true}
            size="large"
            borderRadius="100px"
            loading={loading}
          />
        </div>
      </InviteUserWrapper>
    </CSSTransition>
  );
};

export default InviteUser;
