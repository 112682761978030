import api from '../store/services/index';

const fetchAPI = async ({ url, method, setDataFunc, ...rest }) => {
  try {
    const { data } = await api({ url, method, ...rest });
    setDataFunc && setDataFunc(data);
    return data;
  } catch (error) {
    setDataFunc && setDataFunc(null);
    return Promise.reject(error);
  }
};

export const getSites = async ({ ...rest }) => {
  return fetchAPI({ url: '/sites', method: 'GET', ...rest });
};

export const getSiteDetails = async ({ site_id, ...rest }) => {
  return fetchAPI({ url: `/sites/${site_id}`, method: 'GET', ...rest });
};

export const getEnergyReadingDataByType = async ({ reading_type, ...rest }) => {
  return fetchAPI({ url: `/energy_readings/${reading_type}`, method: 'GET', ...rest });
};
