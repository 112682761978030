import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { ReactComponent as ArrowRoundLightningIcon } from '../../assets/icons/arrow-round-lightning.svg';
import { ReactComponent as HorizontalDotsIcon } from '../../assets/icons/HorizontalDots.svg';
import { ReactComponent as PanelArrowDownIcon } from '../../assets/icons/panel-with-arrow-down.svg';
import { ReactComponent as PanelArrowUpIcon } from '../../assets/icons/panel-with-arrow-up.svg';
import { ReactComponent as PanelSunIcon } from '../../assets/icons/panel-with-sun.svg';
import { getFormattedNumberStyle } from '../../helpers/utils';
import { getContactName } from '../../lib/utils';
import IconContainer from '../common/icon-container';
import MenuV2 from '../common/menu/menu-v2';
import CustomTooltip from '../common/tooltip-new';

const SitesListItem = ({ site = {}, index = 0 }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id: siteId, property, primary_account, system_size, system_stats } = site;

  const { name, formatted_address } = property || {};
  const { contact } = primary_account || {};
  const { total_generated, total_consumed, total_exported } = system_stats || {};

  const contactName = getContactName(contact);

  const [isAnimating, setIsAnimating] = useState(true);

  const onSiteClick = () => {
    navigate(`${siteId}`);
  };

  const addressTooltipContent = () => {
    return (
      <div style={{ maxWidth: '300px' }}>
        <label className="inter-500-text font-12">{formatted_address}</label>
      </div>
    );
  };

  return (
    <SiteListItemWrapper
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={siteId}
      className={classNames('data-container py-1 cursor min-h-fit', index > 0 && 'border-top')}
      onClick={onSiteClick}
      onAnimationComplete={() => setIsAnimating(false)}
      onAnimationStart={() => setIsAnimating(true)}>
      <div className="flex items-center overflow-hidden py-4">
        <CustomTooltip
          wrapperClassName="flex-column row-gap-2 justify-center overflow-hidden"
          id={`site-address-${siteId}`}
          place="right"
          tooltipClassname="address-tooltip"
          hidden={!formatted_address || isAnimating}
          content={addressTooltipContent()}>
          {name && <label className="inter-500-text natural-700-text">{name}</label>}
          <label
            className={classNames(
              name ? 'inter-400-text natural-500-text one-line' : 'inter-500-text natural-700-text three-lines',
            )}>
            {formatted_address}
          </label>
        </CustomTooltip>
      </div>
      <div className="flex-column justify-center row-gap-2 overflow-hidden py-4">
        <label className="inter-500-text natural-700-text font-14">{contactName}</label>
      </div>
      <div className="flex items-center col-gap-2 overflow-hidden py-4">
        <IconContainer
          Icon={PanelSunIcon}
          iconWidth={24}
          iconHeight={24}
          backgroundColor="transparent"
          iconColor="blue_500"
        />
        <label className="inter-500-text natural-700-text font-16">
          {getFormattedNumberStyle(system_size, 'decimal', 0, 0)} kW
        </label>
      </div>
      <div className="flex items-center col-gap-2 overflow-hidden py-4">
        <IconContainer
          Icon={ArrowRoundLightningIcon}
          iconWidth={24}
          iconHeight={24}
          backgroundColor="transparent"
          iconColor="orange_500"
        />
        <label className="inter-500-text natural-700-text font-16">
          {getFormattedNumberStyle(total_generated, 'decimal', 0, 0)} kWh
        </label>
      </div>
      <div className="flex items-center col-gap-2 overflow-hidden py-4">
        <IconContainer
          Icon={PanelArrowDownIcon}
          iconWidth={24}
          iconHeight={24}
          backgroundColor="transparent"
          iconColor="success_500"
        />
        <label className="inter-500-text natural-700-text font-16">
          {getFormattedNumberStyle(total_consumed, 'decimal', 0, 0)} kWh
        </label>
      </div>
      <div className="flex items-center col-gap-2 overflow-hidden py-4">
        <IconContainer
          Icon={PanelArrowUpIcon}
          iconWidth={24}
          iconHeight={24}
          backgroundColor="transparent"
          iconColor="pink_500"
        />
        <label className="inter-500-text natural-700-text font-16">
          {getFormattedNumberStyle(total_exported, 'decimal', 0, 0)} kWh
        </label>
      </div>

      <MenuV2
        menuList={[
          {
            name: t('DETAILS'),
            onClick: onSiteClick,
          },
        ]}
        Icon={HorizontalDotsIcon}
        menuWrapperClassname="flex items-center justify-center"
        menuClassName="property-menu"
        iconClassname="my-4"
      />
    </SiteListItemWrapper>
  );
};

const SiteListItemWrapper = styled(motion.div)`
  min-height: 84px;

  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }

  .address-tooltip {
    padding: 8px 12px;
  }
`;

export default SitesListItem;
