import classNames from 'classnames';
import { motion } from 'framer-motion';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// import { ReactComponent as ConfigSettingIcon } from '../../assets/icons/config-setting.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ShieldIcon } from '../../assets/icons/shield.svg';
import IconContainer from '../../components/common/icon-container';
import { OrganisationContext } from '../../context/organisationContext';
import { formatText, uppercase } from '../../helpers/utils';

const SecuritySettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const securityOptions = [
    {
      id: nanoid(),
      title: t('USERS'),
      subtitle: t('MANAGE_CURRENT_AND_INVITE_USERS'),
      path: '/settings/security/users',
      permissions: ['USERS_MANAGE', 'USERS_VIEW'],
    },
  ];

  return (
    <SecuritySettingsWrapper
      key={'security-settings'}
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      className="flex-column row-gap-8">
      <div className="border-bottom pb-3">
        <label className="inter-600-text natural-900-text font-20">{t('SECURITY')}</label>
      </div>
      <div className="flex-1 flex-column row-gap-4">
        <label className="inter-600-text font-12 line-height-20 natural-500-text letter-spacing-1">
          {uppercase(t('USER_MANAGEMENT'))}
        </label>
        {securityOptions.map(({ id, path, title, subtitle }) => (
          <div
            key={id}
            className="flex justify-between px-5 py-4 cursor border radius-1_5 options"
            onClick={() => path && navigate(path)}>
            <div className="flex-column">
              <label className="inter-500-text natural-900-text font-16 mb-1">{title}</label>
              <label className="inter-400-text natural-400-text">{subtitle}</label>
            </div>
            <IconContainer Icon={ArrowRightIcon} backgroundColor="transparent" />
          </div>
        ))}
      </div>
    </SecuritySettingsWrapper>
  );
};

const PlatformConfiguration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkMultiplePermission, hasPermission } = useContext(OrganisationContext);

  const platformConfigurationOptions = [
    hasPermission('QUOTE_TEMPLATES_VIEW') && {
      managetitle: t('QUOTE_MANAGEMENT'),
      optionConfiguration: [
        {
          id: nanoid(),
          title: t('QUOTE_TEMPLATES'),
          subtitle: t('MANAGE_EXISTING_QUOTE_TEMPLATES_INVITE'),
          path: '/settings/platform-configuration/quote-templates',
          permissions: ['QUOTE_TEMPLATES_VIEW'],
        },
      ],
    },
    hasPermission('PROJECTS_VIEW') && {
      managetitle: t('PROJECTS_MANAGEMENT'),
      optionConfiguration: [
        {
          id: nanoid(),
          title: t('PROJECTS'),
          subtitle: t('MANAGE_EXISTING_PROJECTS_INVITE'),
          path: '/settings/platform-configuration/project',
          permissions: ['PROJECTS_VIEW'],
        },
      ],
    },
    hasPermission('PRODUCTS_VIEW') && {
      managetitle: t('PRODUCTS_MANAGEMENT'),
      optionConfiguration: [
        {
          id: nanoid(),
          title: t('PRODUCTS'),
          subtitle: t('MANAGE_EXISTING_PRODUCTS_INVITE'),
          path: '/settings/platform-configuration/products',
          permissions: ['PROJECTS_VIEW'],
        },
      ],
    },
  ].filter(Boolean);

  return (
    <PlatformConfigurationWrapper
      key={'platform-configuration-settings'}
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      className="flex-column row-gap-8">
      <div className="border-bottom pb-3">
        <label className="inter-600-text natural-900-text font-20">{t('PLATFORM_CONFIGURATION')}</label>
      </div>
      <div className="flex-1 flex-column row-gap-52px mb-6">
        {platformConfigurationOptions?.map(({ optionConfiguration, managetitle }) => (
          <div className="flex-column row-gap-3" key={optionConfiguration.id}>
            <label className="inter-600-text font-12 line-height-20 natural-500-text letter-spacing-1">
              {uppercase(formatText(managetitle))}
            </label>
            {optionConfiguration?.map(({ id, path, title, subtitle, permissions }) => (
              <div
                key={id}
                className="flex justify-between px-5 py-4 cursor border radius-1_5 options"
                onClick={() => checkMultiplePermission(() => path && navigate(path), permissions)}>
                <div className="flex-column">
                  <label className="inter-500-text natural-900-text font-16 mb-1">{title}</label>
                  <label className="inter-400-text natural-400-text">{subtitle}</label>
                </div>
                <IconContainer Icon={ArrowRightIcon} backgroundColor="transparent" />
              </div>
            ))}
          </div>
        ))}
      </div>
    </PlatformConfigurationWrapper>
  );
};

const Settings = () => {
  const { t } = useTranslation();

  const [selectedSetting, setSelectedSetting] = useState('SECURITY');
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      setSelectedSetting(state);
    }
  }, [state]);

  const settingOptions = [
    {
      id: nanoid(),
      title: t('SECURITY'),
      subtitle: 'Configure your security settings',
      key: 'SECURITY',
      icon: ShieldIcon,
    },
  ];

  return (
    <SettingsWrapper className="flex items-start justify-start w-full">
      <div className="left-panel border-right h-full">
        <div className="pxy-6 border-bottom">
          <label className="inter-700-text natural-900-text font-24">{t('SETTINGS')}</label>
        </div>
        {settingOptions.map(({ id, title, subtitle, key, icon: Icon }) => (
          <div
            key={id}
            className={classNames(
              'flex col-gap-4 px-6 py-5 border-bottom cursor',
              selectedSetting === key && 'selected-setting',
            )}
            onClick={() => setSelectedSetting(key)}>
            <IconContainer
              iconContainerClassname={classNames('radius-6 border pxy-2', selectedSetting === key && 'bg-natural-200')}
              iconColor="natural_700"
              backgroundColor="natural_100"
              Icon={Icon}
              iconWidth={24}
              iconHeight={24}
            />
            <div className="flex-column justify-center">
              <label className="inter-500-text natural-700-text font-16">{title}</label>
              <label className="inter-400-text natural-500-text">{subtitle}</label>
            </div>
          </div>
        ))}
      </div>
      <div className="right-panel flex-1">
        {selectedSetting === 'SECURITY' && <SecuritySettings />}
        {selectedSetting === 'PLATFORM_CONFIGURATION' && <PlatformConfiguration />}
        {/* {selectedSetting === 'PRODUCT_CONFIGURATION' && <ProductConfiguration />} */}
      </div>
    </SettingsWrapper>
  );
};

const SettingsWrapper = styled.div`
  .left-panel {
    width: 324px;
  }

  .selected-setting {
    background-color: ${({ theme }) => theme.natural_50};
  }

  .right-panel {
    padding: 80px 80px 0px 80px;
    overflow: auto;
    height: 100%;
  }
`;

const SecuritySettingsWrapper = styled(motion.div)`
  .options {
    :hover {
      background-color: ${({ theme }) => theme.natural_50};
    }
  }
`;
const ProductConfigurationWrapper = styled(motion.div)`
  .options {
    :hover {
      background-color: ${({ theme }) => theme.natural_50};
    }
  }
`;
const PlatformConfigurationWrapper = styled(motion.div)`
  .options {
    :hover {
      background-color: ${({ theme }) => theme.natural_50};
    }
  }
`;
export default Settings;
