import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { nanoid } from 'nanoid';
import api from '../services';
import { addActionToast, removeActionToast, updateProgress } from './ToastActionSlice';

export const downloadSlice = createSlice({
  name: 'download',
  initialState: {},
  reducers: {},
});

export const getFileSize = async ({ url, filename }) => {
  try {
    const response = await axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    });
    return parseInt(response.headers['content-length']) / 1024;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const downloadFile =
  ({ url, filename }) =>
  async (dispatch, getState) => {
    try {
      const id = nanoid();
      let makeToast = true;
      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: event => {
          const progress = event.progress * 100;
          if (makeToast) {
            dispatch(
              addActionToast({
                error: false,
                progress: progress,
                text: filename,
                type: 'DOWNLOAD',
                fileSize: event.total / 1024,
                id,
              }),
            );
            makeToast = false;
          } else {
            const toasts = getState().toastAction.toasts;
            const toast = toasts.find(singleToast => singleToast.id === id);
            dispatch(updateProgress({ id: toast.id, progress }));
            if (progress === 100) {
              setTimeout(() => {
                dispatch(removeActionToast(toast));
              }, 5000);
            }
          }
        },
      });

      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const downloadEvidence =
  ({ request, filename, onDownloadProgress = () => {} }) =>
  async (dispatch, getState) => {
    try {
      const id = nanoid();
      let makeToast = true;
      const { data } = await api.post(`/evidence/download`, request, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/zip',
        },
        onDownloadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          const progress = progressEvent.progress * 100;
          if (makeToast) {
            dispatch(
              addActionToast({
                error: false,
                progress: progress,
                text: `Downloading ${request.length || 0}`,
                type: 'DOWNLOAD',
                fileSize: progressEvent.total / 1024,
                id,
              }),
            );
            makeToast = false;
          } else {
            const toasts = getState().toastAction.toasts;
            const toast = toasts.find(singleToast => singleToast.id === id);
            dispatch(updateProgress({ id: toast.id, progress }));
            if (progress === 100) {
              setTimeout(() => {
                dispatch(removeActionToast(toast));
              }, 5000);
            }
          }
        },
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {} = downloadSlice.actions;
export default downloadSlice.reducer;
