import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { ReactComponent as BankCardIcon } from '../../../../assets/icons/bank-card-icon.svg';
import { ReactComponent as BankIcon } from '../../../../assets/icons/bank-icon.svg';
import { ReactComponent as DisabledIcon } from '../../../../assets/icons/disabled_icon.svg';
import { capitalize, formatText, getFormattedDateV2, getFormattedNumber } from '../../../../helpers/utils';
import { useTransactions } from '../../../../hooks/useAccounts';
import { cn } from '../../../../lib/utils';
import IconContainer from '../../../common/icon-container';
import InfiniteScrollV2 from '../../../common/infinite-scroll-v2';
import Label from '../../../common/label';
import Status from '../../../common/status';
import TableHeader from '../../../common/table-header';
import CustomTooltip from '../../../common/tooltip-new';

const invoiceHeaders = [
  { name: 'NUMBER', key: 'NUMBER' },
  { name: 'CREATED DATE', key: 'CREATED_DATE' },
  { name: 'TYPE', key: 'TYPE' },
  { name: 'PAYMENT METHOD', key: 'PAYMENT_METHOD' },
  { name: 'AMOUNT', key: 'AMOUNT' },
  { name: 'STATUS', key: 'STATUS' },
];

const TransactionsContent = ({ transaction, showBorderBottom = false }) => {
  const { t } = useTranslation();
  const {
    id: transaction_id,
    transaction_number,
    created_on,
    status,
    amount,
    transaction_type,
    payment_method,
  } = transaction || {};
  const { type, last_4_digits } = payment_method || {};

  return (
    <div
      className={cn(
        'px-4 min-h-14 grid grid-cols-account-transaction-grid gap-x-4 cursor hover:bg-neutral-50',
        showBorderBottom && 'border-bottom',
      )}>
      <Label className="flex items-center inter-400-text natural-900-text one-line">{transaction_number}</Label>
      <Label className="flex items-center inter-400-text natural-900-text">
        {created_on ? getFormattedDateV2({ dateInUnix: created_on }) : '-'}
      </Label>
      <Label className="flex items-center inter-400-text">{capitalize(formatText(transaction_type))}</Label>
      <div className="flex items-center inter-400-text natural-900-text">
        <IconContainer
          iconContainerClassname="mr-2"
          Icon={type === 'CARD' ? BankCardIcon : BankIcon}
          iconColor="natural_900"
          backgroundColor="transparent"
          iconWidth={20}
          iconHeight={20}
        />
        {type === 'CARD' ? (
          <Label className="flex items-center inter-400-text natural-900-text">ending in {last_4_digits}</Label>
        ) : (
          <Label className="flex items-center inter-400-text natural-900-text">{t('BANK_TRANSFER')}</Label>
        )}
      </div>
      <Label className="flex items-center inter-400-text natural-900-text">{getFormattedNumber(amount)}</Label>
      <Label className="flex items-center inter-400-text natural-900-text">
        <Status status={status} />
        {status === 'FAILURE' && (
          <CustomTooltip
            tooltipClassname="param-tooltip"
            content={<span className="inter-400-text font-12">Some text about failure reason</span>}
            id={`tooltip-${transaction_id}`}>
            <IconContainer
              iconContainerClassname="mr-2"
              Icon={DisabledIcon}
              iconColor="natural_900"
              backgroundColor="transparent"
              iconWidth={14}
              iconHeight={14}
            />
          </CustomTooltip>
        )}
      </Label>
    </div>
  );
};

const AccountTransactions = () => {
  const { account_id } = useParams();
  const { t } = useTranslation();

  const { data, isLoading, hasNextPage, fetchNextPage } = useTransactions({
    params: { account_id, page: 0 },
  });
  const isDataEmpty = !data || (data && data.length === 0);

  return isLoading ? (
    <Skeleton containerClassName="h-full tracking-[1px]" height={'100%'} />
  ) : (
    <div className="flex-col flex border rounded-xl overflow-x-auto">
      <TableHeader
        headers={invoiceHeaders}
        headerClassName="px-4 bg-natural-50 min-w-full w-fit h-[52px] grid grid-cols-account-transaction-grid gap-x-4"
      />
      {isDataEmpty && (
        <div className="flex items-center justify-center h-14 inter-400-text">
          {t('NO_TYPE', { type: 'transactions' })}
        </div>
      )}
      {!isDataEmpty && (
        <InfiniteScrollV2
          infiniteScrollClassName="min-w-full w-fit"
          hasMore={hasNextPage}
          fetchMoreData={fetchNextPage}>
          {data.map((transaction, index) => (
            <TransactionsContent key={transaction.id} transaction={transaction} showBorderTop={index !== 0} />
          ))}
        </InfiniteScrollV2>
      )}
    </div>
  );
};

export default AccountTransactions;
