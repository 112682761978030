import React from 'react';
import { cn } from '../../../lib/utils';

const FlexBox = ({ children, className, ...rest }) => {
  return (
    <div className={cn('flex items-center', className)} {...rest}>
      {children}
    </div>
  );
};

export default FlexBox;
