import { useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import React, { Fragment, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MasqueradeIcon } from '../../assets/icons/masquerade.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { setItemInLocalStorage } from '../../helpers/localstorage';
import { getContactValueByType } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { useOrganizationMasquerade } from '../../hooks/useOrganizations';
import { cn } from '../../lib/utils';
import { addToast } from '../../store/features/toastSlice';
import { setUser } from '../../store/features/userSlice';
import Button from '../common/button/button';
import FlexBox from '../common/flex-box/flex';
import Label from '../common/label';
import MenuV2 from '../common/menu/menu-v2';

const LineItem = ({ value, labelClassName = '' }) => {
  return (
    <FlexBox className="overflow-hidden">
      <Label className={cn('inter-400-text', labelClassName)}>{value || '-'}</Label>
    </FlexBox>
  );
};

const AccountsListItem = ({ account = {}, showBorderTop = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { user } = useSelector(state => state.user);
  const { hasPermission } = useContext(OrganisationContext);

  const { id: accountId, name, primary_location, contact_details, owners, category } = account;
  const { formatted_address } = primary_location || {};

  const email = getContactValueByType(contact_details, 'contact_type', 'EMAIL', 'contact_value');
  const mobile = getContactValueByType(contact_details, 'contact_type', 'MOBILE', 'contact_value');
  const primaryContact = owners?.[0] || {};

  const {
    data: accountMasquarade,
    isFetching,
    refetch: refetchMasquarade,
    error,
  } = useOrganizationMasquerade({ organization_id: accountId });

  useError({ error, default_message: t('ACCOUNT_CANNOT_ACCESSED') });

  const onAccountsClick = () => {
    // TODO: Redirect to account details
  };

  const onAccessAccount = () => {
    refetchMasquarade();
  };

  useEffect(() => {
    if (accountMasquarade && !error) {
      const api_tokens = user.api_tokens;
      const organizations = user.organizations;
      const updatedUser = {
        ...user,
        original_api_tokens: { ...api_tokens },
        original_organizations: organizations,
        api_tokens: { ...accountMasquarade.api_tokens },
        isAccessAccount: true,
        organizations: accountMasquarade.organizations,
        selectedAccount: { ...account },
      };
      queryClient.invalidateQueries({ refetchType: 'none' });
      queryClient.clear();
      setItemInLocalStorage('user', updatedUser);
      dispatch(setUser(updatedUser));
      navigate('/sites', { replace: true });
      dispatch(addToast({ error: false, text: t('ACCOUNT_ACCESSED') }));
    }
  }, [accountMasquarade, error]);

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={accountId}
      className={cn(
        'px-6 py-4 min-h-[72px] grid grid-cols-accounts-list-grid gap-x-4 cursor hover:bg-neutral-50',
        showBorderTop && 'border-top',
      )}
      onClick={onAccountsClick}>
      <LineItem value={name} />
      <LineItem value={formatted_address} labelClassName="line-clamp-2" />
      <LineItem value={mobile} />
      <LineItem value={email} />
      <LineItem value={primaryContact?.name} />
      <LineItem value={category?.name} labelClassName="line-clamp-2" />
      <MenuV2
        menuList={useFilteredMenuList({ menuList: [{ name: t('DETAILS'), onClick: onAccountsClick }] })}
        menuWrapperClassname="flex items-center justify-center"
        ExtraOptions={
          <Fragment>
            {hasPermission('MASQUERADE') && (
              <div className="px-2 py-2">
                <Button
                  size="medium"
                  width="180px"
                  icon={<MasqueradeIcon className="white-text" />}
                  className="primary specified-width"
                  label={t('ACCESS_ACCOUNT')}
                  borderRadius="100px"
                  disabled={isFetching}
                  onClick={onAccessAccount}
                />
              </div>
            )}
          </Fragment>
        }
      />
    </motion.div>
  );
};

export default AccountsListItem;
