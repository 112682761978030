import React, { Fragment, useContext } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { CSSTransition } from 'react-transition-group';
import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../helpers/utils';
import AutomationAction from './automation-action';
import AutomationCancel from './automation-cancel';
import AutomationLogAction from './automation-log-action';
import AddAction from './automation/add-action';
import AddAutomation from './automation/add-automation';
import AddTrigger from './automation/add-trigger';
import DeleteAction from './automation/delete-action';
import UpdateTrigger from './automation/update-trigger';
import AddIntegration from './integration/add-integration';
import IntegrationActions from './integration/integration-actions';
import InviteUser from './invite-user';
import { ModalWrapper } from './modal.styled';
import UserActions from './user-actions';

const PopupComponents = {
  'add-integration': AddIntegration,
  'integration-action': IntegrationActions,
  'invite-user': InviteUser,
  'user-action': UserActions,
  'automation-action': AutomationAction,
  'automation-log-action': AutomationLogAction,

  'add-automation': AddAutomation,
  'automation-cancel': AutomationCancel,
  'add-trigger': AddTrigger,
  'update-trigger': UpdateTrigger,
  'add-action': AddAction,
  'delete-action': DeleteAction,
};

const Modal = () => {
  const { setModal, modal } = useContext(OrganisationContext);

  const onClick = e => {
    if (e.target === e.currentTarget) {
      setModal(initModal);
    }
  };

  const ref = useOnclickOutside(
    () => {
      setModal(initModal);
    },
    {
      ignoreClass: `ignore-click`,
    },
  );

  const setPopupComponent = () => {
    const Component = PopupComponents[modal.type];
    if (Component) {
      return <Component />;
    }
    return <Fragment />;
  };

  if (!modal.type) return null;

  return (
    <CSSTransition appear classNames="modal-transition" in timeout={100}>
      <ModalWrapper
        id="modal-wrapper-id"
        onclick={onClick}
        style={{ justifyContent: modal.content?.top ? 'start' : 'center' }}>
        <CSSTransition key="modal-content-id" appear classNames="popup-in" in timeout={200}>
          <div
            key="modal-content-id"
            ref={ref}
            className="modal-content"
            id="modal-content-id"
            style={{
              borderRadius: modal.content?.noRadius ? '0' : '16px',
              marginTop: modal.content?.top ? '80px' : '0',
            }}>
            {setPopupComponent()}
          </div>
        </CSSTransition>
      </ModalWrapper>
    </CSSTransition>
  );
};

export default Modal;
