import { keepPreviousData, useInfiniteQuery, useQueries, useQuery } from '@tanstack/react-query';
import { getEnergyReadingDataByType, getSiteDetails, getSites } from '../apis/sites';

export const useSites = ({ params, hasNextPage, ...rest }) => {
  return useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ['sites', { ...params }],
    select: data => {
      return data?.pages?.reduce((acc, page) => {
        return [...acc, ...page.content];
      }, []);
    },
    placeholderData: keepPreviousData,
    queryFn: ({ pageParam = 0 }) => getSites({ params: { ...params, page: pageParam }, ...rest }),
    getNextPageParam: lastPage => {
      const { last, number } = lastPage;
      return last ? undefined : number + 1;
    },
  });
};

export const useSiteDetails = ({ site_id, ...rest }) => {
  return useQuery({
    enabled: !!site_id,
    queryKey: ['siteDetails', { site_id }],
    queryFn: () => getSiteDetails({ site_id, ...rest }),
  });
};

export const useGraphEnergyReadingData = ({ energyTypes, ...rest }) => {
  return useQueries({
    queries: energyTypes.map(energyType => ({
      enabled: !!energyType,
      select: data => {
        return { key: energyType, data };
      },
      queryKey: ['energyReadingData', { reading_type: energyType }],
      queryFn: () => getEnergyReadingDataByType({ reading_type: energyType, ...rest }),
    })),
    combine: results => results,
  });
};
