import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DotsIcon } from '../../../assets/icons/dots.svg';
import { ReactComponent as RightIcon } from '../../../assets/icons/right-Icons.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import CustomTooltip from '../tooltip-new';

const Menu = ({
  menuList = [],
  menuClassName = '',
  Icon = DotsIcon,
  isIcon = true,
  isRightIcin = false,
  isSelectedValueId = null,
  ExtraOptions = <></>,
  getContentEditable = <></>,
  place = 'left',
  isDisabled = false,
  positionStrategy = 'fixed',
  iconClassName = '',
  setValue = () => {},
}) => {
  const menuRef = useRef(nanoid());
  const { checkPermission } = useContext(OrganisationContext);

  const [showMenu, setShowMenu] = useState(false);

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowMenu(false);
    }
  };

  const onMenuItemClick = menuItem => {
    const { onClick, permission } = menuItem;
    checkPermission(() => {
      onClick && onClick();
    }, permission);
  };

  return (
    <MenuWrapper
      className={classNames('relative', menuClassName)}
      isDisabled={isDisabled}
      onBlur={onBlur}
      tabIndex={0}
      onClick={e => {
        e.stopPropagation();
        setShowMenu(!showMenu);
      }}>
      <CustomTooltip
        id={`menu-selector-${menuRef.current}`}
        clickable
        noArrow
        isOpen={showMenu && menuList.length > 0}
        place={place}
        positionStrategy={positionStrategy}
        content={
          <Fragment>
            <div className="menu-wrapper radius-3 overflow-hidden w-auto">
              {menuList.map((menuItem, index) => (
                <Fragment key={index}>
                  <div
                    key={menuItem.name}
                    className={classNames(
                      'cursor menu-item px-4 py-3 flex items-center',
                      index !== 0 && 'border-top',
                      isRightIcin && 'justify-between',
                    )}
                    onClick={() => {
                      onMenuItemClick(menuItem);
                      setValue(menuItem);
                    }}>
                    {menuItem.withIcon && (
                      <menuItem.icon height={20} width={20} className={classNames('mr-2', menuItem.iconClassName)} />
                    )}
                    <span className="inter-400-text natural-900-text">{menuItem.name}</span>
                    {isRightIcin && isSelectedValueId === menuItem?.id && (
                      <RightIcon height={20} width={20} color="#2496FF" />
                    )}
                  </div>
                </Fragment>
              ))}
            </div>
            {ExtraOptions}
          </Fragment>
        }>
        {isIcon && (
          <div className={classNames('flex items-center justify-center cursor menu-btn', iconClassName)}>
            <Icon className={classNames('menu-icon')} />
          </div>
        )}
        {getContentEditable && getContentEditable}
      </CustomTooltip>
    </MenuWrapper>
  );
};

const MenuWrapper = styled('div')`
  position: relative;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.3' : '1')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};

  .menu-btn {
    height: 32px;
    width: 32px;
    &:hover {
      background-color: ${({ theme }) => theme.lightGreyBackground};
      border-radius: 12px;
    }
  }

  .custom-tooltip {
    padding: 0;
    box-shadow: 2px 8px 20px rgba(5, 49, 73, 0.14);
  }

  .menu-wrapper {
    width: auto;
    min-width: 160px;

    .menu-item {
      &:hover {
        background-color: ${({ theme }) => theme.lightGreyBackground};
      }
    }
  }
`;

export default Menu;
