import React from 'react';
import { useParams } from 'react-router-dom';
import { useAccountDetails, useAccountPaymentMethods, useContacts } from '../../../hooks/useAccounts';
import { useError } from '../../../hooks/useError';
import { useSiteDetails } from '../../../hooks/useSites';
import { useAccountsStore } from '../../../store/accounts-store';
import { useSitesStore } from '../../../store/sites-store';
import AdditionalContacts from './additional-contacts';
import ContactInfo from './contact-info';
import DetailsContainer from './details-container';
import PaymentMethod from './payment-method';

const AccountDetailContainer = () => {
  const { site_id, account_id } = useParams();

  const { setSiteDetails } = useSitesStore();
  const { setAccountDetails } = useAccountsStore();

  const { data: siteData, isFetching: isSiteFetching } = useSiteDetails({ site_id, setDataFunc: setSiteDetails });

  const {
    data: accountData,
    isFetching,
    error: accountDataError,
  } = useAccountDetails({ account_id, setDataFunc: setAccountDetails });

  const {
    data: contactsData,
    isFetching: isContactsFetching,
    error: contactsDataError,
  } = useContacts({ params: { account_id } });

  const {
    data: paymentMethods,
    isFetching: isPaymentMethodsFetching,
    error: paymentError,
  } = useAccountPaymentMethods({ account_id });

  useError({ error: accountDataError, default_message: 'Failed to fetch payment methods' });
  useError({ error: contactsDataError, default_message: 'Failed to fetch payment methods' });
  useError({ error: paymentError, default_message: 'Failed to fetch payment methods' });

  const { contact, service_address, billing_email, billing_address } = accountData || {};
  const { content: contacts } = contactsData || {};

  const contactsWithoutAccountHolder = contacts?.filter(c => c?.id !== contact?.id);

  return (
    <div className="flex col-gap-4 row-gap-4 flex-1 min-w-fit-content h-full">
      <div className="flex min-w-[320px] max-w-[320px] flex-col gap-y-4 flex-1 left-col overflow-auto">
        <ContactInfo
          contact={contact}
          loading={isFetching}
          service_address={service_address}
          billing_email={billing_email}
          billing_address={billing_address}
        />
        <AdditionalContacts contacts={contactsWithoutAccountHolder} loading={isContactsFetching} />
        <PaymentMethod paymentMethods={paymentMethods} loading={isPaymentMethodsFetching} />
      </div>
      <div className="flex flex-col flex-1 col-gap-4 right-col">
        <DetailsContainer data={accountData} loading={isFetching} />
      </div>
    </div>
  );
};

export default AccountDetailContainer;
