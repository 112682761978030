import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styled, { useTheme } from 'styled-components';
import Card from '../card';

const CustomTooltip = ({ active, payload, label, yAxisFormatter, xAxisFormatter, ...rest }) => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    const [firstPayload] = payload;
    const { payload: payloadData } = firstPayload;
    const payloadTotal = payload.reduce((acc, { value }) => acc + parseFloat(value || 0), 0);
    const totalData = { name: 'Total', fill: theme.natural_500, value: parseFloat(payloadTotal).toFixed(2) };

    return (
      <CustomChartTooltipWrapper className="flex flex-col gap-y-2 w-fit px-3 py-2">
        <div className="inter-600-text natural-700-text">
          {xAxisFormatter(payloadData.date, null, payloadData, true)}
        </div>
        {[...payload, totalData].map(({ fill, name, value }, index) => (
          <div key={index} className="flex items-center justify-between gap-x-4">
            <div className="flex items-center gap-x-2">
              <div className="w-2 h-2 rounded-full" style={{ backgroundColor: fill || 'red' }} />
              <div className="inter-400-text natural-700-text font-12">{name}</div>
            </div>
            <div className="inter-600-text natural-700-text font-12 ml-8">
              {yAxisFormatter(parseFloat(value || 0).toFixed(2))}
            </div>
          </div>
        ))}
      </CustomChartTooltipWrapper>
    );
  }

  return null;
};

const CustomLegend = ({ payload }) => {
  return (
    <div className="flex gap-x-2 px-4">
      {payload.map(({ color, value }, index) => (
        <div key={index} className="flex items-center gap-x-1 px-2 py-[2px] rounded-md bg-natural-50">
          <div style={{ width: '8px', height: '8px', backgroundColor: color, borderRadius: '100%' }} />
          <div className="inter-400-text natural-700-text font-12">{value}</div>
        </div>
      ))}
    </div>
  );
};

const BarChartUI = ({
  className,
  data,
  useDummy = false,
  xAxisDataKey,
  barArray = [],
  xAxisFormatter = value => value,
  yAxisFormatter = value => value,
}) => {
  const theme = useTheme();
  const axisColor = useDummy ? theme.natural_400 : theme.natural_700;

  return (
    <ResponsiveContainer className={className} width="100%" height="100%">
      <BarChart data={data} margin={{ left: -10, top: 10, right: 10 }}>
        <XAxis
          dataKey={xAxisDataKey}
          tickLine={false}
          tick={{ fontSize: 10, fill: axisColor, wordWrap: 'break-word' }}
          tickMargin={10}
          axisLine={false}
          interval={'preserveStartEnd'}
        />
        <YAxis
          tickFormatter={yAxisFormatter}
          tick={{ fontSize: 10, fill: axisColor }}
          tickLine={false}
          axisLine={false}
          width={90}
          tickMargin={10}
        />
        <CartesianGrid strokeDasharray="0" vertical={false} stroke={theme.natural_200} />
        <Tooltip
          cursor={{
            stroke: theme.natural_100,
            fill: theme.natural_100,
            strokeWidth: 1,
            strokeDasharray: '1 1',
          }}
          xAxisFormatter={xAxisFormatter}
          yAxisFormatter={yAxisFormatter}
          content={<CustomTooltip />}
        />
        <Legend content={<CustomLegend />} />
        {barArray.map((bar, index) => (
          <Bar
            key={index}
            name={bar.name}
            type={bar.type || 'monotone'}
            dataKey={bar.dataKey}
            barSize={12}
            minPointSize={value => (value !== 0 ? 2 : undefined)}
            fill={bar.fill}
            stackId={bar.stackId}
            strokeLinecap="round"
            stroke="#fff"
            strokeWidth={1}
            radius={[2, 2, 2, 2]}
            {...bar}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

const CustomChartTooltipWrapper = styled(Card)`
  box-shadow: 0px 2px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
`;

export default BarChartUI;
