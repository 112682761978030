import api from '../store/services/index';

const fetchAPI = async ({ url, method, setDataFunc, ...rest }) => {
  try {
    const { data } = await api({ url, method, ...rest });
    setDataFunc && setDataFunc(data);
    return data;
  } catch (error) {
    setDataFunc && setDataFunc(null);
    return Promise.reject(error);
  }
};

export const getOrganizationNetworks = async ({ organization_id, ...rest }) => {
  return fetchAPI({ url: `/organizations/${organization_id}/network`, method: 'GET', ...rest });
};

export const getOrganizationMasquerade = async ({ organization_id, ...rest }) => {
  return fetchAPI({ url: `/organizations/${organization_id}/masquerade`, method: 'POST', ...rest });
};
