import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { cn } from '../../../lib/utils';

const Label = ({ loading, children, hasPlaceholder = false, className, ...rest }) => {
  return (
    <label className={cn(className, hasPlaceholder && 'natural-500-text')} {...rest}>
      {loading ? <Skeleton style={{ minWidth: '120px' }} containerClassName="w-full letter-spacing-1" /> : children}
    </label>
  );
};

export default Label;
