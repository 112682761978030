import moment from 'moment';
import React, { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { useError } from '../../../../../hooks/useError';
import { useAccountEnergyTariffs } from '../../../../../hooks/useTariffs';
import TariffDetails from './tariff-details';
import TariffRates from './tariff-rates';

const defaultTariff = {
  name: 'Standard Tariff',
  description: 'Standard Tariff Description',
  type: 'FIXED',
  billing_frequency: 'MONTHLY',
  contract_length_months: 15 * 12,
  start_date: moment().unix(),
  end_date: moment().add(15, 'years').unix(),
};

const AccountTariffs = () => {
  const { account_id } = useParams();

  const { data: accountEnergyTariffs, isFetching, error } = useAccountEnergyTariffs({ account_id });
  useError({ error, default_message: 'Error while fetching tariffs' });

  const isDataEmpty = !accountEnergyTariffs || !accountEnergyTariffs.length;

  return isFetching ? (
    <Skeleton containerClassName="h-full tracking-[1px]" height={'100%'} />
  ) : (
    <div className="flex flex-col flex-1 overflow-auto">
      {isDataEmpty && <div className="flex items-center justify-center h-14 inter-400-text">No tariffs available</div>}
      {!isDataEmpty && (
        <div className="flex flex-col gap-y-6 flex-1">
          {accountEnergyTariffs.map(tariff => (
            <Fragment key={tariff.id}>
              <TariffDetails tariff={tariff} />
              <TariffRates tariff={tariff} />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default AccountTariffs;
