import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';

const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const DateSelector = ({
  isForEndDatePicker = false,
  isFromDateRange = false,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  range,
  showCurrentMonth = true,
  isOnlyWeekDays = false,
  weekday = 0,
  minStartDate = null,
  maxEndDate = null,
  className = '',
}) => {
  const [disablePastDayDates, setDisablePastDayDate] = useState(minStartDate ? moment(minStartDate * 1000) : moment());
  const [disableFuturDayDates, setDisableFuturDayDates] = useState(maxEndDate ? moment(maxEndDate * 1000) : moment());
  const [value, setValue] = useState(
    showCurrentMonth ? moment().clone() : isForEndDatePicker ? endDate.clone() : startDate.clone(),
  );
  const [calendar, setCalendar] = useState([]);
  const startDay = value.clone().startOf('month').day(1);
  const endDay = value.clone().endOf('month').day(7);

  useEffect(() => {
    setDisablePastDayDate(minStartDate ? moment(minStartDate * 1000) : moment());
  }, [minStartDate]);

  useEffect(() => {
    setDisableFuturDayDates(maxEndDate ? moment(maxEndDate * 1000) : moment());
  }, [maxEndDate]);

  useEffect(() => {
    const day = startDay.clone().subtract(1, 'day');
    const a = [];
    while (day.isBefore(endDay, 'day')) {
      a.push(
        Array(7)
          .fill(0)
          .map(() => day.add(1, 'day').clone()),
      );
    }
    setCalendar(a);
  }, [value]);

  const changeSelectedDate = day => {
    if (range) {
      setStartDate(isFromDateRange ? day.clone().endOf('day').unix() : day.clone().weekday(weekday).unix());
      const addedDays = isOnlyWeekDays ? 5 : weekday === 0 ? 6 : 7;
      setEndDate(isFromDateRange ? day.clone().endOf('day').unix() : day.clone().weekday(addedDays).unix());
    } else {
      setStartDate(day.clone().startOf('day').unix());
      setEndDate(day.clone().endOf('day').unix());
    }
  };

  return (
    <DateSelectorWrapper className={className}>
      <div className="month-selector">
        <ArrowLeft
          className="cursor"
          height={16}
          width={16}
          color="#000"
          onClick={e => {
            e.stopPropagation();
            setValue(value.subtract(1, 'month').clone());
          }}
        />
        <label className="inter-500-text font-16">{value.format('MMMM YYYY')}</label>
        <ArrowRight
          className="cursor"
          height={16}
          width={16}
          color="#000"
          onClick={e => {
            e.stopPropagation();
            setValue(value.add(1, 'month').clone());
          }}
        />
      </div>
      <div className="week-days">
        {weekDays.map(wd => (
          <div key={wd} className="week-day">
            <label className="inter-600-text natural-400-text font-12">{wd}</label>
          </div>
        ))}
      </div>
      {calendar.map((week, j) => (
        <div key={j} className="week">
          {week.map((day, i) => (
            <div
              key={i}
              className={classNames(
                'day regular-text cursor',
                !value.isSame(day, 'month') && 'fade',
                range &&
                  ((day.isAfter(startDate) && day.isBefore(endDate) && !day.isSame(endDate, 'day')) ||
                    day.isSame(startDate, 'day') ||
                    day.isSame(endDate, 'day')) &&
                  'range-day',
                minStartDate && !disablePastDayDates.isBefore(day, 'date') && 'fade',
                maxEndDate && !disableFuturDayDates.isAfter(day, 'date') && 'fade',
              )}
              onClick={() => {
                if (
                  (!minStartDate || (minStartDate && disablePastDayDates.isBefore(day, 'date'))) &&
                  (!maxEndDate || (maxEndDate && disableFuturDayDates.isAfter(day, 'date')))
                ) {
                  changeSelectedDate(day);
                }
              }}>
              <div
                className={classNames(
                  'w-full h-full flex items-center justify-center',
                  day.isSame(startDate, 'day') && !isForEndDatePicker && 'selected-day',
                  range && day.isSame(endDate, 'day') && (!isFromDateRange || isForEndDatePicker) && 'selected-day',
                )}>
                <label className="inter-400-text day-label">{day.format('D')}</label>
              </div>
            </div>
          ))}
        </div>
      ))}
    </DateSelectorWrapper>
  );
};
const DateSelectorWrapper = styled.div`
  width: 277px;
  height: 300px;
  background: #ffffff;
  border-radius: 16px;
  user-select: none;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .week-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    user-select: none;

    .week-day {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
    }
  }

  .week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    .day {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      position: relative;
      outline: 0;
    }

    .day:hover {
      color: ${({ theme }) => theme.primary_500};
      background: ${({ theme }) => theme.backgroundColor2};
      border-radius: 20px;
    }

    .fade {
      color: #a0a0a0;
      opacity: 0.3;
    }

    .selected-day {
      color: white;
      background: ${({ theme }) => theme.primary_500};
      border-radius: 20px;

      .day-label {
        color: white;
      }
    }

    .range-day {
      color: ${({ theme }) => theme.primary_500};
    }

    .campaign-day {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #ea5454;
      cursor: pointer;
    }
  }

  .month-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    user-select: none;
    img {
      cursor: pointer;
      min-width: 10px;
      min-height: 10px;
    }
  }
`;
export default DateSelector;
