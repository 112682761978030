import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import { useError } from '../../hooks/useError';
import { useSites } from '../../hooks/useSites';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import SitesListItem from './site-list-item';

const sitesTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'ACCOUNT HOLDER', key: 'ACCOUNT_HOLDER' },
  { name: 'SYSTEM SIZE', key: 'SYSTEM_SIZE' },
  { name: 'TOTAL GENERATED', key: 'TOTAL_GENERATED' },
  { name: 'TOTAL CONSUMED', key: 'TOTAL_CONSUMED' },
  { name: 'TOTAL EXPORTED', key: 'TOTAL_EXPORTED' },
  { name: '', key: 'MENU' },
];

const SitesList = ({ debouncedSearch, orderBy, sortBy }) => {
  const { t } = useTranslation();

  const {
    data: sitesList,
    isLoading,
    hasNextPage,
    fetchNextPage,
    error,
  } = useSites({ params: { search: debouncedSearch, orderBy, sortBy } });

  useError({ error, default_message: t('ERROR_WHILE_FETCHING_SITES') });

  const isDataEmpty = !sitesList || (sitesList && sitesList.length === 0);

  return (
    <SitesListWrapper className="flex-column flex-1 radius-1 mt-6 mx-6 overflow-hidden">
      <SkeletonTransition
        loading={isLoading}
        loaderClassName="item flex items-center justify-center flex-1 mb-6"
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        {isDataEmpty && (
          <div className="flex flex-col flex-1 mb-6 items-center justify-center">
            <NoData
              title={debouncedSearch ? t('NO_RESULTS_FOUND') : t('NO_SITES')}
              description={debouncedSearch ? t('NO_RESULTS_DESCRIPTION') : t('NO_SITES_HERE')}
              className="search-terms"
              EmptyIcon={NoDocumentIcon}
              iconClassName="mb-6 relative"
            />
          </div>
        )}
        {!isDataEmpty && (
          <div className="w-full card radius-1_5 border overflow-auto">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader headers={sitesTableHeaders} />
              <InfiniteScrollV2
                hasMore={hasNextPage}
                fetchMoreData={fetchNextPage}
                infiniteScrollClassName="custom-scrollbar thin-scrollbar">
                {sitesList.map((site, index) => (
                  <SitesListItem site={site} key={site.id} index={index} />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        )}
      </SkeletonTransition>
    </SitesListWrapper>
  );
};

const SitesListWrapper = styled.div`
  border-radius: 6px;
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) 160px 160px 160px 160px 140px 40px;
    column-gap: 24px;
    padding: 0 24px;
  }

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .no-result-container,
  .no-data-container {
    width: 300px;
  }

  .search-terms {
    max-width: 343px;
  }
`;

export default SitesList;
