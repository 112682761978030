import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AutomationLogDetails from '../../components/automation-log-details';
import PageHeader from '../../components/page-header';
import { getAutomationLogDetails } from '../../store/features/automationsSlice';
import { addToast } from '../../store/features/toastSlice';
import { AutomationLogDetailContainerWrapper } from '../../styles/pages/automation-log-detail.styled';

const AutomationLogDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAutomationLogDetails({ automationLogId: id })).catch(() => {
      dispatch(addToast({ error: true, text: t('ERROR_FETCHING_AUTOMATION_LOG_DETAILS') }));
    });
  }, []);
  return (
    <AutomationLogDetailContainerWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: t('PLATFORMS'), onClick: () => {} },
          { text: t('AUTOMATION_LOG'), path: '/platforms/automation-logs' },
          { text: id, onClick: () => {} },
        ]}
        wrapperClassName="pxy-6 automation-log-header"
      />
      <AutomationLogDetails />
    </AutomationLogDetailContainerWrapper>
  );
};

export default AutomationLogDetail;
