export const TRANSLATIONS_FR = {
  EMAIL_ADDRESS: 'Adresse e-mail',
  password: 'Mot de passe',
  ENTER_PASSWORD: 'Entrer le mot de passe',
  LOGIN: 'Connexion',
  INCORRECT_EMAIL_PASSWORD: 'email ou mot de passe incorrect',
  RECEIVE_CODE: 'Entrez votre email pour recevoir le code',
  ENTER_EMAIL_ADDRESS: `Entrer l'adresse e-mail`,
  WE_SENT_EMAIL: 'Nous vous avons envoyé un e-mail',
  SEND: 'Envoyer',
  SECURITY_REASON_DETAIL_TEXT: `Pour plus de sécurité, nous devons vérifier davantage votre compte. S'il vous plaît, entrez le code de sécurité de l'e-mail ici`,
  INCORRECT_CODE_DETAIL_TEXT: 'Code incorrect - veuillez vérifier que votre code est correct et réessayer',
  VERIFY: 'Vérifier',
  RESEND_SECURE_CODE: 'Renvoyer le code sécurisé',
  RESET_YOUR_PASSWORD: 'Réinitialisez votre mot de passe',
  NEW_PASSWORD: 'Nouveau mot de passe',
  ENTER_NEW_PASSWORD: 'Entrez un nouveau mot de passe',
  CONFIRM_NEW_PASSWORD: 'Confirmer le nouveau mot de passe',
  SET_NEW_PASSWORD: 'Definir un nouveau mot de passe',
  // need translation
  FORGOTTEN_PASSWORD: 'Forgotten password',
  WELCOME_BACK: 'Welcome back!',
  SIGN_IN_NEEDED_TEXT: 'You need to sign in to your account',
  ACCOUNT_DEACTIVATE_DETAIL_TEXT:
    'Your account has been de-activated, please ask the workspace owner to re-activate your account',
  FORGOTTEN_YOUR_PASSWORD: 'Forgotten your password?',
  ENTER_EMAIL_BELOW_DETAIL_TEXT: 'Enter your email below. If it exists we will send you an email with a 6 digit code',
  RESEND_EMAIL: 'Re-send email',
  SUBMIT: 'Submit',
  PASSWORD_NOT_MATCH: 'Passwords do not match',
  ADD_NEW: 'Add new',
  ADD_PROPERTY: 'Add Property',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  EDIT: 'Edit',
  DELETE: 'Delete',
  NAME: 'Name',
  DESCRIPTION: 'Description',
  SETTINGS: 'Settings',
  SECURITY: 'Security',
  CONFIGURE_YOUR_SECURITY: 'Configure your security settings',
  ADD_USERS: 'Add users',
  USERS: 'Users',
  MANAGE_CURRENT_AND_INVITE_USERS: 'Manage current and invite new users',
  TEAMS: 'Teams',
  MANAGE_AND_INVITE_NEW_TEAMS: 'Manage current and create new teams',
  ROLES: 'Roles',
  MANAGE_CURRENT_AND_INVITE_NEW_ROLE: 'Manage current and create new roles',
  USER_MANAGEMENT: 'User Management',
  MANAGE_USER_ROLES_AND_TEAMS: 'Manage users, roles and teams',
  INVITE_USER: 'Invite User',
  EMAIL: 'Email',
  TYPE_USER_PHONENUMBER: 'Type user’s phone number',
  EMPLOYEE_NUMBER: 'Employee number',
  TYPE_EMPLOYEE_NUMBER: "Type employee's number",
  YOU_WANT_TO_DELETE_TEAM: 'Are you sure you want to delete this team?',
  YOU_WANT_TO_DELETE_TEAM_MEMBER: 'Are you sure you want to remove  this user from the team?',
  ID: 'ID',
  DATE: 'Date',
  STATUS: 'Status',
  AUTOMATION_LOG: 'Automation Log',
  EVENT: 'Event',
  TEAM_NAME: 'Team Name',
  ROLE_NAME: 'Role name ',
  SERVICES: 'Services',
  AUTOMATION_DETAILS: 'Automation details',
  CONFIGURATION: 'Configuration',
  ADD_NEW_ACTION: 'Add new action',
  CANCEL: 'Cancel',
  MOBILE_PRIMARY: 'Mobile (Primary)',
  EMAIL_PRIMARY: 'Email (Primary)',
  REMOVE: 'Remove',
  DETAILS: 'Details',
  ACTIVE: 'Active',
  AVAILBILITY: 'Availability',
  ERROR_UPDATE_STATUS: 'Error while updating status',
  REQUIRED_INTIGRATION: 'Please add all required fields in integration',
  REQUIRED_PARAMETERS_INTIGRATION: 'Please add all required parameters in integration',
  REQUIRED_FIELD: 'Please enter required field',
  CANCLE_AUTOMATION: 'Cancel this automation?',
  UNDO_ACTION: 'You will not be able to undo this action.',
  ROLE_DETAILS: 'Role Details',
  ROLE_TIER: 'Role Tier',
  ROLE_PERMISSIONS: 'Role Permissions',
  SAVE: 'Save',
  TIER: 'Tier',
  TYPE_ROLE_FULL_NAME: 'Enter a name for the role',
  TYPE_ROLE_TIER: 'Enter a tier for the role',
  MOBILE: 'Mobile',
  COMPANY: 'Company',
  PERSON: 'Person',
  ENTER_USER_FULL_NAME: "Veuillez saisir le nom complet de l'utilisateur",
  PLEASE_FILL_THE_REQUIRED_FIELDS: 'Veuillez remplir les champs requis',
  CONTACT_UPDATED_SUCCESSFULLY: 'Le contact a été mis à jour avec succès',
  ERROR_UPDATING_CONTACT: 'Erreur lors de la mise à jour du contact',
  EDIT_COMPANY_CONTACT: "Modifier le contact de l'entreprise",
  TYPE: 'Taper',
  SELECT_FROM_LIST: 'Sélectionner dans la liste',
  TITLE: 'Titre',
  FULL_NAME: 'Nom et prénom',
  ENTER_FULL_NAME: 'Entrez le nom complet',
  PRIMARY: 'Primaire',
  ENTER_EMAIL: "Entrez l'e-mail",
  LANDLINE: 'Téléphone fixe',
  ADDRESS: 'Adresse',
  CLICK_TO_ADD_ADDRESS: 'Cliquez pour ajouter une adresse',
  MAKE_PRIMARY_CONTACT: 'Établir un contact principal',
  PLEASE_FILL_ALL_THE_REQUIRED_FIELDS: 'Veuillez remplir tous les champs obligatoires',
  CONTACT_ADDRESS: 'Adresse de contact',
  SAME_AS_PROPERTY_ADDRESS: "Identique à l'adresse de la propriété",
  PROPERTY_ADDRESS: 'Adresse de la propriété',
  ADDRESS_LINE_ONE: 'Adresse Ligne 1',
  EXAMPLE_WALNUT_AVENUE: 'par exemple. 4, avenue des Noyers',
  ADDRESS_LINE_TWO: 'Adresse Ligne 2',
  OPTIONAL: '(facultatif)',
  EXAMPLE_ACOMB: 'par exemple. Un peigne',
  CITY: 'Ville',
  EXAMPLE_RYDE: 'par exemple. Ryde',
  POSTCODE: 'Code postal',
  SEARCH_FOR_AN_ADDRESS: 'Commencez à taper pour rechercher une adresse...',
  COUNTY: 'Comté',
  EXAMPLE_ISLE_OF_WIGHT: "par exemple. l'île de Wight",
  ADD_NEW_JOB: 'Ajouter un nouveau travail',
  ENTER_JOB_DETAILS: 'Entrez les détails du poste ici',
  JOB_DESCRIPTION: "Description de l'emploi",
  JOB_NUMBER: 'Numéro de travail',
  PO_NUMBER: 'Numéro de bon de commande',
  LEAD_GEN_SOURCE: 'Source de génération de leads',
  ORGANISATION: 'Organisation',
  USER: 'Utilisatrice',
  START_AT: 'Commencer à',
  SEND_QUOTE_CONFIRMATION: 'Êtes-vous sûr de vouloir envoyer ce devis au client ?',
  SEND: 'Envoyer',
  NO_LEAD_GENERATOR_SELECTED: 'Aucun générateur de leads sélectionné',
  NO_PARTNER_SELECTED: 'Aucun partenaire sélectionné',
  ASSIGN_PARTNER: 'Attribuer un partenaire',
  WORKFLOW: 'Flux de travail',
  JOB_DETAILS: 'Détails du poste',
  JOB_INFO: "Informations sur l'emploi",
  TERMS: 'Termes',
  ATTACHMENTS: 'Pièces jointes',
  UPLOADING: 'Téléchargement',
  ATTACHED: 'Ci-jointe',
  DELETE_THESE_FILES: 'Supprimer ces fichiers?',
  UPLOAD_AT_ANYTIME: 'Vous pouvez télécharger des fichiers à tout moment.',
  YOU_CANNOT_ADD_PRODUCT: "Pour ajouter un produit, veuillez sélectionner l'option d'intégration en haut à gauche",
  ROOF_INFORMATION: 'Informations sur le toit',
  VIEW_ROOF: 'Voir le toita',
  ROOF_DETAILS: 'Détails du toit',
  SEGMENT: 'Segment',
  ANNUAL_SUNSHINE: 'Ensoleillement annuel',
  ACTUAL_PANELS: 'Annual Panels',
  MAX_PANELS: 'Max Panels',
  TOTAL_POWER: 'Total power',
  PITCH: 'Pas',
  ACTUAL_PANELS: 'Panneaux réels',
  AZIMUTH: 'Azimut',
  EXIT_JOB: 'Êtes-vous sûr de vouloir quitter cet emploi',
  COMPLETE_FOLLOWING: 'Do you need to complete one of the following actions before you go',
  DO_NOTHING_EXIT_JOB: 'Ne rien faire et quitter le travail',
  PROGRESS_AND_EXIT: 'Progression et sortie',
  UNASSIGN_AND_EXIT: "Annuler l'attribution du travail et quitter",
  NO_PHONE_NUMBER: 'No phone number',
  PRODUCTS: 'Des produits',
  SALES_PRICE: 'Prix ​​de vente (inclus)',
  PRODUCT_MEDIA: 'Médias produits',
  SKU: 'SKU',
  PRODUCT_TYPE: 'Type de produit',
  SOLD_AS: 'Vendu comme',
  SELECT_TAX_SCHEME: 'Sélectionnez le régime fiscal',
  BRANDS: 'Marques',
  FAMILIES: 'Des familles',
  FEATURES: 'Features',
  ADD_BRAND: 'Ajouter une marque',
  ADD_FAMILY: 'Ajouter une famille',
  ADD_FEATURE: 'Ajouter une fonctionnalité',
  SELECT_FAMILY: 'Sélectionnez une famille',
  SELECT_BRAND: 'Sélectionnez la marque',
  NEW_FEATURE: 'Nouvelle fonctionnalité',
  NAME_PLACEHOLDER: 'Entrez le nom',
  DESCRIPTION_PLACEHOLDER: 'Entrez ici la description de la marque',
  DESCRIPTION_PLACEHOLDER_BRAND: 'Entrez une description pour le tableau',
  NEW_BRAND: 'Nouvelle marque',
  NEW_FAMILY: 'Nouvelle famille',
  DEFAULT: 'Défaut',
  TAG: 'Étiqueter',
  TAG_PLACEHOLDER: 'Sélectionner une balise',
  ERROR_WHILE_FETCHING_PRODUCT: 'Erreur lors de la récupération du produit',
  ERROR_WHILE_FETCHING_TAX_SCHEMES: 'Erreur lors de la récupération des régimes fiscaux',
  ERROR_WHILE_FETCHING_PRODUCT_MEDIA: 'Erreur lors de la récupération du support du produit',
  OFFLINE_OR_POOR_WIFI:
    'Seems like you are offline or on a poor wifi connection, please connect to a stronger wifi connection and try again',
  ACCOUNT_IS_INCATIVE: 'le compte est inactif',
  UPDATE_PRODUCT_SUCCESSFULLY: 'Product updated successfully',
};
