import { MenuDivider, Menu as MenuInner, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { menuDividerSelector, menuItemSelector, menuSelector } from '@szhsin/react-menu/style-utils';
import classNames from 'classnames';
import React, { Fragment, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as DotsIcon } from '../../../assets/icons/dots.svg';
import { ReactComponent as RightIcon } from '../../../assets/icons/right-Icons.svg';
import { OrganisationContext } from '../../../context/organisationContext';

const MenuV2 = ({
  overflow = 'auto',
  align = 'center',
  menuList = [],
  menuClassName = '',
  Icon = DotsIcon,
  isIcon = true,
  isRightIcon = false,
  isSelectedValueId = null,
  ExtraOptions = <></>,
  getContentEditable = <></>,
  place = 'left',
  position = 'anchor',
  viewScroll = 'auto',
  isDisabled = false,
  iconClassname = '',
  setValue = () => {},
  menuWrapperClassname = '',
  portal = false,
}) => {
  const { checkPermission } = useContext(OrganisationContext);

  const onMenuItemClick = menuItem => {
    const { onClick, permission } = menuItem;
    checkPermission(() => {
      onClick && onClick();
    }, permission);
  };

  return (
    <MenuWrapper className={menuWrapperClassname}>
      <Menu
        portal={portal}
        onClick={e => e.stopPropagation()}
        state={true}
        menuClassName={classNames('relative inter-400-text radius-1_5', menuClassName)}
        align={align}
        direction={place}
        position={position}
        viewScroll={viewScroll}
        menuButton={
          isIcon && (
            <div
              className={classNames('flex items-center justify-center cursor menu-btn', iconClassname)}
              onClick={e => e.stopPropagation()}>
              <Icon />
            </div>
          )
        }>
        <Fragment>
          {menuList.map((menu, index) => (
            <Fragment key={index}>
              <MenuItem
                // className={'m-0 flex items-center'}
                onClick={e => {
                  onMenuItemClick(menu);
                  setValue(menu);
                  e.stopPropagation = true;
                }}>
                {menu.withIcon && (
                  <menu.icon height={20} width={20} className={classNames('mr-2', menu.iconClassName)} />
                )}
                {menu.name}
                {isRightIcon && isSelectedValueId === menu?.id && <RightIcon height={20} width={20} color="#2496FF" />}
              </MenuItem>
              <MenuDivider />
            </Fragment>
          ))}
          {ExtraOptions}
        </Fragment>
      </Menu>
    </MenuWrapper>
  );
};

const menuShow = keyframes`
  from {
    opacity: 0;
  }
`;

const menuHide = keyframes`
  to {
    opacity: 0;
  }
`;

const Menu = styled(MenuInner)`
  ${menuSelector.name} {
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    padding: 0;
  }

  ${menuSelector.name}:focus,${menuItemSelector.name}:focus {
    outline: none;
  }

  ${menuSelector.stateOpening} {
    animation: ${menuShow} 0.3s ease-out;
  }

  ${menuSelector.stateClosing} {
    animation: ${menuHide} 0.4s ease-out forwards;
  }

  ${menuItemSelector.name} {
    cursor: pointer;
    padding: 12px 16px;
    border-radius: 8px;
    gap: 0;
  }

  ${menuItemSelector.hover} {
    background-color: ${({ theme }) => theme.lightGreyBackground};
  }

  ${menuItemSelector.disabled} {
    cursor: default;
    opacity: '0.3';
    pointer-events: none;
  }
  ${menuDividerSelector.name} {
    height: 1px;
    margin: 0;
  }
`;

const MenuWrapper = styled('div')`
  position: relative;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.3' : '1')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  border-radius: 6px;

  .menu-btn {
    height: 32px;
    width: 32px;
    &:hover {
      background-color: ${({ theme }) => theme.lightGreyBackground};
      border-radius: 12px;
    }
  }

  .menu-wrapper {
    width: auto;
    min-width: 160px;
  }
`;

export default MenuV2;
