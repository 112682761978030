import { configureStore } from '@reduxjs/toolkit';
import toastActionSlice from './features/ToastActionSlice';
import automationsSlice from './features/automationsSlice';
import downloadSlice from './features/downloadSlice';
import fileuploadSlice from './features/fileuploadSlice';
import integrationsSlice from './features/integrationsSlice';
import invoicesSlice from './features/invoicesSlice';
import navbarSlice from './features/navbarSlice';
import reloadSlice from './features/reloadSlice';
import sitesSlice from './features/sitesSlice';
import teamsManagementSlice from './features/teamsManagmentSlice';
import toastSlice from './features/toastSlice';
import transactionsSlice from './features/transactionsSlice';
import usersManagementSlice from './features/userManagementSlice';
import userSlice from './features/userSlice';

export default configureStore({
  reducer: {
    reload: reloadSlice,
    toast: toastSlice,
    toastAction: toastActionSlice,
    user: userSlice,
    integrations: integrationsSlice,
    usersManagement: usersManagementSlice,
    automations: automationsSlice,
    download: downloadSlice,
    fileUpload: fileuploadSlice,
    navbar: navbarSlice,
    teamManagement: teamsManagementSlice,
    sites: sitesSlice,
    invoices: invoicesSlice,
    transactions: transactionsSlice,
  },
  devTools: process.env.REACT_APP_STAGE === 'development',
});
