import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: {
    transactions: {},
  },
  reducers: {
    setTransactions: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.transactions = { ...data, content: [...state.transactions.content, ...data.content] };
      } else {
        state.transactions = data;
      }
    },
  },
});

export const getTransactions =
  ({ params = {}, merge = false }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/transactions`, { params: params });
      dispatch(setTransactions({ data, merge }));
      return Promise.resolve({ data, merge });
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setTransactions } = transactionsSlice.actions;
export default transactionsSlice.reducer;
