import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { getFormattedDateV2, getFormattedNumber } from '../../../../helpers/utils';
import { useInvoices } from '../../../../hooks/useAccounts';
import { cn } from '../../../../lib/utils';
import InfiniteScrollV2 from '../../../common/infinite-scroll-v2';
import Label from '../../../common/label';
import MenuV2 from '../../../common/menu/menu-v2';
import Status from '../../../common/status';
import TableHeader from '../../../common/table-header';

const invoiceHeaders = [
  { name: 'INVOICE NUMBER', key: 'INVOICE_NUMBER' },
  { name: 'ISSUED DATE', key: 'ISSUED_DATE' },
  { name: 'DUE DATE', key: 'DUE_DATE' },
  { name: 'PAYMENT DATE', key: 'PAYMENT_DATE' },
  { name: 'PAYMENT COUNT', key: 'PAYMENT_COUNT' },
  { name: 'STATUS', key: 'STATUS' },
  { name: 'AMOUNT', key: 'AMOUNT' },
  { name: '', key: 'MENU' },
];

const InvoiceContent = ({ invoice, showBorderBottom = false }) => {
  const { code, issued_date, due_date, status, total_amount, paid_date, payment_count } = invoice || {};

  return (
    <div
      className={cn(
        'px-4 min-h-14 grid grid-cols-account-invoice-grid gap-x-4 cursor hover:bg-neutral-50',
        showBorderBottom && 'border-bottom',
      )}>
      <Label className="flex items-center inter-400-text one-line">{code}</Label>
      <Label className="flex items-center inter-400-text">
        {issued_date ? getFormattedDateV2({ dateInUnix: issued_date }) : '-'}
      </Label>
      <Label className="flex items-center inter-400-text">
        {due_date ? getFormattedDateV2({ dateInUnix: due_date }) : '-'}
      </Label>
      <Label className="flex items-center inter-400-text">
        {paid_date ? getFormattedDateV2({ dateInUnix: paid_date }) : '-'}
      </Label>
      <Label className="flex items-center inter-400-text one-line">{payment_count}</Label>
      <Label className="flex items-center inter-400-text">
        <Status status={status} />
      </Label>
      <Label className="flex items-center inter-400-text">{getFormattedNumber(total_amount)}</Label>
      <div className="flex items-center justify-end">
        <MenuV2 iconClassname="rotate-90" />
      </div>
    </div>
  );
};

const AccountInvoices = () => {
  const { t } = useTranslation();
  const { account_id } = useParams();

  const { data, isLoading, hasNextPage, fetchNextPage } = useInvoices({
    params: { account_id, page: 0 },
  });
  const isDataEmpty = !data || (data && data.length === 0);

  return isLoading ? (
    <Skeleton containerClassName="h-full tracking-[1px]" height={'100%'} />
  ) : (
    <div className="flex-col flex border rounded-xl overflow-x-auto">
      <TableHeader
        headers={invoiceHeaders}
        headerClassName="px-4 bg-natural-50 min-w-full w-fit h-[52px] grid grid-cols-account-invoice-grid gap-x-4"
      />
      {isDataEmpty && (
        <div className="flex items-center justify-center h-14 inter-400-text">{t('NO_TYPE', { type: 'invoices' })}</div>
      )}
      {!isDataEmpty && (
        <InfiniteScrollV2
          infiniteScrollClassName="min-w-full w-fit"
          hasMore={hasNextPage}
          fetchMoreData={fetchNextPage}>
          {data.map((invoice, index) => (
            <InvoiceContent key={invoice.id} invoice={invoice} showBorderTop={index !== 0} />
          ))}
        </InfiniteScrollV2>
      )}
    </div>
  );
};

export default AccountInvoices;
