import api from '../store/services/index';

const fetchAPI = async ({ url, method, setDataFunc, ...rest }) => {
  try {
    const { data } = await api({ url, method, ...rest });
    setDataFunc && setDataFunc(data);
    return data;
  } catch (error) {
    setDataFunc && setDataFunc(null);
    return Promise.reject(error);
  }
};

export const getAccountEnergyTariffs = async ({ account_id, ...rest }) => {
  return fetchAPI({ url: `/accounts/${account_id}/energy_tariffs`, method: 'GET', ...rest });
};

export const getEnergyTariffDetails = async ({ energy_tariff_id, ...rest }) => {
  return fetchAPI({ url: `/energy_tariffs/${energy_tariff_id}/energy_rates`, method: 'GET', ...rest });
};
