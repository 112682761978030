import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { addToast } from '../../../store/features/toastSlice';
import Button from '../button/button';
import DateSelector from '../date-selector/date-selector';
import IconContainer from '../icon-container';
import Label from '../label';
import CustomTooltip from '../tooltip-new';

const SelectorContent = ({ selectedDates, format, onChange, onCancel, onSubmit }) => {
  const dispatch = useDispatch();
  const { start_date, end_date } = selectedDates;

  const onStartDateChange = date => {
    const start_date = moment.unix(date).clone().startOf('day').unix();
    if (end_date && start_date > end_date) {
      dispatch(addToast({ error: true, text: 'Start date should be less than end date' }));
    } else {
      onChange({ start_date, end_date });
    }
  };

  const onEndDateChange = date => {
    const end_date = moment.unix(date).clone().endOf('day').unix();
    if (start_date && end_date < start_date) {
      dispatch(addToast({ error: true, text: 'End date should be greater than start date' }));
    } else {
      onChange({ start_date, end_date });
    }
  };

  return (
    <div className="flex-column">
      <div className="flex flex-1 col-gap-4 px-2 border-bottom">
        <div className="flex-column flex-1 pb-4 pt-3">
          <Label className={classNames('inter-500-text mb-6', !start_date && 'natural-400-text')}>
            <span className="inter-400-text">From: </span>
            {start_date ? moment.unix(start_date).format(format) : 'Select date'}
          </Label>
          <DateSelector
            key={`multi-date-selector-start-date-${start_date}`}
            showCurrentMonth={!start_date}
            setEndDate={() => {}}
            setStartDate={date => onStartDateChange(date)}
            startDate={start_date ? moment.unix(start_date) : null}
          />
        </div>
        <div className="border-between" />
        <div className="flex-column flex-1 pb-4 pt-3">
          <Label className={classNames('inter-500-text mb-6', !end_date && 'natural-400-text')}>
            <span className="inter-400-text">To: </span>
            {end_date ? moment.unix(end_date).format(format) : 'Select date'}
          </Label>
          <DateSelector
            key={`multi-date-selector-end-date-${end_date}`}
            showCurrentMonth={!end_date}
            setEndDate={() => {}}
            setStartDate={date => onEndDateChange(date)}
            startDate={end_date ? moment.unix(end_date) : null}
          />
        </div>
      </div>
      <div className="flex justify-between pt-6 px-5 items-center">
        <Button
          label="Cancel"
          className="default specified-width px-4 py-2"
          size="average"
          width="140px"
          onClick={onCancel}
        />
        <Button
          label="Submit"
          className="primary specified-width px-4 py-2"
          size="average"
          width="140px"
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

const MultiDateSelector = ({
  selectedDates,
  setSelectedDates,
  placeholder = 'DD/MM/YYYY',
  format = 'DD/MM/YYYY',
  tooltipFormat = 'dddd, DD MMM',
  className = '',
  name = '',
  is_required = false,
  error = false,
  isDisabled = false,
  isCalendarIcon = true,
  isOpen = false,
  sub_name = '',
  useParentFocus = false,
}) => {
  const uniqId = useRef(nanoid());
  const [showCalendar, setShowCalendar] = useState(false);
  const [localDates, setLocalDates] = useState(selectedDates);

  const { start_date, end_date } = (showCalendar ? localDates : selectedDates) || {};

  useEffect(() => {
    useParentFocus && setShowCalendar(isOpen);
  }, [isOpen, useParentFocus]);

  useEffect(() => {
    setLocalDates(selectedDates);
  }, [showCalendar]);

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowCalendar(false);
    }
  };

  const changeSelectedDate = date => {
    setSelectedDates(date);
    setShowCalendar(false);
  };

  return (
    <MultiDateSelectorWrapper className="flex flex-1" tabIndex={1} onBlur={onBlur}>
      <CustomTooltip
        wrapperClassName="flex flex-1"
        id={`date-selector-${uniqId.current}`}
        clickable
        place="bottom"
        noArrow
        content={
          showCalendar &&
          !isDisabled && (
            <SelectorContent
              key={uniqId.current}
              format={tooltipFormat}
              selectedDates={localDates}
              onChange={setLocalDates}
              onCancel={() => setShowCalendar(false)}
              onSubmit={() => changeSelectedDate(localDates)}
            />
          )
        }
        isOpen={showCalendar}>
        <div className="flex-column flex-1">
          {name && (
            <div className="flex-1 mb-1">
              <Label className="inter-500-text natural-900-text">{name}</Label>
              {sub_name && <span className="inter-400-text natural-400-text ml-1">{sub_name}</span>}
              {is_required && <span className="required-star ml-1">*</span>}
            </div>
          )}
          <div
            className={classNames(
              'border flex flex-1 items-center px-3 cursor py-1 radius-2 date-value',
              showCalendar && 'show-calendar',
              error && !localDates && 'error-border',
              className,
            )}
            onClick={e => {
              if (useParentFocus) return;
              !isDisabled && setShowCalendar(prev => !prev);
            }}
            tabIndex={0}>
            {isCalendarIcon && (
              <IconContainer
                iconHeight={16}
                iconWidth={16}
                Icon={CalendarIcon}
                iconColor={showCalendar ? 'primary_500' : 'natural_400'}
                backgroundColor={'white'}
                iconContainerClassname="mr-2 p-0"
              />
            )}
            <div className="flex-1 flex items-center">
              <Label className={classNames('inter-400-text font-14', !start_date && 'natural-400-text')}>
                {start_date ? moment.unix(start_date).format(format) : placeholder}
              </Label>
              <Label className="inter-400-text natural-400-text font-14 mx-1">-</Label>
              <Label className={classNames('inter-400-text font-14', !end_date && 'natural-400-text')}>
                {end_date ? moment.unix(end_date).format(format) : placeholder}
              </Label>
            </div>
          </div>
        </div>
      </CustomTooltip>
    </MultiDateSelectorWrapper>
  );
};

const MultiDateSelectorWrapper = styled.div`
  .date-value {
    min-height: 32px;
  }
  .show-calendar {
    border: 1px solid ${({ theme }) => theme.focus_border};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
  }

  .custom-tooltip {
    padding: 0;
  }

  .border-between {
    border-right: 1px solid ${({ theme }) => theme.natural_200};
  }
`;

export default MultiDateSelector;
