import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import { useError } from '../../hooks/useError';
import { useOrganizationNetworks } from '../../hooks/useOrganizations';
import Card from '../common/card';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import AccountsListItem from './accounts-list-item';

const accountsTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'ADDRESS', key: 'ADDRESS' },
  { name: 'PHONE', key: 'PHONE' },
  { name: 'EMAIL', key: 'EMAIL' },
  { name: 'PRIMARY CONTACT', key: 'PRIMARY_CONTACT' },
  { name: 'CATEGORY', key: 'CATEGORY' },
  { name: '', key: 'MENU' },
];

const AccountsList = ({ debouncedSearch, orderBy, sortBy }) => {
  const { t } = useTranslation();

  const { userOrganization } = useSelector(state => state.user);

  const {
    data: accountsList,
    isLoading,
    hasNextPage,
    fetchNextPage,
    error,
  } = useOrganizationNetworks({
    organization_id: userOrganization?.id,
    params: { search: debouncedSearch, orderBy, sortBy },
  });

  useError({ error, default_message: t('ERROR_WHILE_FETCHING_ACCOUNTS') });

  const isDataEmpty = !accountsList || (accountsList && accountsList.length === 0);

  return (
    <div className="flex flex-col flex-1 rounded-md mt-6 mx-6 overflow-hidden">
      <SkeletonTransition
        loading={isLoading}
        loaderClassName="flex items-center justify-center flex-1 mb-6"
        height={'100%'}
        containerClassName="h-full w-full leading-none">
        {isDataEmpty && (
          <div className="flex flex-col flex-1 mb-6 items-center justify-center">
            <NoData
              title={debouncedSearch ? t('NO_RESULTS_FOUND') : t('NO_ACCOUNTS')}
              description={debouncedSearch ? t('NO_RESULTS_DESCRIPTION') : t('NO_ACCOUNTS_HERE')}
              className="search-terms"
              EmptyIcon={NoDocumentIcon}
              iconClassName="mb-6 relative"
            />
          </div>
        )}
        {!isDataEmpty && (
          <Card className="w-full rounded-md border overflow-auto">
            <div className="flex flex-col w-fit min-w-full h-full">
              <TableHeader
                headers={accountsTableHeaders}
                headerClassName="px-6 bg-natural-50 min-w-full w-fit h-[52px] grid grid-cols-accounts-list-grid gap-x-4"
              />
              <InfiniteScrollV2
                hasMore={hasNextPage}
                fetchMoreData={fetchNextPage}
                infiniteScrollClassName="custom-scrollbar thin-scrollbar">
                {accountsList.map((account, index) => (
                  <AccountsListItem account={account} key={account.id} showBorderTop={index !== 0} />
                ))}
              </InfiniteScrollV2>
            </div>
          </Card>
        )}
      </SkeletonTransition>
    </div>
  );
};

export default AccountsList;
