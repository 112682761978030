import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/toast-close.svg';
import Button from '../../components/common/button/button';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';
import { deleteAutomationAction } from '../../store/features/automationsSlice';
import { addToast } from '../../store/features/toastSlice';

const DeleteAction = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { modal, setModal } = useContext(OrganisationContext);
  const { automation_id, action, onSuccess } = modal.content;
  const { id, isNew } = action || {};

  const [loading, setLoading] = useState(false);

  const onDone = () => {
    if (isNew) {
      dispatch(addToast({ error: false, text: t('AUTOMATION_ACTION_DELETED'), id: nanoid() }));
      onSuccess(action);
      setModal(initModal);
    } else {
      setLoading(true);
      dispatch(deleteAutomationAction({ automation_id, action_id: id }))
        .then(data => {
          dispatch(addToast({ error: false, text: t('AUTOMATION_ACTION_DELETED'), id: nanoid() }));
          setModal(initModal);
          onSuccess(data);
        })
        .catch(() => dispatch(addToast({ error: true, text: t('ERROR_WHILE_DELETING_AUTOMATION'), id: nanoid() })))
        .finally(() => setLoading(false));
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeleteActionWrapper className="flex-column items-center pxy-6 ">
        <div className="flex-column content-wrapper items-center relative">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full mt-2">
            <label className="inter-500-text font-18 text-center">{t('DELETE_THIS_AUTOMATION_ACTION')}</label>
            <p className="inter-400-text natural-400-text text-center">{t('ARE_YOU_SURE_TO_REMOVE_THIS_ACTION')}</p>
          </div>
        </div>
        <div className="flex-column justify-between row-gap-3 mt-10 w-full action-wrapper">
          <Button
            className={classNames('negative', loading && 'disabled')}
            disabled={loading}
            label={t('DELETE')}
            onClick={() => onDone()}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary-grey')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </DeleteActionWrapper>
    </CSSTransition>
  );
};

const DeleteActionWrapper = styled.div`
  width: 384px;
`;

export default DeleteAction;
